import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExternalBasketProduct } from '@1po/1po-bff-fe-spec/generated/external_basket/response/GetExternalBasketResponse';
import { Space } from 'antd';
import styled from 'styled-components';
import { Mechanic } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import {
  attachExternalBasketRequest,
  getFirstExternalBasket,
  removeExternalBasket,
} from 'domains/externalBasket/ExternalBasket.store';
import { getDelegationSession, getUserCommercialLink } from 'domains/user';
import { ExternalBasketDialogReferenceName } from 'pages/CartPage/ExternalBasketDialog/ExternalBasketDialogReferenceName';
import { theme } from 'styles';
import { Flex, MarginBox, Pipeline, Text } from 'UI';

const SFlex = styled(Flex)`
  padding: 0 15px 0 15px;
`;

const ExternalBasketDialog = () => {
  const { t } = useTranslation();
  const externalBasket = useSelector(getFirstExternalBasket);
  const dispatch = useDispatch();
  const disclosure = useDisclosure();
  const commercialLink = useSelector(getUserCommercialLink);
  const delegateSession = useSelector(getDelegationSession);
  const activeSeller = (delegateSession ? delegateSession.commercialLink?.seller : commercialLink?.seller) ?? null;

  const { onOpen } = disclosure;

  useEffect(() => {
    if (externalBasket) {
      onOpen();
    }
  }, [externalBasket, onOpen]);

  if (!externalBasket) {
    return <></>;
  }

  const getBillingAccounts = () => {
    if (!activeSeller) {
      return [];
    }
    const primary = [activeSeller.primaryClientCode];
    const secondary = activeSeller.secondaryClientCodes ?? [];
    return primary.concat(secondary);
  };

  const renderExtFields = () => {
    const infoFields = [
      { key: t('order.order_origin', 'Order origin'), value: externalBasket.sia },
      { key: t('common.r1_code', 'R1 code'), value: commercialLink?.seller?.id ?? '' },
      { key: t('my_orders.details.billing_account', 'Billing account'), value: getBillingAccounts() },
    ];
    return (
      <Space size={15} direction={'vertical'}>
        {infoFields.map((field) => (
          <Flex key={field.key} direction={'column'}>
            <Flex>
              <Flex minHeight={55} background={theme.color.grey95} align={'center'} justify={'center'}>
                <Text type={'h6'}>{field.key}</Text>
              </Flex>
              <Flex minHeight={55} align={'center'} justify={'center'}>
                {Array.isArray(field.value) ? (
                  <Flex direction={'column'} align={'center'}>
                    {field.value.map((v, index) => (
                      <Text key={v + index} type={'text_dim'}>
                        {v}
                      </Text>
                    ))}
                  </Flex>
                ) : (
                  <Text type={'text_dim'}>{field.value}</Text>
                )}
              </Flex>
            </Flex>
            <Pipeline size={'100%'} horizontal />
          </Flex>
        ))}
      </Space>
    );
  };

  const handleAttachBasket = () => {
    dispatch(attachExternalBasketRequest({ externalBasketId: externalBasket.externalBasketId }));
  };

  const handleCancelAtach = () => {
    dispatch(removeExternalBasket({ externalBasketId: externalBasket.externalBasketId }));
  };

  const renderReferenceList = () => {
    return (
      <Flex direction={'column'}>
        <Flex minHeight={55} background={theme.color.grey95} align={'center'} justify={'center'}>
          <Text type={'h6'}>{t('catalog.reference_plural', 'References')}</Text>
        </Flex>
        {externalBasket.products.map((reference: ExternalBasketProduct) => (
          <SFlex key={reference.reference} minHeight={55} align={'center'}>
            <Flex size={1}>
              <Text type={'text_dim_bold'}>
                {t('cart.dialog.referenceNumber.short', 'Ref: {{ referenceNumber }}', {
                  referenceNumber: reference.reference,
                })}
              </Text>
            </Flex>
            <MarginBox ml={15} />
            <Flex size={2}>
              <ExternalBasketDialogReferenceName referenceNumber={reference.reference} />
            </Flex>
            <Flex size={1} direction={'row-reverse'}>
              <Text type={'text_dim_bold'}>{`${t('common.quantity.short', 'Qty: ')}${reference.quantity}`}</Text>
            </Flex>
          </SFlex>
        ))}
      </Flex>
    );
  };

  const renderExternalBasketInfo = () => {
    return (
      <Flex direction={'column'}>
        <Text type={'h4_paragraph'}>
          {t('cart.external_basket.subtitle', `An order has been placed for your garage via the {{sia}} tool.`, {
            sia: externalBasket.sia,
          })}
        </Text>
        <Text type={'text_dim'}>
          {t(
            'cart.external_basket.subtitle2',
            'If you are the author of this order, please link it to your user account.',
          )}
        </Text>
        <MarginBox mt={30} />
        {renderExtFields()}
        <MarginBox mt={30} />
        {renderReferenceList()}
      </Flex>
    );
  };

  return (
    <Dialog
      closable={false}
      maskClosable={false}
      title={t('cart.external_basket.title', 'External basket found')}
      icon={Mechanic}
      disclosure={disclosure}
      handleConfirm={handleAttachBasket}
      handleCancel={handleCancelAtach}
      content={renderExternalBasketInfo()}
      buttonsText={{
        cancel: t('common.ignore', 'Ignore'),
        confirm: t('cart.external_basket.action.link_external_basket', 'Link order to my account'),
      }}
    />
  );
};

export default ExternalBasketDialog;
