import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { v4 as uuidV4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { NO_FILE } from 'components/FileUpload/paramsByState';
import { findStatusTypeByPromotionID, removeUploadedFileInfo } from 'domains/promotion/Promotion.store';
import { CreatePromotionModel } from 'domains/promotion/Promotion.types';
import {
  SInput,
  STagReferences,
} from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/CreatePromotion.styled';
import { Box, Flex, GreyButton, MarginBox, Text } from 'UI';
import { hasData, textFormatter, useBreakpointSelectorFull } from 'utils';
import {
  AlreadyExistingReferencesNotification,
  unblockRemovedReferenceInFirstFoundFile,
} from './PromotionErrorNotifications';

interface PreviewListProps {
  columnName: string;
  data?: string[];
  duplicateItems?: string[];
  dataToDisplay?: string[];
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
  fileId?: string;
  referenceEditing?: boolean;
  dealers?: boolean;
  displayCount: number;
  setSearchHasMoreData: (x: boolean) => void;
  setCurrentStep: (x: number) => void;
}

const PreviewAsTags = ({
  columnName,
  data,
  dataToDisplay,
  promotion,
  setPromotion,
  fileId,
  referenceEditing,
  dealers,
  displayCount,
  setSearchHasMoreData,
  duplicateItems,
  setCurrentStep,
}: Readonly<PreviewListProps>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayedData, setDisplayedData] = useState(dataToDisplay);
  const [searchText, setSearchText] = useState('');
  const promotionStatus = useSelector((state: RootState) =>
    findStatusTypeByPromotionID(state, hasData(promotion) ? promotion.promotionId : undefined),
  );
  const width = useBreakpointSelectorFull()({ xs: 750, sm: 750, md: 750, lg: 750, xl: 900, xxl: 1050 });
  const isNotRemovable =
    (promotionStatus === 'PUBLISHED' && referenceEditing) || (promotionStatus === 'PUBLISHED' && dealers);
  const [duplicateItemsToDisplay, setDuplicateItemsToDisplay] = useState(duplicateItems ?? []);

  const performTextSearch = useCallback(() => {
    const searchedData = data?.filter((item) => item.toLowerCase().includes(searchText.toLowerCase()));
    const searchedDuplicateData =
      duplicateItems?.filter((item) => item.toLowerCase().includes(searchText.toLowerCase())) ?? [];

    const filteredData = searchedData?.slice(0, displayCount);
    const searchedDataLength = searchedData?.length ?? 0;

    setDisplayedData(filteredData);
    setDuplicateItemsToDisplay(searchedDuplicateData);

    setSearchHasMoreData(searchedDataLength > displayCount);
  }, [data, displayCount, searchText, duplicateItems, setSearchHasMoreData]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (!searchText) {
      setDisplayedData(dataToDisplay);
      setDuplicateItemsToDisplay(duplicateItems ?? []);
      setSearchHasMoreData(true);
      return;
    }

    performTextSearch();
  }, [data, dataToDisplay, performTextSearch, searchText, setSearchHasMoreData, duplicateItems]);

  const handleRemoveReference = (data: string[], index: number) => {
    const updatedFiles = [...promotion.uploadedReferencesFromFiles];
    const fileToUpdateIndex = updatedFiles.findIndex((file) => file.fileId === fileId);

    if (fileToUpdateIndex === -1) {
      return;
    }

    let fileToUpdate = updatedFiles[fileToUpdateIndex];
    const updatedReferences = [...data];
    const removedReference = updatedReferences[index];
    updatedReferences.splice(index, 1);

    if (
      updatedReferences.length === 0 &&
      fileToUpdate.alreadyPromoted?.length === 0 &&
      fileToUpdate.alreadyLocallyPromoted?.length === 0
    ) {
      fileToUpdate = {
        fileId: uuidV4(),
        rows: [],
        status: NO_FILE,
        discount: 0,
      };
    }

    unblockRemovedReferenceInFirstFoundFile(removedReference, fileToUpdateIndex, updatedFiles);

    updatedFiles[fileToUpdateIndex] = {
      ...fileToUpdate,
      rows: updatedReferences,
    };

    setPromotion({
      ...promotion,
      uploadedReferencesFromFiles: updatedFiles,
    });

    if (updatedReferences.length === 0 && fileId) {
      dispatch(removeUploadedFileInfo({ fileId }));
    }
  };

  const handleRemoveDealer = (data: string[], index: number) => {
    const updatedFile = { ...promotion.uploadedTargetedAudienceFromFile };
    const updatedDealers = [...data];

    updatedDealers.splice(index, 1);
    updatedFile.rows = updatedDealers;

    if (updatedDealers.length === 0) {
      updatedFile.fileId = uuidV4();
      updatedFile.rows = [];
      updatedFile.status = NO_FILE;
    }

    setPromotion({
      ...promotion,
      uploadedTargetedAudienceFromFile: updatedFile,
      targetAudience: {
        dealers: updatedDealers,
      },
    });
  };

  return (
    <Flex direction={'column'}>
      <Flex direction={'row'}>
        <Flex direction={'column'} size={8}>
          <Text type={'h5_bold'}>{columnName}</Text>
          <MarginBox mt={15} />
        </Flex>
        <Flex direction={'column'} size={2} minWidth={210} maxWidth={210} justify={'flex-end'}>
          <SInput
            bordered
            placeholder={t('common.search', 'Search')}
            onChange={(searchedText) => setSearchText(searchedText)}
          ></SInput>
        </Flex>
      </Flex>
      <MarginBox mt={20} />
      <Box width={width}>
        {displayedData &&
          data &&
          displayedData
            .filter((item) => item.toLowerCase().includes(searchText.toLowerCase()))
            .map((item, index) => {
              const handleRemoveItem = () => {
                if (!data || index === undefined) {
                  return;
                }

                if (dealers) {
                  handleRemoveDealer(data, index);
                } else {
                  handleRemoveReference(data, index);
                }
              };

              return (
                <React.Fragment key={`Reference_${item}`}>
                  {isNotRemovable ? (
                    <STagReferences key={`Reference_${item}`} closable={false}>
                      <Text type={'light_12_black_45'} key={`Reference_${item}`}>
                        {item}
                      </Text>
                    </STagReferences>
                  ) : (
                    <STagReferences key={`Reference_${item}`} closable={true} onClose={() => handleRemoveItem()}>
                      <Text type={'light_12_black_45'} key={`Reference_${item}`}>
                        {item}
                      </Text>
                    </STagReferences>
                  )}
                </React.Fragment>
              );
            })}
      </Box>
      <MarginBox mt={30} />
      {duplicateItemsToDisplay.length > 0 && (
        <AlreadyExistingReferencesNotification
          promotion={promotion}
          setPromotion={setPromotion}
          setCurrentStep={setCurrentStep}
          duplicateItems={duplicateItemsToDisplay}
          fileId={fileId}
        />
      )}
    </Flex>
  );
};

export interface FileUploadPreviewProps {
  columnName: string;
  data?: string[];
  duplicateRows?: string[];
  fileSize?: number;
  uploadTime?: string;
  fileName?: string;
  loadMoreBy?: number;
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
  fileId?: string;
  referenceEditing?: boolean;
  dealers?: boolean;
  setCurrentStep: (x: number) => void;
}

export function FileUploadPreview({
  columnName,
  data,
  duplicateRows,
  fileSize,
  uploadTime,
  fileName,
  loadMoreBy,
  promotion,
  setPromotion,
  fileId,
  referenceEditing,
  dealers,
  setCurrentStep,
}: FileUploadPreviewProps) {
  const { t } = useTranslation();
  const [dataSegment, setDataSegment] = useState<number>(loadMoreBy || data?.length || 0);
  const [searchHasMoreData, setSearchHasMoreData] = useState(true);

  if (!data) {
    return null;
  }

  const dataToDisplay = data.slice(0, dataSegment);
  const hasMoreData = dataToDisplay.length < data?.length;
  const displayLoadMore = hasMoreData && searchHasMoreData;

  const formattedDate =
    moment(uploadTime).isValid() && uploadTime ? textFormatter.formatDateNumeric(new Date(uploadTime)) : undefined;
  const loadMore = () => {
    setDataSegment((prevState) => prevState + (loadMoreBy ?? 0));
  };
  const shouldRenderPreview = dataToDisplay.length > 0 || (duplicateRows?.length ?? 0) > 0;
  const title = () => {
    if (dealers) {
      return <Text type={'h2'}>{t('common.file_upload.file_preview', 'File preview')}</Text>;
    } else {
      return (
        <Text type={'h2'}>{t('common.file_upload.uploaded_references_preview', 'Uploaded references preview')}</Text>
      );
    }
  };

  return (
    <>
      {shouldRenderPreview && (
        <Flex direction={'column'}>
          {title()}
          {fileName && (
            <Text type={'text'}>{t('common.file_upload.file_with_name', 'File name: {{fileName}}', { fileName })}</Text>
          )}
          {fileSize !== 0 && (
            <Text type={'text'}>
              {t('common.file_upload.file_with_size', 'File size: {{fileSize}} B', { fileSize })}
            </Text>
          )}
          {formattedDate && (
            <Text type={'text'}>
              {t('common.file_upload.uploaded', 'Uploaded: {{formattedDate}}', { formattedDate })}
            </Text>
          )}
          <MarginBox mt={15} />
          <PreviewAsTags
            columnName={columnName}
            data={data}
            dataToDisplay={dataToDisplay}
            promotion={promotion}
            setPromotion={setPromotion}
            fileId={fileId}
            referenceEditing={referenceEditing}
            dealers={dealers}
            displayCount={dataSegment}
            setSearchHasMoreData={setSearchHasMoreData}
            duplicateItems={duplicateRows}
            setCurrentStep={setCurrentStep}
          />
          {displayLoadMore && (
            <MarginBox mt={15} ml={10}>
              <GreyButton stretch={false} size={'middle'} onClick={loadMore}>
                {t('common.load_more', 'Load more')}
              </GreyButton>
            </MarginBox>
          )}
        </Flex>
      )}
    </>
  );
}
