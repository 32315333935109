import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Technicity } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/model/ServiceOperation';
import { Settings as EstimateSettings } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Settings';
import { Popover, Space, Table } from 'antd';
import { History } from 'history';
import { TFunction } from 'i18next';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { ChevronDownIcon, ChevronUpIcon, CogIcon } from 'assets/icons';
import QuantityModule from 'components/QuantityModule';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { getTechnicityTier } from 'domains/catalog/Catalog.types';
import { getEstimateSettings } from 'domains/estimate/Estimate.store';
import { EstimateTabParam, FocusSetting, SettingsTabName } from 'domains/estimate/Estimate.types';
import {
  getLastSearchServiceOperationsData,
  getServiceOperationsReferences,
  setSelectedReferenceQuantity,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { ServiceOperationLocal, ServiceReferenceLocal } from 'domains/maintenancePlan/MaintenancePlan.types';
import { concatArrays } from 'domains/maintenancePlan/MaintenancePlan.utils';
import { getIAMReferences, getPricesMap, IAMReferenceLocal, ReferencePriceType } from 'domains/references';
import { getCurrency } from 'domains/user';
import { getLaborTimeRate, MECHANICS } from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/utils';
import {
  EstimateTotal,
  OverviewReferencesDetailTable,
  OverviewTable,
  TotalDetails,
  TotalHeader,
  TotalSeparator,
} from 'pages/CatalogPage/IAM/MaintenancePlan/Overview/MaintenancePlanOverviewTable.styled';
import { IconWrapper } from 'pages/CatalogPage/IAM/SubcategorySection/LaborTimeSection/LaborTimeSection.styled';
import { SETTINGS_RATES } from 'pages/EstimatePage/SettingTab/SettingsTab';
import { theme } from 'styles';
import { Box, Flex, Icon, MarginBox, Text } from 'UI';
import { getData, NO_DATA, useSmall } from 'utils';
import { textFormatter } from 'utils/format';

const overviewColumns = (
  t: TFunction,
  currency: string,
  estimateSettings: EstimateSettings | undefined,
  history: History,
) => [
  {
    title: (
      <Text type={'h6_black'}>
        <Trans i18nKey={'catalog.maintenance.overview.operations'}>Operations</Trans>
      </Text>
    ),
    dataIndex: 'operation',
    key: 'operation',
    render: function rowSelector(operation: string, item: ServiceOperationLocal) {
      return `${item.label} - ${item.repairLabel}`;
    },
  },
  {
    title: (
      <Text type={'h6_black'}>
        <Trans i18nKey={'catalog.maintenance.overview.technicity'}>Technicity</Trans>
      </Text>
    ),
    dataIndex: 'technicity',
    key: 'technicity',
    render: function rowSelector(technicity: Technicity, item: ServiceOperationLocal) {
      const laborTimeRate = getLaborTimeRate(MECHANICS, item.technicity, estimateSettings?.laborPriceList)?.price;
      return (
        <Flex justify={'center'}>
          <Text type={'lead_dim'}>{getTechnicityTier(technicity) + `-${laborTimeRate || '?'}`}</Text>
        </Flex>
      );
    },
  },
  {
    title: (
      <Text type={'h6_black'}>
        <Trans i18nKey={'catalog.maintenance.overview.time'}>Time</Trans>
      </Text>
    ),
    dataIndex: 'time',
    key: 'time',
    render: function rowSelector(rawDate: string) {
      return <Flex justify={'center'}> {rawDate}</Flex>;
    },
  },
  {
    title: (
      <Text type={'h6_black'}>
        <Trans i18nKey={'order.order_detail.price'}>Price</Trans>
      </Text>
    ),
    dataIndex: 'priceVatExcluded',
    key: 'price',
    width: '10%',
    render: function rowSelector(priceVatExcluded: number, item: ServiceOperationLocal) {
      const setTechnicity = getLaborTimeRate(MECHANICS, item.technicity, estimateSettings?.laborPriceList)?.price;

      return (
        <Flex justify={'center'}>
          {setTechnicity ? (
            !isNaN(parseFloat(setTechnicity)) &&
            item.time && (
              <Text type={'light_14_black_85'}>
                {textFormatter.formatCurrency(parseFloat(setTechnicity) * item.time, currency)}
              </Text>
            )
          ) : (
            <Popover
              content={`${t(
                'catalog.parts.category.car_parts.labor_time.notification.set_technicity',
                'Set the hourly rate of technicity',
              )}`}
              placement={'bottomLeft'}
              trigger="hover"
            >
              <IconWrapper>
                <Icon
                  IconComponent={CogIcon}
                  color={theme.color.clear_blue}
                  height={22}
                  width={23}
                  onClick={() => {
                    const params = new URLSearchParams();
                    params.set(EstimateTabParam, SettingsTabName);
                    params.set(FocusSetting, SETTINGS_RATES);
                    history.push(`${ROUTER_ESTIMATE}?${params}`);
                  }}
                />
              </IconWrapper>
            </Popover>
          )}
        </Flex>
      );
    },
  },
  Table.EXPAND_COLUMN,
];

const referencesColumns = (
  operationId: string,
  prices: Map<string, NO_DATA | ReferencePriceType>,
  updateReferenceToQuantity: (operationId: string, referenceNumber: string, newQuantity: number) => void,
  currency: string,
  referenceData: IAMReferenceLocal[],
) => [
  {
    title: (
      <Text type={'h6_black'}>
        <Trans i18nKey={'catalog.maintenance.overview.brand'}>Brand</Trans>
      </Text>
    ),
    dataIndex: 'brand',
    key: 'brand',
    width: '15%',
    render: function rowSelector(_designation: string, item: ServiceReferenceLocal) {
      return referenceData.find((r) => r.referenceNumber === item.referenceNumber)?.supplier ?? '?';
    },
  },
  {
    title: (
      <Text type={'h6_black'}>
        <Trans i18nKey={'catalog.maintenance.overview.references'}>References</Trans>
      </Text>
    ),
    dataIndex: 'referenceNumber',
    key: 'referenceNumber',
    width: '15%',
    render: function rowSelector(referenceNumber: string) {
      return <Flex justify={'center'}>{referenceNumber}</Flex>;
    },
  },
  {
    title: (
      <Text type={'h6_black'}>
        <Trans i18nKey={'catalog.maintenance.overview.designations'}>Designations</Trans>
      </Text>
    ),
    dataIndex: 'designation',
    key: 'designation',
    render: function rowSelector(_designation: string, item: ServiceReferenceLocal) {
      return (
        <Flex justify={'center'}>
          {referenceData.find((r) => r.referenceNumber === item.referenceNumber)?.designation ?? '?'}
        </Flex>
      );
    },
  },
  {
    title: (
      <Text type={'h6_black'}>
        <Trans i18nKey={'catalog.maintenance.overview.quantity'}>Quantity</Trans>
      </Text>
    ),
    dataIndex: 'quantity',
    key: 'quantity',
    width: '20%',
    render: function rowSelector(quantity: number, item: ServiceReferenceLocal) {
      const updateQuantityCallback = (newQuantity: number) => {
        updateReferenceToQuantity(operationId, item.referenceNumber, newQuantity);
      };

      return (
        <Flex justify={'center'}>
          <QuantityModule value={quantity} onChange={updateQuantityCallback} showDelete={true} />
        </Flex>
      );
    },
  },
  {
    title: (
      <Text type={'h6_black'}>
        <Trans i18nKey={'catalog.maintenance.overview.price'}>Price</Trans>
      </Text>
    ),
    dataIndex: 'priceVatExcluded',
    key: 'price',
    width: '10%',
    render: function rowSelector(_priceVatExcluded: number, item: ServiceReferenceLocal) {
      const priceVatExcluded = getData(prices.get(item.referenceNumber))?.clientView?.recommendedPriceVatExcluded;
      return (
        priceVatExcluded && (
          <Flex direction={'column'} align={'center'}>
            <Text type={'light_14_black_85'}>
              {textFormatter.formatCurrency(parseFloat(priceVatExcluded) * item.quantity, currency)}
            </Text>
          </Flex>
        )
      );
    },
  },
  {
    title: <></>,
    dataIndex: 'expand',
    key: 'expand',
    width: '48px',
    render: function rowSelector(_expand: number) {
      return <></>;
    },
  },
];

const MaintenancePlanReferencesTable = ({ item }: { item: ServiceOperationLocal }) => {
  const dispatch = useDispatch();
  const currency = useSelector(getCurrency);
  const referenceToQuantity = useSelector(getServiceOperationsReferences);
  const references = getData(referenceToQuantity);
  const allReferences = Array.from(references?.values() ?? []).flat();
  const prices = useSelector((state: RootState) =>
    getPricesMap(
      state,
      allReferences.map((r) => r.referenceNumber),
    ),
  );
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const referencesData = useSelector((state: RootState) =>
    getIAMReferences(state, {
      referenceNumbers: allReferences.map((r) => r.referenceNumber),
      vehicleKey,
    }),
  );

  const updateReferenceToQuantity = (operationId: string, referenceNumber: string, newQuantity: number) => {
    dispatch(setSelectedReferenceQuantity({ operationId, referenceNumber, newQuantity }));
  };

  const columns = referencesColumns(item.id, prices, updateReferenceToQuantity, currency, referencesData);
  return (
    <OverviewReferencesDetailTable<ServiceReferenceLocal>
      rowKey={(item2) => item2.referenceNumber}
      columns={columns}
      dataSource={getData(referenceToQuantity)?.get(item.id)}
      pagination={false}
      bordered={false}
    />
  );
};

const MaintenancePlanOverviewTable = () => {
  const small = useSmall();
  const history = useHistory();
  const { t } = useTranslation();
  const currency = useSelector(getCurrency);
  const estimateSettings = useSelector(getEstimateSettings);
  const estimateSettingsData = getData(estimateSettings);
  const referencesQuantity = getData(useSelector(getServiceOperationsReferences));
  const allReferences = Array.from(referencesQuantity?.values() ?? []).flat();
  const prices = useSelector((state: RootState) =>
    getPricesMap(
      state,
      allReferences.map((r) => r.referenceNumber),
    ),
  );
  const columns = overviewColumns(t, currency, estimateSettingsData, history);

  const operationsData = useSelector(getLastSearchServiceOperationsData);
  const selectedMainOperations = operationsData?.mainOperations
    ?.flatMap((cat) => cat.operations)
    .filter((op) => op.selected);
  const selectedAddOperations = operationsData?.additionalOperations
    ?.flatMap((cat) => cat.operations)
    .filter((op) => op.selected);

  const selectedOperations = concatArrays(selectedMainOperations, selectedAddOperations);
  const selectedReferencesByOperation = useSelector(getServiceOperationsReferences);
  const references = getData(selectedReferencesByOperation);

  const totalsLabourTime = selectedOperations.reduce((acc: number | undefined, item) => {
    const setTechnicity = getLaborTimeRate(MECHANICS, item?.technicity, estimateSettingsData?.laborPriceList)?.price;
    if (!setTechnicity || isNaN(parseFloat(setTechnicity)) || !item.time) return undefined;
    if (acc === undefined) return undefined;
    acc += parseFloat(setTechnicity) * item.time;
    return acc;
  }, 0);

  const totalsLabourTimeVat = estimateSettingsData
    ? ((totalsLabourTime ?? 0) * (100 + (estimateSettingsData.vatRate ?? 0))) / 100 - (totalsLabourTime ?? 0)
    : undefined;

  const countReferencesAndOperationItems =
    selectedOperations.length +
    selectedOperations.reduce((acc: number, item) => {
      const refs = selectedReferencesByOperation.get(item.id);
      if (refs === undefined) return acc;
      acc += refs.length;
      return acc;
    }, 0);

  const totalsReferencesVatExcluded = selectedOperations.reduce((acc: number | undefined, item) => {
    const refs = selectedReferencesByOperation.get(item.id);
    if (acc === undefined) return undefined;
    if (refs === undefined) return acc;
    acc += refs.reduce((accRefs, item) => {
      const priceVatExcluded = getData(prices.get(item.referenceNumber))?.clientView?.recommendedPriceVatExcluded;
      if (priceVatExcluded === undefined) return accRefs;
      accRefs += item.quantity * parseFloat(priceVatExcluded);
      return accRefs;
    }, 0);
    return acc;
  }, 0);

  const totalsReferencesVat = selectedOperations.reduce((acc: number | undefined, item) => {
    const refs = selectedReferencesByOperation.get(item.id);
    if (acc === undefined) return undefined;
    if (refs === undefined) return acc;
    acc += refs.reduce((accRefs, item) => {
      const priceVatExcluded = getData(prices.get(item.referenceNumber))?.clientView?.recommendedPriceVatExcluded;
      const priceVatIncluded = getData(prices.get(item.referenceNumber))?.clientView?.recommendedPriceVatIncluded;
      if (priceVatExcluded === undefined || priceVatIncluded === undefined) return accRefs;
      accRefs += item.quantity * (parseFloat(priceVatIncluded) - parseFloat(priceVatExcluded));
      return accRefs;
    }, 0);
    return acc;
  }, 0);

  return (
    <>
      <OverviewTable<ServiceOperationLocal>
        rowKey={(item) => item.id}
        columns={columns}
        dataSource={selectedOperations}
        pagination={false}
        expandable={{
          rowExpandable: (item) => {
            return (references?.get(item.id)?.length ?? 0) > 0;
          },
          expandedRowRender: (item) => (
            <Flex>
              <MaintenancePlanReferencesTable item={item} />
            </Flex>
          ),
          expandIcon: ({
            expanded,
            record,
            onExpand,
          }: {
            expanded: boolean;
            record: ServiceOperationLocal;
            onExpand: any;
          }) =>
            (references?.get(record.id)?.length ?? 0) > 0 ? (
              <Icon
                IconComponent={expanded ? ChevronUpIcon : ChevronDownIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  onExpand(record, e);
                }}
                height={15}
                width={15}
              />
            ) : (
              <></>
            ),
          expandRowByClick: false,
        }}
      />
      <MarginBox mt={20} />
      <Space size={10} direction={small ? 'vertical' : 'horizontal'}>
        <Flex justify={'flex-end'} direction={'row'}>
          <TotalDetails>
            <TotalHeader>
              <Flex align={'center'} justify={'center'}>
                <MarginBox mt={15}>
                  <Text type={'h4_paragraph'}>
                    <Trans i18nKey={'catalog.maintenance.overview.maintenance_plan_details'}>
                      Maintenance plan details
                    </Trans>
                  </Text>
                </MarginBox>
              </Flex>
            </TotalHeader>
            <MarginBox mt={30} />
            <Flex>
              <Flex>
                <MarginBox mr={20} />
                <Text type={'text_dim_bold'}>
                  <Trans i18nKey={'catalog.maintenance.overview.maintenance_plan_total_references'}>References</Trans>
                </Text>
              </Flex>
              <Flex>
                <Text type={'text_dim'}>
                  <Trans i18nKey={'common.price.vat_excl_price.short'}>VAT excl. :</Trans>
                </Text>
              </Flex>
              <Flex>
                {totalsReferencesVatExcluded && textFormatter.formatCurrency(totalsReferencesVatExcluded, currency)}
              </Flex>
              <Flex>
                <Text type={'text_dim'}>
                  <Trans i18nKey={'common.price.vat'}>VAT:</Trans>
                </Text>
              </Flex>
              <Flex>{totalsReferencesVat && textFormatter.formatCurrency(totalsReferencesVat, currency)}</Flex>
            </Flex>
            <Flex>
              <Flex>
                <MarginBox mr={20} />
                <Text type={'text_dim_bold'}>
                  <Trans i18nKey={'catalog.maintenance.overview.maintenance_plan_total_labortime'}>Labor time</Trans>
                </Text>
              </Flex>
              <Flex>
                <Text type={'text_dim'}>
                  <Trans i18nKey={'common.price.vat_excl_price.short'}>VAT excl. :</Trans>
                </Text>
              </Flex>
              <Flex>{totalsLabourTime && textFormatter.formatCurrency(totalsLabourTime, currency)}</Flex>
              <Flex>
                <Text type={'text_dim'}>
                  <Trans i18nKey={'common.price.vat'}>VAT:</Trans>
                </Text>
              </Flex>
              <Flex>{totalsLabourTimeVat && textFormatter.formatCurrency(totalsLabourTimeVat, currency)}</Flex>
            </Flex>
          </TotalDetails>
        </Flex>

        <Box width={31} />
        <Flex justify={'flex-end'} direction={'row'}>
          <EstimateTotal>
            <TotalHeader>
              <Flex align={'center'} justify={'center'}>
                <MarginBox mt={15}>
                  <Text type={'h4_paragraph'}>
                    <Trans
                      i18nKey={'catalog.maintenance.overview.estimate_total'}
                      tOptions={{ countReferencesAndOperationItems }}
                    >
                      Estimate Total: {{ countReferencesAndOperationItems }} Items
                    </Trans>
                  </Text>
                </MarginBox>
              </Flex>
            </TotalHeader>
            <MarginBox mt={30} />
            <Flex>
              <Flex>
                <MarginBox mr={20} />
                <Text type={'text_dim_bold'}>
                  <Trans i18nKey={'common.price.total_vat_exclusive_short'}>Total VAT excl:</Trans>
                </Text>
              </Flex>
              <Flex>
                {textFormatter.formatCurrency((totalsReferencesVatExcluded ?? 0) + (totalsLabourTime ?? 0), currency)}
              </Flex>
            </Flex>
            <Flex>
              <Flex>
                <MarginBox mr={20} />
                <Text type={'text_dim_bold'}>
                  <Trans i18nKey={'common.price.total_vat'}>VAT:</Trans>
                </Text>
              </Flex>
              <Flex>
                {textFormatter.formatCurrency((totalsLabourTimeVat ?? 0) + (totalsReferencesVat ?? 0), currency)}
              </Flex>
            </Flex>
            <MarginBox mt={10}>
              <TotalSeparator />
              <Flex>
                <Flex>
                  <MarginBox mr={20} />
                  <Text type={'h3'}>
                    <Trans i18nKey={'common.price.total_vat_incl'}>Total VAT. Incl</Trans>
                  </Text>
                </Flex>
                <Flex>
                  <Text type={'h3'}>
                    {textFormatter.formatCurrency(
                      (totalsReferencesVatExcluded ?? 0) +
                        (totalsLabourTime ?? 0) +
                        (totalsLabourTimeVat ?? 0) +
                        (totalsReferencesVat ?? 0),
                      currency,
                    )}
                  </Text>
                </Flex>
              </Flex>
            </MarginBox>
          </EstimateTotal>
        </Flex>
      </Space>
    </>
  );
};

export default MaintenancePlanOverviewTable;
