import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownOutlinedIcon } from 'assets/icons';
import { TireBrandType } from 'pages/TiresPage/TireSearch/TiresSearchbar/TireBrandsData';
import {
  BrandMenuItem,
  DropdownMenu,
  SAntDropdown,
  SCheckbox,
} from 'pages/TiresPage/TireSearch/TiresSearchbar/TiresSearchbar.styled';
import { Flex, Icon, Image, MarginBox, Text } from 'UI';

const SELECT_ALL = 'all';

export interface TireBrandSelectionProps {
  brands: TireBrandType[];
  setBrands: (brands: TireBrandType[]) => void;
  bordered?: boolean;
  translateY?: number;
}

export const TireBrandSelection = ({
  brands,
  setBrands,
  bordered = false,
  translateY = 0,
}: TireBrandSelectionProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const selected = brands.filter((b) => b.selected);
  const [selectAll, setSelectAll] = useState(selected.length === brands.length);

  const getLabel = () => {
    switch (selected.length) {
      case 0:
        return t('catalog.tires.select_brand', 'Select brand here');
      case 1:
        return selected[0].name;
      default:
        return t('catalog.tires.select_brand_multi', 'Multi selection');
    }
  };

  // eslint-disable-next-line
  useEffect(() => setSelectAll(selected.length === brands.length), [selected, brands.length]);

  const handleSelect = (id: string) => {
    const update = brands.map((brand: TireBrandType) => {
      if (id === SELECT_ALL) return { ...brand, selected: !selectAll };
      return brand.brandId === id
        ? {
            ...brand,
            selected: !brand.selected,
          }
        : brand;
    });
    setBrands(update);
  };

  const brandMenu = (
    <DropdownMenu type={'brand'} translateX={0} translateY={translateY}>
      <Flex direction={'column'}>
        <BrandMenuItem onClick={() => handleSelect(SELECT_ALL)}>
          <SCheckbox checked={selectAll} />
          <Text type={'light_14_black_65'}>{t('common.all', 'ALL')}</Text>
        </BrandMenuItem>
        {brands.map((brand) => {
          return (
            <Flex key={brand.brandId} direction={'row'}>
              <BrandMenuItem onClick={() => handleSelect(brand.brandId)}>
                <SCheckbox checked={brand.selected} />
                <Text type={'light_14_black_65'}>{brand.name}</Text>
                <Flex direction={'row-reverse'}>
                  <Image src={brand.image} alt={'brand_image'} width={50} height={20} />
                </Flex>
              </BrandMenuItem>
            </Flex>
          );
        })}
      </Flex>
    </DropdownMenu>
  );

  return (
    <SAntDropdown
      overlay={brandMenu}
      trigger={['click']}
      visible={open}
      onVisibleChange={(visible) => setOpen(visible)}
      bordered={bordered}
    >
      <a className="ant-dropdown-link">
        <MarginBox mr={12} />
        <Text type={'lead_dim'}>{getLabel()}</Text>
        <Flex direction={'row-reverse'}>
          <Icon IconComponent={DownOutlinedIcon} size={18} color={'black'} />
        </Flex>
        <MarginBox mx={5} />
      </a>
    </SAntDropdown>
  );
};
