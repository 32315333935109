import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { useDebouncedCallback } from 'use-debounce';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { AngleDownIcon, AngleUpIcon, CheckCircleIcon } from 'assets/icons';
import {
  setReferencesIsSelected,
  updateAllUrgencyFlagsRequest,
  updateOrderMarkRequest,
  updateReferenceUrgencyFlag,
} from 'domains/basket/Basket.store';
import { VehicleLocal } from 'domains/basket/Basket.types';
import { VehicleCountry } from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { collectAllReferenceNumbers, countIsSelectedReferences } from 'domains/order/Order.mapper';
import { getDiscounts, getLoadedPrices } from 'domains/references';
import { getUserCommercialLink } from 'domains/user';
import CartReferenceTable from 'pages/CartPage/CartStep/CartItems/CartReferenceTable';
import { calculateTotalPrice } from 'pages/CartPage/CartStep/CartStep';
import { theme } from 'styles';
import { Flex, Icon, MarginBox, Text, WhiteButton } from 'UI';
import Checkbox from 'UI/CheckBox';
import VehicleFlag from 'UI/Icon/VehicleFlag';
import { textFormatter } from 'utils/format';
import { CartItemWrapper, SInput, UrgentWrapper } from '../CartItems.styled';

interface CartItemsProps {
  basketVehicle: VehicleLocal;
  currency?: string;
  markRemovedReferences: (numberOfReferences: number) => void;
  disabledRefs: string[];
  highlightedReference: string | undefined;
  setDisableRefs: (ref: string) => void;
}

const CartItemsVehicle = ({
  basketVehicle,
  currency,
  markRemovedReferences,
  disabledRefs,
  highlightedReference,
  setDisableRefs,
}: CartItemsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);
  const [isSelectAllUrgentChecked, setAllUrgentChecked] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const refNumbers = basketVehicle.references.map((r) => r.referenceNumber);
  const discounts = useSelector((state: RootState) => getDiscounts(state, refNumbers));
  const loadedPrices = useSelector((state: RootState) => getLoadedPrices(state, refNumbers));
  const totalPriceForVehicle = calculateTotalPrice(basketVehicle?.references, discounts, loadedPrices);
  const params = new URLSearchParams(location.search);
  const commercialLink = useSelector(getUserCommercialLink);
  const isAllVehicleRefUrgent = basketVehicle.references.every((r) => r.isUrgent);

  function setVehiclesReferences(isSelected: boolean) {
    const refs = collectAllReferenceNumbers(basketVehicle.references, disabledRefs);
    dispatch(
      setReferencesIsSelected({
        isSelected,
        referenceIds: refs,
        vehicleKey: basketVehicle.vehicleDetail.vehicleKey,
      }),
    );
  }

  function doSelectAll(isSelected: boolean) {
    setVehiclesReferences(isSelected);
    setIsSelectAllChecked(isSelected);
  }

  function selectAll() {
    doSelectAll(!isSelectAllChecked);
  }

  function selectAllUrgent() {
    dispatch(
      updateAllUrgencyFlagsRequest({
        basketReferenceType: basketVehicle.vehicleDetail.vehicleKey ? 'VEHICLE' : 'OTHER',
        vehicleKey: basketVehicle.vehicleDetail.vehicleKey,
        externalBasketId: undefined,

        isUrgent: !isSelectAllUrgentChecked,
      }),
    );
    dispatch(
      updateReferenceUrgencyFlag({
        vehicleKey: basketVehicle.vehicleDetail.vehicleKey,
        externalBasketId: undefined,
        referenceNumbers: basketVehicle.references.map((r) => r.referenceNumber),
        isUrgent: !isSelectAllUrgentChecked,
      }),
    );
    setAllUrgentChecked(!isSelectAllUrgentChecked);
  }

  useEffect(() => {
    setAllUrgentChecked(isAllVehicleRefUrgent);
  }, [isAllVehicleRefUrgent]);

  useEffect(() => {
    const count = countIsSelectedReferences(basketVehicle.references);
    const numOfRef = basketVehicle.references ? basketVehicle.references.length : 0;
    const disabled = refNumbers.filter((rn) => disabledRefs.includes(rn));

    setIsSelectAllChecked(count > 0 ? count === numOfRef - disabled.length : false);
  }, [basketVehicle, disabledRefs, refNumbers]);

  const bmmVehicleHeader = () => {
    const vehicle = basketVehicle.vehicleDetail;
    if (vehicle?.catalogSource === 'IAM') {
      return (
        <Flex direction={'row'}>
          <Text type={'light_14_black_85'}>
            {vehicle.vehicleBrand ?? ''} {vehicle.model ?? ''} {vehicle.iamVehicle?.phase ?? ''}
          </Text>
        </Flex>
      );
    }
    return (
      <>
        <Flex direction={'row'}>
          <Text type={'light_14_black_85'}>
            {vehicle?.dataHubVehicle?.name} {vehicle?.dataHubVehicle?.modelType}
          </Text>
        </Flex>
        <Flex direction={'row'}>
          <Text type={'light_12_black_65'}>
            {vehicle?.dataHubVehicle?.engine} - {vehicle.dataHubVehicle?.gearbox}
          </Text>
        </Flex>
      </>
    );
  };

  const vehicleHeader = (vehicleDetail: VehicleDetail) => {
    switch (vehicleDetail.catalogSource) {
      case 'DATAHUB':
        return (
          <>
            <Flex direction={'row'}>
              <Text type={'light_14_black_85'}>{vehicleDetail?.dataHubVehicle?.name}</Text>
              <MarginBox ml={5} mr={5} mt={2}>
                <VehicleFlag vehicle={vehicleDetail} />
              </MarginBox>
              <Text type={'light_14_black_85'} dataCy={'vrn'}>
                {vehicleDetail?.vrn}
              </Text>
            </Flex>
            <Flex direction={'row'}>
              <Text type={'light_12_black_65'} dataCy={'vin'}>
                {`${t('catalog.vehicle.vin', 'VIN')}: ${vehicleDetail?.vin}`}
              </Text>
            </Flex>
          </>
        );
      case 'IAM':
        return (
          <>
            <Flex direction={'row'}>
              <Text type={'light_14_black_85'}>
                {`${vehicleDetail.vehicleBrand ?? vehicleDetail.iamVehicle?.name ?? ''} ${vehicleDetail.model}`}
              </Text>
              <MarginBox ml={5} mr={5} mt={2}>
                <VehicleFlag vehicle={vehicleDetail} />
              </MarginBox>
              {vehicleDetail.vehicleIdentifiedBy === vehicleDetail.vrn && (
                <Text type={'light_14_black_85'} dataCy={'vrn'}>
                  {vehicleDetail?.vrn}
                </Text>
              )}
            </Flex>
            {vehicleDetail?.vin && (
              <Flex direction={'row'}>
                <Text type={'light_12_black_65'} dataCy={'vin'}>
                  {`${t('catalog.vehicle.vin', 'VIN')}: ${vehicleDetail.vin}`}
                </Text>
              </Flex>
            )}
          </>
        );
    }
  };

  const setOrderMarkDebounce = useDebouncedCallback((value) => {
    dispatch(
      updateOrderMarkRequest({
        basketReferenceType: basketVehicle.vehicleDetail.vehicleKey ? 'VEHICLE' : 'OTHER',
        vehicleKey: basketVehicle.vehicleDetail.vehicleKey,
        externalBasketId: undefined,
        orderMark: value,
      }),
    );
  }, 1000);

  const hasVinOrVrn = (vehicleDetail: VehicleDetail) => {
    return vehicleDetail?.vrn || vehicleDetail?.vin;
  };

  return (
    <>
      <CartItemWrapper expanded={expanded}>
        <Flex direction={'row'} minWidth={1000}>
          <MarginBox mt={27} ml={36}>
            <Checkbox value={isSelectAllChecked} onChange={selectAll} />
          </MarginBox>
          <MarginBox mt={18} ml={33}>
            <Flex direction={'column'} dataCy={'car'}>
              {hasVinOrVrn(basketVehicle.vehicleDetail)
                ? vehicleHeader(basketVehicle.vehicleDetail)
                : bmmVehicleHeader()}
            </Flex>
          </MarginBox>
          {commercialLink?.canPlaceUrgentOrders ? (
            <Flex justify={'flex-end'}>
              <MarginBox mr={67} mt={26}>
                <UrgentWrapper onClick={selectAllUrgent}>
                  <Flex>
                    <Checkbox value={isSelectAllUrgentChecked} />
                    <Icon
                      mr={5}
                      ml={5}
                      IconComponent={CheckCircleIcon}
                      size={12}
                      display={'inline'}
                      color={theme.color.brand}
                      background={theme.color.brand_black}
                      round
                    />
                    <Text type={'link'}>{t('cart.urgent_delivery', 'Urgent delivery')}</Text>
                  </Flex>
                </UrgentWrapper>
                <MarginBox mt={1} ml={20}>
                  <Text type={'light_dimmer'}>{t('cart.urgent_delivery_note', '(Select for faster delivery)')}</Text>
                </MarginBox>
              </MarginBox>
            </Flex>
          ) : (
            <Flex />
          )}
          <MarginBox mt={13} mr={36}>
            <Flex direction={'row'}>
              <Text type={'highlight'}>{t('common.price.total_VAT_excl', 'Total VAT. Excl')}</Text>
            </Flex>
            <Flex direction={'row'}>
              <Text type={'highlight'}>{textFormatter.formatCurrency(totalPriceForVehicle ?? 0, currency)}</Text>
            </Flex>
          </MarginBox>
          <MarginBox mt={27} mr={36}>
            <div onClick={() => setExpanded(!expanded)}>
              <Icon IconComponent={expanded ? AngleUpIcon : AngleDownIcon} />
            </div>
          </MarginBox>
        </Flex>
        {expanded && (
          <Flex direction={'row'}>
            <MarginBox ml={37} mt={17}>
              <SInput
                maxLength={20}
                onChange={(val) => {
                  setOrderMarkDebounce(val);
                }}
                bordered
                placeholder={t('order.order_mark', 'Click here to add your order mark')}
                initialValue={basketVehicle.orderMark}
              />
            </MarginBox>
            <MarginBox ml={30} mt={17}>
              <WhiteButton
                onClick={() => {
                  if (basketVehicle.vehicleDetail.country) {
                    params.set(VehicleCountry, basketVehicle.vehicleDetail.country);
                  }
                  history.push(
                    `${getCatalogSourceUrl(basketVehicle.vehicleDetail.catalogSource)}/${
                      basketVehicle.vehicleDetail?.vehicleKey
                    }${ROUTER_CATALOG_VEHICLE}?${params}`,
                  );
                }}
              >
                {t('catalog.actions.browseCatalog', 'Browse Catalog')}
              </WhiteButton>
            </MarginBox>
          </Flex>
        )}
        {expanded && (
          <Flex direction={'row'}>
            <CartReferenceTable
              vehicleKey={basketVehicle.vehicleDetail?.vehicleKey}
              markRemovedReferences={markRemovedReferences}
              disabledRefs={disabledRefs}
              setDisableRefs={setDisableRefs}
              highlightedReference={highlightedReference}
            />
          </Flex>
        )}
      </CartItemWrapper>
    </>
  );
};

export default CartItemsVehicle;
