import { FilterAndSort } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterAndSort';
import { FilterArray } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterArray';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { FilterAndSortField } from '@1po/1po-bff-fe-spec/generated/order/request/model/FilterAndSortField';
import { CheckoutOrderResponse } from '@1po/1po-bff-fe-spec/generated/order/response/CheckoutOrderResponse';
import { GetDealerOrderPageResponse } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { GetExternalOrdersResponse } from '@1po/1po-bff-fe-spec/generated/order/response/GetExternalOrdersResponse';
import { GetOrderByIdResponse } from '@1po/1po-bff-fe-spec/generated/order/response/GetOrderByIdResponse';
import { ReAddOrderReferencesToBasketResponse } from '@1po/1po-bff-fe-spec/generated/order/response/ReAddOrderReferencesToBasketResponse';

import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { getBasketId } from 'domains/basket/Basket.store';
import {
  sendCheckoutOrderRequest,
  sendExternalOrderListRequest,
  sendGetOrderById,
  sendOrderListRequest,
  sendReAddReferencesToBasket,
} from 'domains/order/Order.api';
import * as actions from 'domains/order/Order.store';
import {
  addExternalOrdersData,
  addInProgressOrder,
  addInProgressOrdersData,
  addOrderIdToPendingAddToBasketRequests,
  clearCheckoutStatus,
  getAllInProgressOrdersFilters,
  getInProgressOrdersCursor,
  getInProgressOrdersSort,
  removeOrderIdFromPendingAddToBasketRequests,
  resetInProgressOrdersForNewSearch,
  setCheckoutStatus,
  setExternalOrdersNoDataStatus,
  setInProgressIsLoading,
  setInProgressOrderSearchStatus,
  setInProgressOrdersNotFound,
} from 'domains/order/Order.store';
import { sortOptionsMapping } from 'domains/order/Order.types';
import { getCurrency, getTradingProfile, getUserContext } from 'domains/user';
import { notifyTop } from 'UI';
import { AppTranslation, LOADING, sagaGuard, select } from 'utils';

export function* checkoutOrderRequestSaga({
  payload,
}: ReturnType<typeof actions.checkoutOrderRequestSaga>): SagaIterator {
  const currency = yield* select(getCurrency);
  const basketId = yield* select(getBasketId);
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile || !tradingProfile.isComplete()) return;

  if (!basketId) return;
  yield put(clearCheckoutStatus());
  const userContext = yield* select(getUserContext);

  yield put(
    sendCheckoutOrderRequest({
      ...payload,
      basketId,
      userContext,
      currency,
    }),
  );
}

export function* checkoutOrderResponseSaga(action: WsResponse<CheckoutOrderResponse>): SagaIterator {
  if (action.payload) {
    yield put(setCheckoutStatus(action.payload));
    // force re-fetch of orders if we placed any
    if (action.payload.placedOrders?.length > 0) {
      yield put(resetInProgressOrdersForNewSearch());
    }
  }
}

export function* fetchDealerOrdersRequestSaga(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile || !tradingProfile.buyerId) {
    yield put(setInProgressOrdersNotFound());
    return;
  }
  yield put(setInProgressIsLoading(true));

  const cursor = yield* select(getInProgressOrdersCursor);
  const sortOption = yield* select(getInProgressOrdersSort);
  const sort = sortOptionsMapping.get(sortOption ?? 'DATE_DESC');
  const filters = yield* select(getAllInProgressOrdersFilters);
  const andFilter = new FilterArray<FilterAndSortField>('AND', filters);

  const filterAndSort: FilterAndSort<FilterAndSortField> = {
    filter: andFilter,
    sorts: sort ? [sort] : [],
    cursor,
  };

  yield put(
    sendOrderListRequest({
      filterAndSort,
    }),
  );
}

export function* fetchDealerOrdersResponseSaga(action: WsResponse<GetDealerOrderPageResponse>): SagaIterator {
  if (action.payload) {
    const { orderItems, cursor, hasMore } = action.payload;
    yield put(addInProgressOrdersData({ orderItems, cursor, hasMore }));
    yield put(setInProgressIsLoading(false));
  }
}

export function* fetchDealerExternalOrdersRequestSaga(): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile || !tradingProfile.isComplete()) return;
  yield put(setExternalOrdersNoDataStatus(LOADING));
  yield put(sendExternalOrderListRequest());
}

export function* fetchDealerExternalOrdersResponseSaga(action: WsResponse<GetExternalOrdersResponse>): SagaIterator {
  if (action.payload) {
    yield put(addExternalOrdersData(action.payload.orderItems));
  }
}

export function* fetchOrderByIdResponseSaga(action: WsResponse<GetOrderByIdResponse>): SagaIterator {
  if (action.payload) {
    const { orderItem } = action.payload;
    yield put(addInProgressOrder(orderItem));
  }
}

export function* fetchOrderByIdRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchOrderByIdRequestSaga>): SagaIterator {
  yield put(setInProgressOrderSearchStatus({ orderId: payload, status: LOADING }));
  yield put(
    sendGetOrderById({
      orderId: payload,
    }),
  );
}

export function* reAddOrderReferencesToBasketRequestSaga({
  payload,
}: ReturnType<typeof actions.reAddOrderReferencesToBasketRequestSaga>): SagaIterator {
  yield put(addOrderIdToPendingAddToBasketRequests(payload));
  yield put(
    sendReAddReferencesToBasket({
      orderId: payload,
    }),
  );
}

export function* reAddOrderReferencesToBasketResponseSaga(
  action: WsResponse<ReAddOrderReferencesToBasketResponse>,
): SagaIterator {
  if (action.payload) {
    const { orderId, result } = action.payload;
    yield put(removeOrderIdFromPendingAddToBasketRequests(orderId));

    if (result) {
      notifyTop(
        'success',
        AppTranslation.t('my_orders.details.reAddOrderedReferences.success.title', 'Added to Basket'),
        AppTranslation.t(
          'my_orders.details.reAddOrderedReferences.success.description',
          'Your references have been successfully added to your basket.',
        ),
      );
    } else {
      notifyTop(
        'error',
        AppTranslation.t('my_orders.details.reAddOrderedReferences.failure.title', 'Failed to add to basket'),
        AppTranslation.t(
          'my_orders.details.reAddOrderedReferences.failure.description',
          'Unable to add references to your basket. Please try again',
        ),
      );
    }
  }
}

export function* OrderSagas(): SagaIterator {
  yield takeEvery(actions.checkoutOrderRequestSaga.type, sagaGuard(checkoutOrderRequestSaga));
  yield takeEvery(actions.checkoutOrderResponseSaga.type, sagaGuard(checkoutOrderResponseSaga));
  yield takeEvery(actions.fetchDealerOrdersRequestSaga.type, sagaGuard(fetchDealerOrdersRequestSaga));
  yield takeEvery(actions.fetchOrderByIdRequestSaga.type, sagaGuard(fetchOrderByIdRequestSaga));
  yield takeEvery(actions.fetchOrderByIdResponseSaga.type, sagaGuard(fetchOrderByIdResponseSaga));
  yield takeEvery(
    actions.reAddOrderReferencesToBasketRequestSaga.type,
    sagaGuard(reAddOrderReferencesToBasketRequestSaga),
  );
  yield takeEvery(
    actions.reAddOrderReferencesToBasketResponseSaga.type,
    sagaGuard(reAddOrderReferencesToBasketResponseSaga),
  );

  yield takeEvery(actions.fetchDealerOrdersResponseSaga.type, sagaGuard(fetchDealerOrdersResponseSaga));
  yield takeEvery(actions.fetchDealerExternalOrdersRequestSaga.type, sagaGuard(fetchDealerExternalOrdersRequestSaga));
  yield takeEvery(actions.fetchDealerExternalOrdersResponseSaga.type, sagaGuard(fetchDealerExternalOrdersResponseSaga));
}
