import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { RootState } from 'app/AppStore';
import { AngleDownIcon, AngleUpIcon, CheckCircleIcon } from 'assets/icons';
import {
  getBasketExternalSection,
  setReferencesIsSelected,
  updateAllUrgencyFlagsRequest,
  updateOrderMarkRequest,
  updateReferenceUrgencyFlag,
} from 'domains/basket/Basket.store';
import { ExternalBasketSectionLocal } from 'domains/basket/Basket.types';
import { collectAllReferenceNumbers, countIsSelectedReferences } from 'domains/order/Order.mapper';
import { getDiscounts, getLoadedPrices } from 'domains/references';
import { getUserCommercialLink } from 'domains/user';
import CartReferenceTable from 'pages/CartPage/CartStep/CartItems/CartReferenceTable';
import { calculateTotalPrice } from 'pages/CartPage/CartStep/CartStep';
import { theme } from 'styles';
import { Flex, Icon, MarginBox, Text } from 'UI';
import Checkbox from 'UI/CheckBox';
import { textFormatter } from 'utils/format';
import { CartItemWrapper, SInput, UrgentWrapper } from '../CartItems.styled';

interface CartItemsProps {
  externalBasket: ExternalBasketSectionLocal;
  currency?: string;
  markRemovedReferences: (numberOfReferences: number) => void;
  disabledRefs: string[];
  highlightedReference: string | undefined;
  setDisableRefs: (ref: string) => void;
}

const CartItemsExternal = ({
  externalBasket,
  currency,
  markRemovedReferences,
  disabledRefs,
  highlightedReference,
  setDisableRefs,
}: CartItemsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);
  const [isSelectAllUrgentChecked, setAllUrgentChecked] = useState(false);
  const allExternalBaskets = useSelector(getBasketExternalSection);
  const [expanded, setExpanded] = useState(true);
  const refNumbers = externalBasket.references.map((r) => r.referenceNumber);
  const discounts = useSelector((state: RootState) => getDiscounts(state, refNumbers));
  const loadedPrices = useSelector((state: RootState) => getLoadedPrices(state, refNumbers));
  const totalPriceForExternal = calculateTotalPrice(externalBasket.references, discounts, loadedPrices);
  const commercialLink = useSelector(getUserCommercialLink);
  const isAllVehicleRefUrgent = externalBasket.references.every((r) => r.isUrgent);

  function setExternalReferences(isSelected: boolean) {
    const refs = collectAllReferenceNumbers(externalBasket.references, disabledRefs);
    dispatch(
      setReferencesIsSelected({
        isSelected,
        referenceIds: refs,
        externalBasketId: externalBasket.externalBasketId,
      }),
    );
  }

  function doSelectAll(isSelected: boolean) {
    setExternalReferences(isSelected);
    setIsSelectAllChecked(isSelected);
  }

  function selectAll() {
    doSelectAll(!isSelectAllChecked);
  }

  const getExternalBasketName = () => {
    const irnBaskets = allExternalBaskets.filter((ex) => ex.irn === externalBasket.irn);
    const index = irnBaskets.findIndex((el) => el.externalBasketId === externalBasket.externalBasketId) + 1;
    const getIndexNumber = () => {
      if (irnBaskets.length > 1 && index > 0) {
        return `#${index}`;
      }
      return '';
    };
    return `${t('cart.external_basket.transferred_sia', '{{sia}} transferred basket', {
      sia: externalBasket.sia,
    })} ${getIndexNumber()}`;
  };

  function selectAllUrgent() {
    dispatch(
      updateAllUrgencyFlagsRequest({
        basketReferenceType: 'EXTERNAL_BASKET',
        vehicleKey: undefined,
        externalBasketId: externalBasket.externalBasketId,

        isUrgent: !isSelectAllUrgentChecked,
      }),
    );
    dispatch(
      updateReferenceUrgencyFlag({
        vehicleKey: undefined,
        externalBasketId: externalBasket.externalBasketId,
        referenceNumbers: externalBasket.references.map((r) => r.referenceNumber),
        isUrgent: !isSelectAllUrgentChecked,
      }),
    );
    setAllUrgentChecked(!isSelectAllUrgentChecked);
  }

  useEffect(() => {
    setAllUrgentChecked(isAllVehicleRefUrgent);
  }, [isAllVehicleRefUrgent]);

  useEffect(() => {
    const count = countIsSelectedReferences(externalBasket.references);
    const numOfRef = externalBasket.references ? externalBasket.references.length : 0;
    const disabled = refNumbers.filter((rn) => disabledRefs.includes(rn));

    setIsSelectAllChecked(count > 0 ? count === numOfRef - disabled.length : false);
  }, [externalBasket, disabledRefs, refNumbers]);

  const renderHeader = () => {
    return (
      <>
        <Flex direction={'row'} justify={'center'}>
          <Text type={'h5_bold'} disableGutter>
            {getExternalBasketName()}
          </Text>
        </Flex>
      </>
    );
  };

  const setOrderMarkDebounce = useDebouncedCallback((value) => {
    dispatch(
      updateOrderMarkRequest({
        basketReferenceType: 'EXTERNAL_BASKET',
        vehicleKey: undefined,
        externalBasketId: externalBasket.externalBasketId,
        orderMark: value,
      }),
    );
  }, 1000);

  return (
    <>
      <CartItemWrapper expanded={expanded}>
        <Flex>
          <Flex maxWidth={10} background={theme.color.brand_black} justify={'center'} align={'center'} />
          <Flex direction={'column'}>
            <Flex direction={'row'} minWidth={1000}>
              <MarginBox mt={27} ml={16}>
                <Checkbox value={isSelectAllChecked} onChange={selectAll} />
              </MarginBox>
              <MarginBox mt={25} ml={33}>
                {renderHeader()}
              </MarginBox>
              {commercialLink?.canPlaceUrgentOrders ? (
                <Flex justify={'flex-end'}>
                  <MarginBox mr={67} mt={26}>
                    <UrgentWrapper onClick={selectAllUrgent}>
                      <Flex>
                        <Checkbox value={isSelectAllUrgentChecked} />
                        <Icon
                          mr={5}
                          ml={5}
                          IconComponent={CheckCircleIcon}
                          size={12}
                          display={'inline'}
                          color={theme.color.brand}
                          background={theme.color.brand_black}
                          round
                        />
                        <Text type={'link'}>{t('cart.urgent_delivery', 'Urgent delivery')}</Text>
                      </Flex>
                    </UrgentWrapper>
                    <MarginBox mt={1} ml={20}>
                      <Text type={'light_dimmer'}>
                        {t('cart.urgent_delivery_note', '(Select for faster delivery)')}
                      </Text>
                    </MarginBox>
                  </MarginBox>
                </Flex>
              ) : (
                <Flex />
              )}
              <MarginBox mt={13} mr={36}>
                <Flex direction={'row'}>
                  <Text type={'highlight'}>{t('common.price.total_VAT_excl', 'Total VAT. Excl')}</Text>
                </Flex>
                <Flex direction={'row'}>
                  <Text type={'highlight'}>{textFormatter.formatCurrency(totalPriceForExternal ?? 0, currency)}</Text>
                </Flex>
              </MarginBox>
              <MarginBox mt={27} mr={36}>
                <div onClick={() => setExpanded(!expanded)}>
                  <Icon IconComponent={expanded ? AngleUpIcon : AngleDownIcon} />
                </div>
              </MarginBox>
            </Flex>
            {expanded && (
              <Flex direction={'row'}>
                <MarginBox ml={17} mt={17}>
                  <SInput
                    maxLength={20}
                    onChange={(val) => {
                      setOrderMarkDebounce(val);
                    }}
                    bordered
                    placeholder={t('order.order_mark', 'Click here to add your order mark')}
                    initialValue={externalBasket.note}
                  />
                </MarginBox>
              </Flex>
            )}
          </Flex>
        </Flex>
        {expanded && (
          <Flex direction={'row'}>
            <CartReferenceTable
              externalBasketId={externalBasket.externalBasketId}
              markRemovedReferences={markRemovedReferences}
              disabledRefs={disabledRefs}
              setDisableRefs={setDisableRefs}
              highlightedReference={highlightedReference}
            />
          </Flex>
        )}
      </CartItemWrapper>
    </>
  );
};

export default CartItemsExternal;
