import React, { useState } from 'react';
import { CleverInput, FloatingLabel } from 'UI';
import { InputProps } from './Input';

export interface FloatInputProps extends InputProps {
  // if not filled, placeholder is used
  floatingLabel?: string;
}

export const FloatInput = (props: FloatInputProps) => {
  const [focus, setFocus] = useState(false);
  const displayFloatingLabel = focus || (props.value !== undefined && props.value.length !== 0);
  const floatingLabel = props.floatingLabel ?? props.placeholder ?? '';

  return (
    <FloatingLabel label={floatingLabel} setFocus={setFocus} displayFloatingLabel={displayFloatingLabel}>
      <CleverInput {...props} placeholder={displayFloatingLabel ? '' : props.placeholder} />
    </FloatingLabel>
  );
};
