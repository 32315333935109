import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { TFunction } from 'i18next';
import { CarIcon } from 'assets/icons';
import {
  WarrantyRequestFlex,
  WarrantyRequestRadio,
} from 'components/MotrioWarranty/step/TypeOfGuaranteeStep/WarantyRequests.styled';
import { theme } from 'styles';
import { Flex, Icon, MarginBox, Text } from 'UI';

type WarrantyRequestVariant = 'NO_LABOR' | 'UNDER_1000' | 'MORE_1000';

interface WarrantyRequestItem {
  variant: WarrantyRequestVariant;
  label: string;
  subLabel: string;
  description: string;
}

function getItems(t: TFunction): WarrantyRequestItem[] {
  return [
    {
      variant: 'NO_LABOR',
      label: t('motrio.warranty.request.no_labor.header', 'Part warranty'),
      subLabel: t('motrio.warranty.request.no_labor.sublabel', 'no-labor'),
      description: t(
        'motrio.warranty.request.no_labor.description',
        // eslint-disable-next-line max-len
        'If the workshop invoice presented only shows the part reference (without labor), the warranty can only apply to this part (part, without labor).',
      ),
    },
    {
      variant: 'UNDER_1000',
      label: t('motrio.warranty.request.under_1000.header', 'Parts and labor warranty'),
      subLabel: t('motrio.warranty.request.under_1000.sublabel', 'under 1000€'),
      description: t(
        'motrio.warranty.request.under_1000.description',
        // eslint-disable-next-line max-len
        'If the workshop invoice presented only shows the part reference (without labor), the warranty can only apply to this part (part, without labor).',
      ),
    },
    {
      variant: 'MORE_1000',
      label: t('motrio.warranty.request.more_1000.header', 'Parts and labor warranty'),
      subLabel: t('motrio.warranty.request.more_1000.sublabel', 'more then 1000€'),
      description: t(
        'motrio.warranty.request.more_1000.description',
        // eslint-disable-next-line max-len
        'If the workshop invoice presented only shows the part reference (without labor), the warranty can only apply to this part (part, without labor).',
      ),
    },
  ];
}

export function WarrantyRequests() {
  const { t } = useTranslation();
  const items = getItems(t);
  const [selected, setSelected] = useState<WarrantyRequestVariant>();

  return (
    <Radio.Group value={selected}>
      <Flex>
        {items.map((i, key) => (
          <MarginBox mr={40} key={key}>
            <WarrantyRequest
              variant={i.variant}
              selectVariant={setSelected}
              label={i.label}
              subLabel={i.subLabel}
              description={i.description}
            />
          </MarginBox>
        ))}
      </Flex>
    </Radio.Group>
  );
}

interface WarrantyRequestProps {
  variant: WarrantyRequestVariant;
  selectVariant: (variant: WarrantyRequestVariant) => void;
  label: string;
  subLabel: string;
  description: string;
}

function WarrantyRequest(props: WarrantyRequestProps) {
  function onClick() {
    props.selectVariant(props.variant);
  }

  return (
    <WarrantyRequestFlex onClick={onClick}>
      <Icon IconComponent={CarIcon} size={30} color={theme.color.brand_black} noPointer />
      <WarrantyRequestRadio value={props.variant} />
      <MarginBox mt={15} />
      <Text type={'h6_black'}>{props.label}</Text>
      <Text type={'light_14_black_85'}>{props.subLabel}</Text>
      <MarginBox mt={15} />
      <Text type={'light_14_black_85'}>{props.description}</Text>
    </WarrantyRequestFlex>
  );
}
