import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppConfig, getAppConfig } from 'domains/appContext/AppContext.store';
import { getUserProfileSearchStatus } from 'domains/user';
import { FOUND } from 'utils';

export const useFetchAppConfig = () => {
  const searchStatus = useSelector(getUserProfileSearchStatus);
  const appConfigStatus = useSelector(getAppConfig).searchStatus;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!appConfigStatus && searchStatus === FOUND) {
      dispatch(fetchAppConfig());
    }
  }, [dispatch, searchStatus, appConfigStatus]);
};
