import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { fetchGarageInfoRequest, getGarageInfo, getUserGarageInfo } from './Garage.store';
import { getTradingProfile } from '../user';

export function useFetchCurrentGarageInfo() {
  const dispatch = useDispatch();
  const garageInfo = useSelector(getUserGarageInfo);
  const tradingProfile = useSelector(getTradingProfile);

  useEffect(() => {
    if (!garageInfo && tradingProfile?.buyerId) {
      dispatch(fetchGarageInfoRequest({ garageId: tradingProfile.buyerId }));
    }
  }, [dispatch, garageInfo, tradingProfile]);
}

export function useFetchGarageInfo(garageId: string) {
  const dispatch = useDispatch();
  const garageInfo = useSelector((state: RootState) => getGarageInfo(state, garageId));

  useEffect(() => {
    if (garageId && !garageInfo) {
      dispatch(fetchGarageInfoRequest({ garageId }));
    }
  }, [dispatch, garageId, garageInfo]);
}
