import React, { useRef } from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import { ThemeFontKeyType } from 'styles';
import { MarginBox, Text } from 'UI';
import { SFlex } from 'UI/Radio/Radio.styled';

export interface CheckBoxProps {
  value: boolean;
  onChange?: (x: boolean) => void;
  label?: string;
  disable?: boolean;
  type?: ThemeFontKeyType;
  dataCy?: string;
}

export const Checkbox = ({ onChange, value, label, disable, type, dataCy }: CheckBoxProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (disable || !onChange) return;
    onChange(!value);
  };

  return (
    <SFlex onClick={handleClick} disable={disable}>
      <AntCheckbox disabled={disable} checked={value} ref={ref} data-cy={dataCy} />
      {label && (
        <MarginBox ml={8}>
          <Text type={type ?? 'text'}>{label}</Text>
        </MarginBox>
      )}
    </SFlex>
  );
};
