import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentationAlertMotrioRequest } from '@1po/1po-bff-fe-spec/generated/email/request/SendDocumentationAlertMotrio';
import { OrderReferenceItem } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { Form as AntForm } from 'antd';
import { TFunction } from 'i18next';
import { useDebouncedCallback } from 'use-debounce';
import { SItem } from 'components/DocumentationAlertButton/DocumentaryAlertForm.styled';
import { useFillDocumentationAlertState } from 'components/DocumentationAlertButton/useFillDocumentationAlertState';
import {
  sendEmailDocumentationAlertMotrioRequestSaga,
  setEmailDocumentationAlertMotrioData,
} from 'domains/email/Email.store';
import { SendDocumentationAlertMotrioLocal } from 'domains/email/Email.types';
import { getUserGarageInfo } from 'domains/garage/Garage.store';
import { DHReferenceLocal, IAMReferenceLocal } from 'domains/references';
import { Flex, Form, Input, MarginBox, Select, Text, TextArea, WhiteButton, YellowButton } from 'UI';
import Checkbox from 'UI/CheckBox';
import { getData } from 'utils';

interface SelectRequestOption {
  title: string;
  value: DocumentationAlertMotrioRequest;
}

const requestOptions = (t: TFunction): SelectRequestOption[] => {
  return [
    {
      title: t(
        'motrio.documentation_alert.select.not_compatible_with_vehicle',
        'Reference not compatible with the vehicle',
      ),
      value: 'NOT_COMPATIBLE_WITH_VEHICLE',
    },
    {
      title: t('motrio.documentation_alert.select.wrong_content', 'Wrong reference/part content'),
      value: 'WRONG_CONTENT',
    },
    {
      title: t('motrio.documentation_alert.select.wrong_label', 'Wrong reference/part label'),
      value: 'WRONG_LABEL',
    },
    {
      title: t(
        'motrio.documentation_alert.select.incorrect_technical_characteristic',
        'Incorrect/missing technical characteristic',
      ),
      value: 'INCORRECT_TECHNICAL_CHARACTERISTIC',
    },
  ];
};

interface FormItemProps {
  name: string;
  label?: string;
  type?: 'string' | 'email' | 'boolean' | any;
  required?: boolean;
  message?: string;
  children?: React.ReactNode;
  width?: string;
}

const FormItem = ({ name, label, type, required = false, message, children, width = '100%' }: FormItemProps) => (
  <SItem
    name={[name]}
    label={
      label ? (
        <Text disableGutter type={'section_bold'}>
          {label}
        </Text>
      ) : undefined
    }
    rules={[{ required, type, message }]}
    width={width}
  >
    {children}
  </SItem>
);

export const isFilled = (s?: string): boolean => (s?.length ?? 0) > 0;

const sendDisable = (email: SendDocumentationAlertMotrioLocal): boolean => {
  if (!email) {
    return false;
  }
  return !(
    isFilled(email.email) &&
    isFilled(email.socialReason) &&
    isFilled(email.companyRegistrationNumber) &&
    isFilled(email.vehicleRegistrationNumber) &&
    isFilled(email.subject) &&
    isFilled(email.message) &&
    email.request
  );
};

export function DocumentationAlertForm({
  onCancel,
  reference,
  vehicleRegistrationNumber,
}: {
  onCancel: () => void;
  reference: DHReferenceLocal | IAMReferenceLocal | OrderReferenceItem | undefined;
  vehicleRegistrationNumber: string | undefined;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const garageInfo = useSelector(getUserGarageInfo);
  const emailData = useFillDocumentationAlertState(getData(garageInfo), reference, vehicleRegistrationNumber);
  const [form] = AntForm.useForm();

  const setEmailData = useDebouncedCallback((emailData: SendDocumentationAlertMotrioLocal) => {
    dispatch(setEmailDocumentationAlertMotrioData(emailData));
  }, 250);

  const handleCancel = () => {
    onCancel();
  };

  const handleSend = () => {
    dispatch(sendEmailDocumentationAlertMotrioRequestSaga());
    handleCancel();
  };

  const validationMessages = {
    types: {
      email: t('common.email.validation', 'Please enter valid e-mail address.'),
    },
  };

  return (
    <Flex direction={'column'}>
      <Text type={'h4_paragraph_title'}>{t('motrio.documentation_alert', 'Documentation Alert')}</Text>
      <Text type={'h5_bold'}>{t('motrio.documentation_alert.report', 'Report a documentation error on a part')}</Text>
      <Text type={'description'}>
        {t('motrio.documentation_alert.required_fields', 'Fields marked with an asterisk are required')}
      </Text>
      <MarginBox mt={15} />
      <Form
        form={form}
        validationMessages={validationMessages}
        onFinish={handleSend}
        layout={'vertical'}
        initialValues={emailData}
      >
        <Flex direction={'column'}>
          <Flex direction={'row'}>
            <FormItem
              name={'socialReason'}
              label={t('assistance.contact_us.social_reason', 'Social reason')}
              type={'string'}
              required
            >
              <Input
                onChange={(socialReason) => {
                  setEmailData({ ...emailData, socialReason });
                }}
                placeholder={t('assistance.contact_us.social_reason.placeholder', 'Your social reason')}
                bordered
              />
            </FormItem>
            <MarginBox ml={15} />
            <FormItem
              name={'companyRegistrationNumber'}
              label={t('assistance.contact_us.company_registration_number', 'Company registration number')}
              type={'string'}
              required
            >
              <Input
                onChange={(companyRegistrationNumber) => setEmailData({ ...emailData, companyRegistrationNumber })}
                placeholder={t(
                  'assistance.contact_us.company_registration_number.placeholder',
                  'Your company registration number',
                )}
                bordered
              />
            </FormItem>
            <MarginBox ml={15} />
            <FormItem
              name={'vehicleRegistrationNumber'}
              label={t('assistance.contact_us.vehicle_registration_number', 'Vehicle registration number')}
              type={'string'}
              required
            >
              <Input
                onChange={(vehicleRegistrationNumber) => setEmailData({ ...emailData, vehicleRegistrationNumber })}
                placeholder={t(
                  'assistance.contact_us.vehicle_registration_number.placeholder',
                  'Your vehicle registration number',
                )}
                bordered
              />
            </FormItem>
          </Flex>
          <Flex direction={'row'}>
            <FormItem name={'email'} label={t('common.email', 'E-mail address')} type={'email'} required>
              <Input
                onChange={(email) => {
                  setEmailData({ ...emailData, email });
                }}
                placeholder={t('common.email.address.placeholder', 'Type your e-mail address here')}
                bordered
              />
            </FormItem>
            <MarginBox ml={15} />
            <FormItem name={'subject'} label={t('common.email.object', 'Subject')} type={'string'}>
              <Input
                disabled
                onChange={(subject) => setEmailData({ ...emailData, subject })}
                placeholder={t('common.email.object.placeholder', `Please, enter your e-mail's subject`)}
                bordered
              />
            </FormItem>
            <MarginBox ml={15} />
            <FormItem name={'request'} label={t('assistance.contact_us.request', 'Request')} type={'string'} required>
              <Select
                onChange={(request) =>
                  setEmailData({
                    ...emailData,
                    request: typeof request === 'string' ? (request as DocumentationAlertMotrioRequest) : undefined,
                  })
                }
                options={requestOptions(t)}
                placeholder={t('assistance.contact_us.request.placeholder', 'Select a category')}
                bordered
                styleLess
              />
            </FormItem>
          </Flex>
          <FormItem name={'body'} label={t('assistance.contact_us.message', 'Message')} type={'string'} required>
            <TextArea
              onChange={(message) => setEmailData({ ...emailData, message })}
              style={{ height: '200px' }}
              allowEnterNextLine
            />
          </FormItem>
          <FormItem name={'sendCopy'} required={false} type={'boolean'}>
            <>
              <Flex>
                <Checkbox
                  value={emailData?.sendCopy}
                  onChange={(sendCopy) => {
                    setEmailData({ ...emailData, sendCopy });
                  }}
                  label={t('common.email.request_copy', 'I would like to receive a copy of this e-mail.')}
                />
              </Flex>
              <MarginBox mt={15} />
              <Text type={'description'}>
                {t(
                  'assistance.contact_us.privacy_policy_note',
                  "By clicking “Send”, I confirm that I have read Rpartstore's  Privacy Policy.",
                )}
              </Text>
            </>
          </FormItem>
          <Flex justify={'flex-start'}>
            <WhiteButton onClick={handleCancel}>{t('common.action.cancel', 'Cancel')}</WhiteButton>
            <MarginBox ml={15} />
            <YellowButton disabled={sendDisable(emailData)} htmlType={'submit'}>
              {t('common.action.send', 'Send')}
            </YellowButton>
          </Flex>
        </Flex>
      </Form>
    </Flex>
  );
}
