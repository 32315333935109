import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import { useFetchGarageInfo } from 'domains/garage/Garage.requests';
import { getGarageInfo } from 'domains/garage/Garage.store';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { getTradingProfile, getUserCountry } from 'domains/user';
import { ContactFormType } from 'pages/AssistancePage/AssistanceCategories/ContactUs/ContactUs';
import { theme } from 'styles';
import { Box, Flex, MarginBox, Text } from 'UI';
import { getData } from 'utils';
import { getBrowserLanguage } from 'utils/i18n/localeDetector';
import { SFooterBox } from './AssistancePageFooter.styled';

interface AssistanceContact {
  phone?: string;
  tariff?: number;
  email?: string;
  openingHours?: React.ReactNode;
}

export const assistanceContactByUserCountry: Record<string, AssistanceContact> = {
  DE: { phone: '01 80 52 02 333', tariff: 14, email: 'help-de@rparts.online' },
  PL: { email: 'adminpl.rparts@renault.com.pl' },
  AT: { phone: '0820 87 44 40', tariff: 14, email: 'help-at@rparts.online' },
  BE: { email: 'help-be@rparts.online' },
  CH: { phone: '0848 000 115', tariff: 14, email: 'help-ch@rparts.online' },
  ES: { phone: '902 877 553', tariff: 9, email: 'help-sp@rparts.online' },
  FR: { phone: '0805 980 450', tariff: 9, email: 'help-fr@rparts.online' },
  GB: { phone: '0870 9000 370', email: 'help-uk@rparts.online' },
  IT: { phone: '02 385 91 091', tariff: 9, email: 'help-it@rparts.online' },
};

export const assistanceContactForRenaultDacia: Record<string, AssistanceContact> = {
  ES: {
    phone: '902 877 553',
    tariff: 9,
  },
  FR: {
    phone: '0 805 980 450',
    tariff: 9,
  },
  IT: {
    phone: '02 385 91 091',
    tariff: 9,
  },
};

export const assistanceContactForCarParts: Record<string, AssistanceContact> = {
  ES: {
    phone: '986 206 000',
    email: 'TBC',
    openingHours: (
      <Trans i18nKey={'assistance.footer.support_centre.car_parts.opening_hours.spain'}>
        {'Monday to Friday from 8h30 to 17h30'}
      </Trans>
    ),
  },
  FR: {
    phone: '0 825 002 123',
    tariff: 15,
    openingHours: (
      <Trans i18nKey={'assistance.footer.support_centre.car_parts.opening_hours.france'}>
        {'Monday to Friday from 8h30 to 12h and 14h to 17h30'}
      </Trans>
    ),
  },
};

export const assistanceContactForMembershipAndSupport: Record<string, AssistanceContact> = {
  ES: {
    phone: '919 440 040',
    openingHours: (
      <Trans i18nKey={'assistance.footer.support_centre.membership_support.opening_hours.spain'}>
        {'Monday to Friday from 8h30 to 17h30'}
      </Trans>
    ),
  },
  FR: {
    phone: '01 89 61 05 12',
    openingHours: (
      <Trans i18nKey={'assistance.footer.support_centre.membership_support.opening_hours.france'}>
        {'Monday to Friday from 8h to 18h'}
      </Trans>
    ),
  },
};

const AssistanceFooterCard = ({
  title,
  phone,
  tariff,
  openingHours,
  email,
}: {
  title?: string;
  phone?: string;
  tariff?: number;
  openingHours?: React.ReactNode;
  email?: string;
}) => {
  const { t } = useTranslation();
  if (!phone && !tariff && !email) {
    return null;
  }
  return (
    <Flex direction={'column'} minWidth={420} maxWidth={420}>
      <MarginBox my={45}>
        <Flex direction={'column'} minWidth={420} maxWidth={420}></Flex>
        {title && <Text type={'h2'}>{title}</Text>}
        {phone ? (
          <Text type={'h2'}>{`${t('assistance.footer.support_centre.telephone', 'By phone:')} ${phone}`}</Text>
        ) : (
          <Text type={'text_dim'}>
            {t(
              'assistance.footer.support_centre.telephone.unavailable',
              'Contact number is currently unavailable for your country.',
            )}
          </Text>
        )}
        <MarginBox mt={15} />
        {email && <Text type={'h3_dim'}>{email}</Text>}
        <MarginBox mt={15} />
        {tariff && !openingHours && (
          <Text type={'text_dim'}>
            {t(
              'assistance.footer.support_centre.description',
              '({{tariff}} cents/min. from the landline / mobile phone tariffs may vary) Monday to Friday from 8h30 to 17h30',
              { tariff },
            )}
          </Text>
        )}
        {tariff && openingHours && (
          <Text type={'text_dim'}>
            {t(
              'assistance.footer.support_centre.description.tariff',
              '({{tariff}} cents/min. from the landline / mobile phone tariffs may vary)',
              { tariff },
            )}
            <MarginBox mr={3} />
            {openingHours}
          </Text>
        )}
        {!tariff && openingHours && <Text type={'text_dim'}>{openingHours}</Text>}
        <Box height={15} />
      </MarginBox>
    </Flex>
  );
};

const AssistancePageFooter = ({ type }: { type: ContactFormType }) => {
  const { t } = useTranslation();

  const userCountry = useSelector(getUserCountry);
  const browserCountry = getBrowserLanguage()?.getCountryCode();
  const country = userCountry ?? browserCountry;

  const assistanceContact = assistanceContactByUserCountry[country ?? ''];
  const tradingProfile = useSelector(getTradingProfile);
  const sellerId = tradingProfile?.sellerId ?? '';
  useFetchGarageInfo(sellerId);

  const garageInfoState = useSelector((state: RootState) => getGarageInfo(state, sellerId));
  const garageInfo = getData(garageInfoState);
  const address = garageInfo ? getFirstValidAddress(garageInfo) : undefined;

  let assistanceContactForType: AssistanceContact | undefined;

  switch (type) {
    case 'ren-dac':
      assistanceContactForType = assistanceContactForRenaultDacia[country ?? ''];
      break;
    case 'car-parts':
      assistanceContactForType = assistanceContactForCarParts[country ?? ''];
      break;
    case 'membership-and-support':
      assistanceContactForType = assistanceContactForMembershipAndSupport[country ?? ''];
      break;
    case 'technical-documentation':
      assistanceContactForType = undefined;
      break;
    default:
      assistanceContactForType = assistanceContact;
      break;
  }

  return (
    <SFooterBox>
      <Flex background={theme.color.white} direction={'row'}>
        <DataContainer
          data={garageInfoState}
          Error={() => (
            <AssistanceFooterCard
              title={t('assistance.footer.garage.error', 'Garage data temporarily unavailable, please try again later')}
            />
          )}
        >
          <AssistanceFooterCard title={garageInfo?.name} phone={address?.phone} />
        </DataContainer>
        <AssistanceFooterCard
          title={t('assistance.footer.customer_service', 'Customer service')}
          phone={assistanceContactForType?.phone}
          email={assistanceContactForType?.email}
          tariff={assistanceContactForType?.tariff}
          openingHours={assistanceContactForType?.openingHours}
        />
      </Flex>
    </SFooterBox>
  );
};

export default AssistancePageFooter;
