const brand = 'rgb(255, 204, 51)';
const brand_black = 'rgb(0, 0, 0)';
const white = 'rgb(255, 255, 255)';
const clear_blue = 'rgb(24, 144, 255)';
export const antLessTheme = {
  token: {
    colorPrimary: brand,
    // link color
    linkColor: '#ffcc33',
    // success state color
    successColor: '#8bde7f',
    // warning state color
    warningColor: '#ffc14c',
    // error state color
    errorColor: '#cb333b',
    // major text font size
    fontSizeBase: '14px',
    fontSizeLg: '14px',
    // heading text color
    headingColor: '#000',
    // major text color
    textColor: '#000',
    // secondary text color
    textColorSecondary: '#999999',
    // disable state color
    disabledColor: '#999999',
    // major border radius
    borderRadiusBase: '3px',
    // major border color
    borderColorBase: '#d9d9d9',
    // major shadow for layers
    boxShadowBase: '0 2px 8px rgba(0, 0, 0, 0.15)',
    // primary button
    btnPrimaryColor: brand_black,
    btnPrimaryBg: brand,
    // default button
    btnDefaultColor: white,
    btnDefaultBg: brand_black,
    btnDefaultBorder: brand_black,
    // Typography Title strongness
    typographyTitleFontWeight: '400',
    // Card header background color
    cardHeadBackground: '#ffcc33',
    // Card border radius
    cardRadius: '15px',
    // Divider color
    dividerColor: '#979797',
    // color for Alert type info
    infoColor: '#1c1c1c',
    alertInfoBgColor: '#dddddd',
    alertInfoBorderColor: '#dddddd',
    componentBackground: '#fff',
    primary5: brand,
    primary7: brand,
    disabledActiveBg: '#1a1a1a',
    // Input & Select
    inputPaddingHorizontalMd: '11px',
    inputPaddingHorizontalSm: '10px',
    inputPaddingHorizontalLg: '12px',
    inputPaddingVerticalSm: '8px',
    inputPaddingVerticalMd: '12px',
    inputPaddingVerticalLg: '14px',
    selectDropdownVerticalPadding: '2px',
    selectionItemPadding: '14px',
    selectSingleItemHeight: '32px',
    // Layout Footer padding
    layoutFooterPadding: '0px 0px',
    // Layout Header
    layoutHeaderPadding: '0px 0px',
    layoutHeaderHeight: '90px',
    // Layout background-color under the components
    layoutBodyBackground: white,
    // Tabs
    tabsCardActiveColor: clear_blue,
    tabsHighlightColor: clear_blue,
    tabsInkBarColor: clear_blue,
    tabsHoverColor: clear_blue,
    tabsActiveColor: clear_blue,
    tabsHorizontalMargin: '0 0 0 0',
    // Menu
    layoutSiderBackground: brand_black,
    menuBg: brand_black,
    menuPopupBg: brand_black,
    menuItemColor: 'rgba(255, 255, 255, 0.65)',
    menuInlineSubmenuBg: brand_black,
    menuHighlightColor: brand,
    menuItemActiveBg: brand,
    menuItemSelected: brand_black,
    menuDarkColor: 'rgba(255, 255, 255, 0.65)',
    menuDarkBg: brand_black,
    menuDarkArrowColor: 'rgba(255, 255, 255, 0.65)',
    menuDarkInlineSubmenuBg: brand_black,
    menuDarkHighlightColor: brand,
    menuDarkItemActiveBg: brand,
    menuDarkItemHoverBg: 'transparent',
    menuDarkSelectedItemCconColor: brand_black,
    menuDarkSelectedItemTextColor: brand_black,
    menuIconMarginRight: '10px',
    menuIconSize: '24px',
    menuIconSizeLg: '24px',
    menuItemIconSize: '24px',
    menuItemGroupTitleFontSize: '10px',
    menuItemFontSize: '10px',
    menuItemSelectedFontSize: '12px',

    menuItemHeight: '74px',
    menuVerticalHeight: '74px',
    menuInlineToplevelItemHeight: '40px',

    menuItemVerticalMargin: '0px',
    menuItemBoundaryMargin: '0px',
    menuItemPaddingHorizontal: '0px',
    menuItemPadding: '0px 0px 0px 0px',

    // Media queries breakpoints
    // @screen-xs and @screen-xs-min is not used in Grid
    // smallest break point is @screen-md
    screenXs: '240px',
    screenXsMin: '240px',
    // 👆 Extra small screen / phone

    // 👇 Small screen / tablet
    screenSm: '360px',
    screenSmMin: '360px',

    // Medium screen / desktop
    screenMd: '766px',
    screenMdMin: '766px',

    // Large screen / wide desktop
    screenLg: '1024px',
    screenLgMin: '1024px',

    // Extra large screen / full hd
    screenXl: '1440px',
    screenXlMin: '1440px',

    // Extra extra large screen / large desktop
    screenXxl: '1920px',
    screenXxlMin: '1920px',

    // provide a maximum
    screenXsMax: '359px',
    screenSmMax: '765px',
    screenMdMax: '1023px',
    screenLgMax: '1439px',
    screenXlMax: '1919px',

    // Grid system
    gridColumns: '24',

    checkboxColor: '#1890ff',

    // Radio
    radioDotColor: '#1890ff',
    radioSolidCheckedColor: white,

    // Radio buttons
    radioButtonBg: white,
    radioButtonCheckedBg: white,
    radioButtonColor: white,
    radioButtonHoverColor: brand,
    radioButtonActiveColor: brand,
    radioDisabledButtonCheckedBg: '#1a1a1a',
    radioDisabledButtonCheckedColor: '#999999',

    // Switch
    switchColor: '#1890ff',

    // Slider
    sliderHandleColor: '#1890ff',
    sliderHandleColorHover: '#1890ff',
    sliderHandleBackgroundColor: '#1890ff',
    sliderDotBorderColor: '#1890ff',
    sliderDotBorderColorActive: '#1890ff',
    sliderHandleColorFocusShadow: '#1890ff',
    sliderHandleColorFocus: '#1890ff',
    sliderHandleColorTooltipOpen: '#1890ff',
    sliderTrackBackgroundColor: '#1890ff',
    sliderTrackBackgroundColorHover: '#1890ff',
    sliderRailBackgroundColorHover: '#1890ff',
  },
};
