import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { List, Space } from 'antd';
import moment from 'moment';
import { RootState } from 'app/AppStore';
import { BrandsSearchEnginIcon, ClockFullIcon, HourglassIcon, SearchIcon } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import { getBannersCount } from 'domains/information/Information.store';
import { useFetchIAMServiceProposals } from 'domains/maintenancePlan/MaintenancePlan.requests';
import {
  fetchIAMServiceProposalOperationsRequestSaga,
  getIAMAvailableModules,
  getMileageInput,
  getOptionInput,
  getRegistrationDateInput,
  getServiceProposal,
  getServiceProposalOperations,
  setMileageInputRequest,
  setOperationsRequest,
  setOptionInputRequest,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import {
  RowDivider,
  SearchButton,
  SListItem,
  SSelect,
  SServiceCard,
} from 'pages/CatalogPage/IAM/MaintenancePlan/VehicleCriterias/VehicleCriterias.styled';
import { theme } from 'styles';
import {
  Box,
  CenterFlex,
  Container,
  DarkCard,
  DatePicker,
  Flex,
  Icon,
  IconType,
  InputNumber,
  MarginBox,
  SelectOptionSingle,
  Text,
} from 'UI';
import Checkbox from 'UI/CheckBox';
import { getData, isLoading, useSmall } from 'utils';
import { getStringDateFromMoment } from 'utils/date';

const VehicleCriterias = ({ vehicleDetail }: { vehicleDetail?: VehicleDetail }) => {
  const dispatch = useDispatch();
  const small = useSmall();
  const { t } = useTranslation();
  const disclosure = useDisclosure();
  const availableDataModule = getData(useSelector(getIAMAvailableModules));
  const regDateStored = getData(useSelector(getRegistrationDateInput));
  const mileageStored = getData(useSelector(getMileageInput));
  const optionStored = getData(useSelector(getOptionInput));
  const registrationDate = new Date(vehicleDetail?.iamVehicle?.registrationDate ?? '');

  const [registrationDateEditable] = useState<boolean>(!moment(registrationDate).isValid());
  const [registrationDateInput, setRegistrationDateInput] = useState<moment.Moment | null>(
    moment(registrationDate).isValid() ? moment(registrationDate) : regDateStored ?? null,
  );
  useFetchIAMServiceProposals(vehicleDetail, getStringDateFromMoment(registrationDateInput));
  const [mileageInput, setMileageInput] = useState<number>(mileageStored ?? 0);
  const [optionInput, setOptionInput] = useState<string | number | undefined>(optionStored);
  const [serviceLastCheck, setServiceLastCheck] = useState<boolean>(false);
  const [serviceNextCheck, setServiceNextCheck] = useState<boolean>(false);
  const proposals = useSelector(getServiceProposal);
  const proposalsData = getData(proposals);
  const proposalOperations = useSelector(getServiceProposalOperations);
  const proposalOperationsData = getData(proposalOperations);
  const isBanner = useSelector((state: RootState) => getBannersCount(state, 'OTHER_BRANDS_CATALOG')) > 0;

  const { onOpen, isOpen } = disclosure;

  const options = (): SelectOptionSingle[] =>
    proposalsData?.proposals.map((p) => {
      return { title: p.label, value: p.id };
    }) ?? [];

  function handleSearch() {
    if (vehicleDetail?.iamVehicle?.versionCode === undefined) return;
    if (registrationDateInput === null || !registrationDateInput.isValid()) return;
    if (!optionInput) return;
    dispatch(
      fetchIAMServiceProposalOperationsRequestSaga({
        versionCode: vehicleDetail?.iamVehicle?.versionCode,
        proposalId: optionInput?.toString(),
        mileage: mileageInput.toString(),
        registrationDate: registrationDateInput.format('YYYY-MM-DD'),
      }),
    );
    onOpen();
  }

  function handleConfirm() {
    const operationsIds = proposalOperationsData?.proposalOperations
      .filter(
        (service) =>
          (service.label === 'NextService' && serviceNextCheck) ||
          (service.label === 'LastService' && serviceLastCheck),
      )
      .flat()
      .map((operation) => operation.operations)
      .flat()
      .map((o) => o.id);

    if (operationsIds === undefined || operationsIds.length === 0) return;
    dispatch(setOperationsRequest({ operationsIds }));
  }

  useEffect(() => {
    const operationsIdsWithFamily = proposalOperationsData?.proposalOperations
      .filter(
        (service) =>
          (service.label === 'NextService' && serviceNextCheck) ||
          (service.label === 'LastService' && serviceLastCheck),
      )
      .flat()
      .map((operation) => operation.operations)
      .flat()
      .filter((o) => o.familyIds.length > 0)
      .map((o) => o.id);

    if (operationsIdsWithFamily && operationsIdsWithFamily.length > 0 && !isOpen) {
      const scrollToId = 'scroll-operation-' + operationsIdsWithFamily[0];
      const divElementAbove = document.getElementById(scrollToId);
      if (divElementAbove) {
        const rect = divElementAbove.getBoundingClientRect();
        const offsetTop = rect.top + window.scrollY;
        window.scrollTo({
          top: offsetTop - (isBanner ? 170 : 100),
          behavior: 'smooth',
        });
      }
    }
  }, [proposalOperationsData, isBanner, serviceLastCheck, serviceNextCheck, isOpen]);

  const ServiceCard = ({
    icon,
    label,
    selected,
    onSelect,
    operations,
  }: {
    icon: IconType;
    label: string;
    selected: boolean;
    onSelect: (value: boolean) => void;
    operations?: string[];
  }) => {
    return (
      <SServiceCard
        active={false}
        minWidth={400}
        maxWidth={400}
        minHeight={300}
        maxHeight={300}
        direction={'column'}
        align={'center'}
      >
        <RowDivider maxHeight={80} direction={'row'} align={'center'}>
          <CenterFlex
            style={{
              width: '48px',
              height: '48px',
              backgroundColor: theme.color.ice_blue,
            }}
          >
            <Icon IconComponent={icon} color={theme.color.blue} size={26} />
          </CenterFlex>
          <MarginBox mr={10} />
          <Text type={'h5_bold'} cursor={'pointer'}>
            {label}
          </Text>
          <Flex direction={'column'} align={'flex-end'}>
            <Checkbox value={selected} onChange={onSelect} />
          </Flex>
        </RowDivider>

        <List
          dataSource={operations}
          loading={isLoading(proposalOperations)}
          renderItem={(o) => {
            return <SListItem>{o}</SListItem>;
          }}
        />
      </SServiceCard>
    );
  };

  function handleSelectLast(val: boolean) {
    setServiceLastCheck(val);
  }

  function handleSelectNext(val: boolean) {
    setServiceNextCheck(val);
  }

  const showDialog = availableDataModule?.availableData.find((d) => d.name === 'servicePlanProposal')?.dataAvailable;

  return showDialog ? (
    <Container>
      <CenterFlex>
        <Dialog
          width={1000}
          disclosure={disclosure}
          title={t('maintenance_plan.maintenance_plan_term', 'Maintenance plan term')}
          description={
            t(
              'maintenance_plan.maintenance_plan_description',
              'Please, select at least one indicator of maintenance before moving on to the next step.',
            ) as string
          }
          content={
            <Space size={30} direction={small ? 'vertical' : 'horizontal'}>
              <ServiceCard
                icon={HourglassIcon}
                selected={serviceLastCheck}
                onSelect={handleSelectLast}
                label={t('maintenance_plan.previous_revision', 'Previous revision')}
                operations={proposalOperationsData?.proposalOperations
                  ?.find((p) => p.label === 'LastService')
                  ?.operations.map((o) => `${o.label} - ${o.repairLabel}`)}
              />
              <MarginBox mr={20} mb={20} />
              <ServiceCard
                icon={HourglassIcon}
                selected={serviceNextCheck}
                onSelect={handleSelectNext}
                label={t('maintenance_plan.upcoming_revision', 'Upcoming revision')}
                operations={proposalOperationsData?.proposalOperations
                  ?.find((p) => p.label === 'NextService')
                  ?.operations.map((o) => `${o.label} - ${o.repairLabel}`)}
              />
            </Space>
          }
          icon={ClockFullIcon}
          status={'info'}
          handleConfirm={handleConfirm}
        />
      </CenterFlex>
      <DarkCard
        justify={'flex-start'}
        noPadding
        title={
          <Flex>
            <Icon IconComponent={BrandsSearchEnginIcon} color={theme.color.yellow_light} size={24} />
            <Text type={'card_title_dark'}>
              <Trans i18nKey={'maintenance_plan.vehicle_criterias'}>Vehicle criterias</Trans>
            </Text>
          </Flex>
        }
      >
        <MarginBox ml={45} mr={33} mt={15} mb={30}>
          <Flex direction={'row'} justify={'space-between'} align={'center'}>
            <Flex direction={'column'}>
              <Flex direction={'row'}>
                <Text type={'text_dim_bold'}>
                  <Trans i18nKey={'maintenance_plan.first_registration'}>First registration</Trans>
                </Text>
              </Flex>
              <Flex direction={'row'}>
                <DatePicker
                  value={moment(registrationDateInput).isValid() ? moment(registrationDateInput) : null}
                  disabled={!registrationDateEditable}
                  onChange={(newValue) => {
                    if (newValue && newValue.isValid()) {
                      setRegistrationDateInput(newValue);
                    }
                  }}
                  placeholder={t('maintenance_plan.first_registration', 'First registration')}
                />
              </Flex>
            </Flex>
            <MarginBox mr={20} />
            <Flex direction={'column'}>
              <Flex direction={'row'}>
                <Text type={'text_dim_bold'}>
                  <Trans i18nKey={'maintenance_plan.actual_mileage'}>Actual mileage (Km)</Trans>
                </Text>
              </Flex>
              <Flex direction={'row'}>
                <Box width={150}>
                  <InputNumber
                    value={mileageInput}
                    onBlur={(val) => {
                      setMileageInput(Number(val));
                      dispatch(setMileageInputRequest({ mileage: Number(val) }));
                    }}
                    bordered
                    maxLength={10}
                  />
                </Box>
              </Flex>
            </Flex>
            <MarginBox mr={20} />
            <Flex direction={'column'} size={3}>
              <Flex direction={'row'}>
                <Text type={'text_dim_bold'}>
                  <Trans i18nKey={'maintenance_plan.options'}>Options</Trans>
                </Text>
              </Flex>
              <Flex direction={'row'}>
                <SSelect
                  value={optionInput}
                  onChange={(request: string | number | undefined) => {
                    if (request !== undefined) {
                      setOptionInput(request);
                      dispatch(setOptionInputRequest({ option: request.toString() }));
                    }
                  }}
                  options={options()}
                  bordered
                />
              </Flex>
            </Flex>
            <Flex direction={'column'} justify={'flex-end'}>
              <Flex direction={'row'} justify={'flex-end'}>
                <SearchButton
                  disabled={
                    registrationDateInput === null ||
                    !registrationDateInput.isValid() ||
                    mileageInput === 0 ||
                    optionInput === undefined
                  }
                  onClick={handleSearch}
                >
                  <Flex align={'center'} justify={'center'}>
                    <Icon IconComponent={SearchIcon} ml={10} mr={10} size={24} color={'black'} />
                  </Flex>
                  <MarginBox mr={5} />
                </SearchButton>
              </Flex>
            </Flex>
          </Flex>
        </MarginBox>
      </DarkCard>
    </Container>
  ) : (
    <></>
  );
};

export default VehicleCriterias;
