import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { useFetchCategoryImages } from 'domains/catalog/Catalog.hooks';
import { getExplodedTree, getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { Box, Text } from 'UI';
import { getData, head } from 'utils';
import { useBreakpointSelectorFull } from 'utils/hooks/useBreakpoint';
import { OFFSET_HEADER, OFFSET_PADDING, OFFSET_SUB_HEADER, useOffsetTop } from 'utils/hooks/useOffsetTop';
import CarPartGroupItem from './CarPartGroupItem';
import {
  isMaintenanceNodeId,
  ROUTINE_VEHICLE_MAINTENANCE_GROUP_NODE_ID,
  RoutineVehicleMaintenanceGroupCard,
} from './CarPartGroupItem/RoutineVehicleMaintenanceGroupCard';
import { ScrollWrapper } from './CarPartGroupsSection.styled';
import { TabTitle } from '../CatalogPage.styled';

export const CarPartElementId = 'car-part-group-';

const CarPartGroupsSection = () => {
  const { t } = useTranslation();
  const breakpointSelectorFull = useBreakpointSelectorFull();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const { carPartGroupId: enlargedViewNodeId } = useParams<{
    carPartGroupId?: string;
  }>();
  const vehicleExplodedViewsRawData = getData(useSelector((state: RootState) => getExplodedTree(state, vehicleKey)));
  const vehicleExplodedViewsFiltered = useMemo(
    () => vehicleExplodedViewsRawData?.filter((item) => item.nodeId !== ROUTINE_VEHICLE_MAINTENANCE_GROUP_NODE_ID),
    [vehicleExplodedViewsRawData],
  );
  const [vehicleExplodedViews, setVehicleExplodedViews] = useState(vehicleExplodedViewsFiltered);
  const offsetTop = useOffsetTop('RENAULT_CATALOG') + OFFSET_HEADER + OFFSET_SUB_HEADER + OFFSET_PADDING;
  const hasVehicleMaintenanceGroup = vehicleExplodedViewsRawData?.find(
    (item) => item.nodeId === ROUTINE_VEHICLE_MAINTENANCE_GROUP_NODE_ID,
  );

  useFetchCategoryImages();

  useEffect(() => {
    if (vehicleExplodedViewsFiltered) {
      setVehicleExplodedViews(vehicleExplodedViewsFiltered);
    }
    // eslint-disable-next-line
  }, [vehicleKey]);

  const setEnlargedNode = useCallback(() => {
    const order = head(vehicleExplodedViewsFiltered?.filter((item) => item?.nodeId === enlargedViewNodeId))?.index;
    if (!order) return;
    // normalize order to count from zero
    const index = order - 2;
    if (index % 2 === 0) {
      setVehicleExplodedViews(vehicleExplodedViewsFiltered);
    } else {
      const x = index;
      const y = index - 1;
      const list = vehicleExplodedViewsFiltered;
      if (list) {
        const vehicleExplodedPermuted = Object.assign([], list, { [x]: list[y], [y]: list[x] });
        setVehicleExplodedViews(vehicleExplodedPermuted);
      }
    }
  }, [enlargedViewNodeId, setVehicleExplodedViews, vehicleExplodedViewsFiltered]);

  useEffect(() => {
    setEnlargedNode();
    setTimeout(() => {
      document
        .getElementById(CarPartElementId + enlargedViewNodeId)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enlargedViewNodeId]);

  return (
    <>
      <TabTitle>
        <Text type={'h1_banner_light'} transform={'capitalize'} dataCy={'title-car-parts'}>
          {t('catalog.parts.category.car_parts', 'Car parts')}
        </Text>
      </TabTitle>
      <Row gutter={[30, 30]}>
        <DataContainer
          data={vehicleExplodedViewsRawData}
          Error={() => (
            <ErrorWithLabel
              label={t('catalog.parts.backend_error', 'Car parts are temporarily unavailable, please try again later.')}
            />
          )}
        >
          {hasVehicleMaintenanceGroup && (
            <Col span={24}>
              <RoutineVehicleMaintenanceGroupCard />
            </Col>
          )}
          {vehicleExplodedViews?.map((item) => {
            if (!item?.nodeId || !item?.index || isMaintenanceNodeId(item?.nodeId)) return null;
            return (
              <Col
                span={
                  item?.nodeId === enlargedViewNodeId
                    ? 24
                    : breakpointSelectorFull({
                        xs: 24,
                        sm: 24,
                        md: 24,
                        lg: 24,
                        xl: 12,
                        xxl: 12,
                      })
                }
                key={`${CarPartElementId}${item?.nodeId}`}
              >
                <ScrollWrapper offsetTop={offsetTop} id={`${CarPartElementId}${item?.nodeId}`}>
                  <CarPartGroupItem
                    key={item.nodeId}
                    boxNodeId={item.nodeId}
                    label={item.label}
                    svgImageKey={item?.imageKey}
                    enlarged={enlargedViewNodeId === item.nodeId}
                    details={item.items}
                    order={item.index}
                  />
                </ScrollWrapper>
              </Col>
            );
          })}
        </DataContainer>
      </Row>
      <Box height={15} />
    </>
  );
};

export default CarPartGroupsSection;
