import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  checkAndSetFile,
  FileUploadProps,
  inputOnChange,
  onClick,
  onDragOver,
  onDrop,
  uploadFile,
} from 'components/FileUpload/FileUpload';
import { Body } from 'components/FileUpload/FileUpload.styled';
import paramsByState, {
  defaultParamsByState,
  ModalStatusType,
  ParamsByState,
  UPLOADING_FILE,
} from 'components/FileUpload/paramsByState';
import { Box, CenteredSpin, Flex, Icon, Link, MarginBox, Modal, Text, WhiteButton, YellowButton } from 'UI';

export interface FileUploadModalProps extends Omit<FileUploadProps, 'currentFile'> {
  handleCancel: () => void;
  isOpen: boolean;
}

export const FileUploadModal = ({
  template,
  handleFileBase64,
  validateFile,
  handleNoFile,
  isOpen,
  status,
  descriptions,
  getText1Override,
  handleCancel,
}: FileUploadModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [state, setState] = useState<ParamsByState>(defaultParamsByState(theme, t, getText1Override));
  const fileInput = useRef<HTMLInputElement>(null);

  function onCancel() {
    checkAndSetFile(null, validateFile, handleNoFile, setFile);
    handleCancel();
  }

  const updateState = (newStatus: ModalStatusType, newFile: File | null) => {
    setState(paramsByState(newStatus, newFile ? newFile.name : '', theme, t, getText1Override));
  };

  useEffect(() => {
    updateState(status, file);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, file]);

  return (
    <Modal
      title={
        <MarginBox mx={19} mt={20}>
          <Text type={'h1'}>{t('common.file_upload', 'File upload')}</Text>
        </MarginBox>
      }
      visible={isOpen}
      closable
      onCancel={() => onCancel()}
      footer={
        <MarginBox mx={29} mb={20}>
          <Flex justify={'space-between'} align={'baseline'}>
            {template && (
              <Link to={template.link} external download={template.fileName}>
                <Text type={'text'} displayStyle={'link'} cursor={'pointer'} decoration={'underline'}>
                  {t('common.file_upload.download_template', 'Download template')}
                </Text>
              </Link>
            )}
            <Flex size={0}>
              <WhiteButton onClick={() => onCancel()} disabled={state.isCancelDisabled}>
                {t('common.action.cancel', 'Cancel')}
              </WhiteButton>
              <Box width={30} />
              <YellowButton onClick={() => uploadFile(file, handleFileBase64)} disabled={state.isImportDisabled}>
                {t('common.import', 'Import')}
              </YellowButton>
            </Flex>
          </Flex>
        </MarginBox>
      }
      width={700}
    >
      <Body
        onClick={() => onClick(fileInput)}
        onDrop={(e) => onDrop(e, validateFile, handleNoFile, setFile)}
        onDragOver={onDragOver}
        height={200}
        cursor={'pointer'}
      >
        <MarginBox mt={50}>
          <Flex direction={'column'} justify={'center'} align={'center'}>
            <>
              {status === UPLOADING_FILE ? (
                <CenteredSpin size={'large'} />
              ) : (
                <Icon IconComponent={state.icon} size={50} display={'block'} color={state.iconColor} />
              )}
              {state.text1 && (
                <MarginBox mt={10}>
                  <Text type={'lead'} displayStyle={state.textType1}>
                    {state.text1}
                  </Text>
                </MarginBox>
              )}
            </>
            <MarginBox mt={5} />
            {descriptions.length > 0 &&
              descriptions.map((desc, i) => (
                <Text
                  type={'light_14_black_85'}
                  displayStyle={state.textType2}
                  key={'fileupload_modal_description_' + i}
                >
                  {desc}
                </Text>
              ))}
          </Flex>
        </MarginBox>
      </Body>
      <input
        type="file"
        id="file"
        ref={fileInput}
        style={{ display: 'none' }}
        onInput={(e: ChangeEvent<HTMLInputElement>) => inputOnChange(e, validateFile, handleNoFile, setFile)}
      />
    </Modal>
  );
};
