import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { TFunction } from 'i18next';
import { SELECTED_PROFILE_PARAM } from 'domains/auth/Auth.types';
import { getTokenUser, setSelectedProfile, TokenProfile } from 'domains/user';
import { GetTitle } from 'pages/EstimatePage/TableSection/tableComponents';
import { Box, Flex, Modal, Text, YellowButton } from 'UI';

const dataColumns = (t: TFunction) => {
  return [
    {
      title: GetTitle(t('login.select_profile.ipn', 'IPN')),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: GetTitle(t('login.select_profile.site_id', 'Site Source Id')),
      dataIndex: 'garageId',
      key: 'garageId',
    },
    {
      title: GetTitle(t('login.select_profile.site_name', 'Site Name')),
      dataIndex: 'garageName',
      key: 'garageName',
    },
    {
      title: GetTitle(t('login.select_profile.identity_source', 'Identity source')),
      dataIndex: 'repository',
      key: 'repository',
    },
  ];
};

interface LoginOptionData extends TokenProfile {
  key: React.Key;
}

const UserProfileSelectionPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokenUser = useSelector(getTokenUser);
  const options = tokenUser?.profiles;
  const data = Array.isArray(options)
    ? options.map((o) => {
        return {
          key: o.id,
          ...o,
        } as LoginOptionData;
      })
    : [];
  const [profile, setProfile] = useState<TokenProfile | undefined>(undefined);

  const rowSelection: TableRowSelection<LoginOptionData> = {
    type: 'radio',
    onChange: (_selectedRowKeys: React.Key[], selectedRows: LoginOptionData[]) => {
      setProfile((options ?? []).find((o) => o.id === selectedRows[0].id));
    },
    getCheckboxProps: (record: TokenProfile) => ({
      id: record.id,
      name: record.id,
    }),
  };

  return (
    <Modal width={700} footer={''} visible={options && tokenUser?.selectedProfile === undefined}>
      {Array.isArray(options) && (
        <Flex align={'center'} direction={'row'} justify={'center'}>
          <Box width={600} align={'center'}>
            <Text type={'h1_lead'}>{t('login.select_profile', 'Please choose your profile')}</Text>
            <Box height={20} />
            <Table<LoginOptionData>
              columns={dataColumns(t)}
              dataSource={data}
              rowSelection={rowSelection}
              pagination={false}
            />
            <Box height={30} />
            <Flex justify={'flex-end'}>
              <YellowButton
                disabled={profile === undefined}
                onClick={() => {
                  if (profile) {
                    sessionStorage.setItem(SELECTED_PROFILE_PARAM, profile.id);
                    dispatch(setSelectedProfile(profile));
                  }
                }}
              >
                {t('login.select_profile.connect', 'Connect')}
              </YellowButton>
            </Flex>
          </Box>
        </Flex>
      )}
    </Modal>
  );
};

export default UserProfileSelectionPopup;
