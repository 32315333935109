import React, { ReactNode, useRef } from 'react';
import { Radio as AntRadio } from 'antd';
import { MarginBox, Text } from 'UI';
import { SFlex } from 'UI/Radio/Radio.styled';

export interface RadioProps {
  value: boolean;
  onChange?: (x: boolean) => void;
  label?: string | ReactNode;
  disable?: boolean;
}

export const Radio = ({ onChange, value, label, disable }: RadioProps) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <SFlex onClick={() => !disable && onChange && onChange(!value)} disable={disable}>
      <AntRadio
        disabled={disable}
        onChange={(e) => (onChange ? onChange(e.target.checked) : undefined)}
        checked={value}
        ref={ref}
      />
      {label && <MarginBox ml={8}>{typeof label === 'string' ? <Text type={'text'}>{label}</Text> : label}</MarginBox>}
    </SFlex>
  );
};
