/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Designation,
  LaborTime,
  LaborTimeDetails,
  LaborTimeDuration,
} from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/model/LaborTime';
import { Technicity } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/model/Technicity';
import { LaborTimeFilterOption } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetIAMLaborTimesResponse';
import { LaborTime as EstimateLaborTime } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/LaborTime';
import { Settings as EstimateSettings } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Settings';
import { Popover } from 'antd';
import { isEmpty } from 'fast-glob/out/utils/string';
import { History } from 'history';
import { TFunction } from 'i18next';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { CogIcon, DotCircleIcon, EyeIcon, InfoCircleIcon, MinusSquareIcon, PlusSquareIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import PrivateComponent from 'composers/PrivateComponent';
import { getSearchVehicleResult } from 'domains/catalog/Catalog.store';
import { FilterByElementOperationState, FilterDefault } from 'domains/catalog/Catalog.types';
import {
  convertCatalogTestLaborTimesToEstimateLaborTimes,
  convertDHLaborTimeToEstimateLaborTime,
} from 'domains/estimate/Estimate.mapper';
import {
  addCatalogLaborTime,
  getCatalogLaborTimes,
  getCurrentEstimateId,
  getEstimateSettings,
  setSelectedTab,
  updateLaborTime,
} from 'domains/estimate/Estimate.store';
import { EstimateTabName, EstimateTabParam, FocusSetting, SettingsTabName } from 'domains/estimate/Estimate.types';
import { useFetchLaborTimes } from 'domains/laborTime/LaborTime.requests';
import {
  getElementsByCodes,
  getLaborTimesByIds,
  getLaborTimeSearchStatusesMapByIds,
  getOperationsByCodes,
} from 'domains/laborTime/LaborTime.store';
import { getLaborSubtableLabelText, LaborSubtableLabel, LaborTimeLocal } from 'domains/laborTime/LaborTime.types';
import { getCurrency, UserRole } from 'domains/user';
import { LaborTimeQuickFilter } from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/LaborTimeQuickFilter';
import {
  IconWrapper,
  SPopoverContent,
  STable,
} from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/LaborTimeSection.styled';
import { EstimateColumn } from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/LaborTimeSectionColumn';
import TestIcon from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/TestIcon/TestIcon';
import {
  getLaborDetailList,
  getTechnicityFieldData,
} from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/utils';
import { SETTINGS_RATES } from 'pages/EstimatePage/SettingTab/SettingsTab';
import { ExpandedLaborTimeDotCircleWrapper } from 'pages/EstimatePage/TableSection/TableSection.styled';
import { theme, ThemeColorKeyType } from 'styles';
import { Box, capitalize, CenteredSpin, ErrorAlert, Flex, Icon, MarginBox, Select, SelectOptionSingle, Text } from 'UI';
import { NotificationLink, notifyTop } from 'UI/Notification/notification';
import { FOUND, getArrayWithoutUndefinedMembers, getData, hasData, LOADING } from 'utils';
import { textFormatter } from 'utils/format';

export interface EstimateLaborTimeDetail {
  itemId: string;
  quantity: number;
}

export function GetTitle(tr: string) {
  return (
    <Text disableGutter type={'h2'}>
      {tr}
    </Text>
  );
}

function LineInTime({ title, number }: { title: React.ReactElement; number?: number }) {
  if (number === undefined || number === 0) return null;
  return (
    <Text type={'light_12_medium_black_85'}>
      <Flex>
        {title}
        <MarginBox ml={5} />
        {number}
      </Flex>
    </Text>
  );
}

function LineInDetails({ title, text }: { title?: React.ReactElement; text?: string }) {
  if (text === undefined || isEmpty(text)) return null;
  return (
    <Text type={'light_12_medium_black_85'}>
      <Flex>
        {title}
        {title && <MarginBox ml={5} />}
        {text}
      </Flex>
    </Text>
  );
}

export function PopoverContentInTime({ time }: { time: LaborTimeDuration }) {
  return (
    <SPopoverContent>
      <LineInTime
        title={<Trans i18nKey="catalog.parts.category.car_parts.labor_time.time.mechanical">Mechanical:</Trans>}
        number={time.mechanicsHours}
      />
      <LineInTime
        title={<Trans i18nKey="catalog.parts.category.car_parts.labor_time.time.trim">Trim:</Trans>}
        number={time.trimHours}
      />
      <LineInTime
        title={<Trans i18nKey="catalog.parts.category.car_parts.labor_time.time.electricity">Electricity:</Trans>}
        number={time.electricityHours}
      />
      <LineInTime
        title={<Trans i18nKey="catalog.parts.category.car_parts.labor_time.time.panelwork">Panelwork:</Trans>}
        number={time.panelWorkHours}
      />
      <LineInTime
        title={<Trans i18nKey="catalog.parts.category.car_parts.labor_time.time.painting">Painting:</Trans>}
        number={time.paintingHours}
      />
    </SPopoverContent>
  );
}

export function PopoverContentInApplicability({ laborTime }: { laborTime: LaborTime }) {
  const applicability = laborTime.applicability;
  return (
    <SPopoverContent>
      {Array.isArray(applicability) &&
        applicability.map((element, index) =>
          element.descriptions.length > 1 ? (
            <Flex key={`applicability-box-${index}`} direction={'column'}>
              <Text type={'light_12_medium_black_85'} key={`applicability-${index}`}>
                {element.name}
              </Text>
              {Array.isArray(element.descriptions) &&
                element.descriptions.map((description, dIndex) => (
                  <Text type={'light_12_medium_black_85'} key={`description-${element.name}-${dIndex}`}>
                    {`- ${description}`}
                  </Text>
                ))}
            </Flex>
          ) : (
            element.descriptions && (
              <Flex key={`applicability-box-${index}`} direction={'column'}>
                <Text type={'light_12_medium_black_85'} key={`applicability-${index}`}>
                  {`${element.name}: ${element.descriptions[0]}`}
                </Text>
              </Flex>
            )
          ),
        )}
    </SPopoverContent>
  );
}

function showExpandIcon(row: LaborTimeLocal): boolean {
  const sub = row.subtable;
  return !(sub === undefined || sub.length === 0);
}

function isSubtableLabelCell(row: LaborTimeLocal) {
  return { colSpan: row.subtableLabel ? 0 : 1 };
}

function colorSwitch(subableLabel: LaborSubtableLabel | undefined): { font: string; circle: ThemeColorKeyType } {
  switch (subableLabel) {
    case 'not_included':
      return {
        font: 'light_14_bold_red',
        circle: 'error',
      };
    case 'included':
      return {
        font: 'link_bold',
        circle: 'clear_blue',
      };
    default:
      return {
        font: 'text_bold',
        circle: 'grey15',
      };
  }
}

export const tableColumns = (
  t: TFunction,
  laborTimeToQuantity: Map<string, EstimateLaborTimeDetail>,
  addLaborTimeToEstimate: (laborTimeLocal: LaborTimeLocal, testLaborTimeToQuantityMap: Map<string, number>) => void,
  updateLaborTimeQuantity: (itemId: string, newQuantity: number) => void,
  isApplicabilityAvailable: boolean,
  currency: string,
  estimateSettings: EstimateSettings | undefined,
  history: History,
) => {
  return [
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.code', 'Code')),
      dataIndex: 'code',
      key: 'code',
      render: function rowSelector() {
        return <></>;
      },
      onCell: function onCell(row: LaborTimeLocal) {
        return { colSpan: row.subtableLabel ? 8 : 1 };
      },
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.designation', 'Designation')),
      dataIndex: 'designation',
      key: 'designation',
      render: function rowSelector(designation: Designation, row: LaborTimeLocal) {
        const testLaborTimeCodes = row.subLaborTimes?.testLaborTimes?.map((lt) => lt.code) ?? [];
        return (
          <Flex justify={'space-between'} align={'center'}>
            <Text type={'highlight'} disableGutter dataCy={'designation'}>
              {designation.title}
            </Text>
            <TestIcon
              essay={designation.essay}
              testWasAdded={testLaborTimeCodes.find((lt) => laborTimeToQuantity.has(lt)) !== undefined}
            />
          </Flex>
        );
      },
      onCell: isSubtableLabelCell,
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.time', 'Time')),
      dataIndex: 'time',
      key: 'time',
      render: function rowSelector(time: LaborTimeDuration) {
        return (
          <Flex justify={'space-evenly'} align={'center'}>
            {!isNaN(time.totalHours) && (
              <>
                <Text type={'highlight'} disableGutter>
                  {time.totalHours}
                </Text>
                <Popover content={<PopoverContentInTime time={time} />} placement={'bottomLeft'} trigger="hover">
                  <IconWrapper>
                    <Icon IconComponent={EyeIcon} color={'grey'} hoverFill={'black'} height={20} width={20} />
                  </IconWrapper>
                </Popover>
              </>
            )}
          </Flex>
        );
      },
      onCell: isSubtableLabelCell,
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.details', 'Details')),
      dataIndex: 'details',
      key: 'details',
      render: function rowSelector(details?: LaborTimeDetails) {
        return (
          <>
            <Flex direction={'column'}>
              {details && (
                <>
                  <LineInDetails text={details.precision} />
                  <LineInDetails text={details.additionalLabel} />
                  <LineInDetails
                    title={
                      <Trans i18nKey="catalog.parts.category.car_parts.labor_time.details.quantity">Quantity:</Trans>
                    }
                    text={details.quantity}
                  />
                  <LineInDetails
                    title={
                      <Trans i18nKey="catalog.parts.category.car_parts.labor_time.details.number_of_layers">
                        Number of layers:
                      </Trans>
                    }
                    text={details.paintType}
                  />
                  <LineInDetails
                    title={
                      <Trans i18nKey="catalog.parts.category.car_parts.labor_time.details.paint_class">
                        Paint class:
                      </Trans>
                    }
                    text={details.paintClass}
                  />
                </>
              )}
            </Flex>
          </>
        );
      },
      onCell: isSubtableLabelCell,
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.technicity', 'Technicity')),
      dataIndex: 'technicity',
      key: 'technicity',
      render: function rowSelector(technicity: Technicity, row: LaborTimeLocal) {
        const laborDetailList = getLaborDetailList(row.time, technicity, estimateSettings?.laborPriceList);
        return (
          <Flex direction={'column'} align={'center'} minWidth={laborDetailList.length > 1 ? 200 : undefined}>
            {laborDetailList.map((laborDetail) => (
              <Text type={'lead_dim'} dataCy={'technicity'} key={`${laborDetail.laborType}_${laborDetail.laborLevel}`}>
                {getTechnicityFieldData(
                  t,
                  laborDetailList.length > 1,
                  laborDetail.laborType,
                  laborDetail.laborLevel,
                  laborDetail.laborTimeRate,
                )}
              </Text>
            ))}
          </Flex>
        );
      },
      onCell: isSubtableLabelCell,
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.applicability', 'Applicability')),
      dataIndex: 'applicability',
      key: 'applicability',
      render: function rowSelector(_designation: Designation, row: LaborTimeLocal) {
        return (
          row.applicability.length > 0 && (
            <Flex justify={'center'}>
              <Popover
                content={<PopoverContentInApplicability laborTime={row} />}
                placement={'bottomLeft'}
                trigger="hover"
              >
                <IconWrapper>
                  <Icon IconComponent={InfoCircleIcon} color={'grey'} hoverFill={'black'} height={20} width={20} />
                </IconWrapper>
              </Popover>
            </Flex>
          )
        );
      },
      onCell: isSubtableLabelCell,
      hidden: !isApplicabilityAvailable,
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.priceVATExcl', 'Price VAT.Excl')),
      dataIndex: 'priceVatExcluded',
      key: 'priceVatExcluded',
      render: function rowSelector(_priceVatExcluded: number, row: LaborTimeLocal) {
        const laborDetailList = getLaborDetailList(row.time, row.technicity, estimateSettings?.laborPriceList);
        const missingLaborTimeRate = laborDetailList.find((labordDetail) => labordDetail.laborTimeRate === undefined);
        const priceVATExcl = !missingLaborTimeRate
          ? laborDetailList.reduce((total, laborDetail) => {
              if (!laborDetail.laborTimeRate) {
                return total;
              }
              const laborTimeRate = parseFloat(laborDetail.laborTimeRate);
              return isNaN(laborTimeRate) ? total : total + laborTimeRate * laborDetail.time;
            }, 0)
          : undefined;
        return (
          <PrivateComponent
            requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
            render={() => (
              <Flex justify={'center'}>
                {priceVATExcl ? (
                  <Text type={'highlight'} disableGutter dataCy={'price-vat-excl'}>
                    {textFormatter.formatCurrency(priceVATExcl, currency)}
                  </Text>
                ) : (
                  <Popover
                    content={`${t(
                      'catalog.parts.category.car_parts.labor_time.notification.set_technicity',
                      'Set the hourly rate of technicity',
                    )}`}
                    placement={'bottomLeft'}
                    trigger="hover"
                  >
                    <IconWrapper>
                      <Icon
                        IconComponent={CogIcon}
                        color={theme.color.clear_blue}
                        height={22}
                        width={23}
                        onClick={() => {
                          const params = new URLSearchParams();
                          params.set(EstimateTabParam, SettingsTabName);
                          params.set(FocusSetting, SETTINGS_RATES);
                          history.push(`${ROUTER_ESTIMATE}?${params}`);
                        }}
                      />
                    </IconWrapper>
                  </Popover>
                )}
              </Flex>
            )}
          />
        );
      },
      onCell: isSubtableLabelCell,
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.estimate', 'Estimate')),
      dataIndex: 'estimate',
      key: 'estimate',
      render: function rowSelector(_estimate: boolean, row: LaborTimeLocal) {
        const laborDetailList = getLaborDetailList(row.time, row.technicity, estimateSettings?.laborPriceList);
        const missingLaborTimeRate = laborDetailList.find((labordDetail) => labordDetail.laborTimeRate === undefined);

        return (
          <PrivateComponent
            requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
            render={() => (
              <EstimateColumn
                row={row}
                laborTimeToQuantity={laborTimeToQuantity}
                addLaborTimeToEstimate={addLaborTimeToEstimate}
                updateLaborTimeQuantity={updateLaborTimeQuantity}
                isTechnicitySet={!missingLaborTimeRate}
                estimateSettings={estimateSettings}
              />
            )}
          />
        );
      },
      onCell: isSubtableLabelCell,
    },
  ].filter((item) => !item.hidden);
};

export function ExpandIcon({
  expanded,
  record,
  onExpand,
}: {
  expanded: boolean;
  record: LaborTimeLocal;
  onExpand: any;
}) {
  const { t } = useTranslation();
  const style = colorSwitch(record.subtableLabel);

  if (record.subtableLabel) {
    return (
      <Flex>
        <MarginBox mr={10} />
        <ExpandedLaborTimeDotCircleWrapper>
          <Icon IconComponent={DotCircleIcon} size={8} color={theme.color[style.circle]} noPointer />
        </ExpandedLaborTimeDotCircleWrapper>
        <Text type={style.font}>{getLaborSubtableLabelText(record.subtableLabel, t)}</Text>
      </Flex>
    );
  }
  return (
    <>
      <Flex justify={'flex-start'} align={'center'}>
        {showExpandIcon(record) ? (
          <Icon
            IconComponent={expanded ? MinusSquareIcon : PlusSquareIcon}
            color={expanded ? 'white' : 'black'}
            background={expanded ? 'black' : 'white'}
            onClick={(e) => onExpand(record, e)}
            size={18}
            display={'block'}
            mr={10}
          />
        ) : (
          <MarginBox mr={28} />
        )}
        <span className={'tableDivider'}></span>
        <Text type={'h2'} disableGutter dataCy={'labor-code'}>
          {record.code}
        </Text>
      </Flex>
    </>
  );
}

interface LaborTimeSectionProps {
  query: string;
  nodeId: string;
  laborTimeIds: string[];
}

const convertToSelectOption = (options: LaborTimeFilterOption[] | undefined): SelectOptionSingle[] =>
  options?.map((option) => ({ value: option.code, title: capitalize(option.label) })) ?? [];

export default function LaborTimeSection({ query, nodeId, laborTimeIds }: LaborTimeSectionProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const vehicle = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const vehicleDetail = vehicle?.vehicleDetail;
  const laborTimesWrapper = useSelector((state: RootState) =>
    getLaborTimesByIds(state, { vehicleKey: vehicleDetail?.vehicleKey, laborTimeIds }),
  );
  const laborTimes = getArrayWithoutUndefinedMembers(laborTimesWrapper?.map((lt) => lt?.data) ?? []);
  const laborTimesSearchStatusMap = useSelector((state: RootState) =>
    getLaborTimeSearchStatusesMapByIds(state, {
      vehicleKey: vehicleDetail?.vehicleKey,
      laborTimeIds,
    }),
  );

  const foundLaborTimesId = laborTimeIds.filter((lt) => hasData(laborTimesSearchStatusMap?.get(lt)));

  const laborTimeSearchStatus = laborTimeIds.reduce((acc, next) => {
    const currStatus = laborTimesSearchStatusMap?.get(next);
    return currStatus === LOADING || acc === LOADING ? LOADING : FOUND;
  }, FOUND);

  const quickFilters = useMemo(() => {
    const currLTS = getArrayWithoutUndefinedMembers(
      foundLaborTimesId.map((id) => laborTimes?.find((lt) => lt?.id === id)),
    );
    const quickFilterSet = new Set(currLTS.map((lt) => lt.designation.title?.split(':')[0].trim() ?? ''));
    return Array.from(quickFilterSet).sort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundLaborTimesId]);

  const currentElementCodes = useMemo(() => {
    const currLTS = getArrayWithoutUndefinedMembers(
      foundLaborTimesId.map((id) => laborTimes?.find((lt) => lt?.id === id)),
    );
    const elementsSet = new Set(getArrayWithoutUndefinedMembers(currLTS.map((lt) => lt?.element)));
    return Array.from(elementsSet).sort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundLaborTimesId]);

  const currentOperationCodes = useMemo(() => {
    const currLTS = getArrayWithoutUndefinedMembers(
      foundLaborTimesId.map((id) => laborTimes?.find((lt) => lt?.id === id)),
    );
    const operationsSet = new Set(getArrayWithoutUndefinedMembers(currLTS.map((lt) => lt?.operation)));
    return Array.from(operationsSet).sort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundLaborTimesId]);

  const elementValues = useSelector((state: RootState) =>
    getElementsByCodes(state, {
      vehicleKey: vehicleDetail?.vehicleKey,
      codes: currentElementCodes,
    }),
  );

  const oprationValues = useSelector((state: RootState) =>
    getOperationsByCodes(state, {
      vehicleKey: vehicleDetail?.vehicleKey,
      codes: currentOperationCodes,
    }),
  );

  const elementsRaw = convertToSelectOption(getData(elementValues));
  const operationsRaw = convertToSelectOption(getData(oprationValues));
  const estimateSettings = useSelector(getEstimateSettings);
  const elements = [
    {
      value: FilterDefault.filterElement,
      title: t('catalog.parts.category.car_parts.labor_time.all_elements', 'All elements'),
    },
  ].concat(elementsRaw);
  const operations = [
    {
      value: FilterDefault.filterOperations,
      title: t('catalog.parts.category.car_parts.labor_time.all_operations', 'All operations'),
    },
  ].concat(operationsRaw);
  const estimateId = useSelector(getCurrentEstimateId);
  const currentLaborTimes = useSelector((state: RootState) => getCatalogLaborTimes(state, estimateId));
  const currency = useSelector(getCurrency);
  const currentQuantityMap = currentLaborTimes.reduce(
    (acc: Map<string, EstimateLaborTimeDetail>, next: EstimateLaborTime) =>
      acc.set(next.code, { itemId: next.itemId, quantity: next.quantity }),
    new Map<string, EstimateLaborTimeDetail>(),
  );

  const [activeQuickFilters, setActiveQuickFilters] = useState<string[]>([]);
  const [filterState, setFilterState] = useState<FilterByElementOperationState>(FilterDefault);
  useEffect(() => {
    setFilterState(FilterDefault);
  }, [query, nodeId]);

  useFetchLaborTimes(query, laborTimeIds);

  const getFilterWidth = (filterArray: SelectOptionSingle[]) => {
    // 30 minimum, 8px per char
    return Math.max(...filterArray.map((e) => e.title.length), 30) * 8;
  };

  const operationsWidth = getFilterWidth(operations);
  const elementsWidth = getFilterWidth(elements);

  const addLaborTimeToEstimate = (laborTimeLocal: LaborTimeLocal, testLaborTimeToQuantityMap: Map<string, number>) => {
    if (vehicleDetail && vehicleDetail.dataHubVehicle && hasData(estimateSettings)) {
      const hasTest = testLaborTimeToQuantityMap.size > 0;
      notifyTop(
        'success',
        <>
          {hasTest ? (
            <Trans i18nKey={'catalog.parts.category.car_parts.labor_time.notification.added_operation_time_with_test'}>
              {"Operation's labor time has been added to your estimate with required test labor time."}
            </Trans>
          ) : (
            <Trans i18nKey={'catalog.parts.category.car_parts.labor_time.notification.added_operation_time'}>
              {"Operation's labor time has been added to your estimate."}
            </Trans>
          )}
        </>,
        undefined,
        <NotificationLink
          onClick={() => {
            dispatch(setSelectedTab(EstimateTabName));
            history.push(ROUTER_ESTIMATE);
          }}
        >
          <Trans i18nKey={'catalog.parts.category.car_parts.labor_time.notification.see_estimate'}>
            {'See estimate'}
          </Trans>
        </NotificationLink>,
      );
      dispatch(
        addCatalogLaborTime({
          laborTime: convertDHLaborTimeToEstimateLaborTime(laborTimeLocal, estimateSettings),
          testLaborTimes: convertCatalogTestLaborTimesToEstimateLaborTimes(
            laborTimeLocal.subLaborTimes?.testLaborTimes ?? [],
            testLaborTimeToQuantityMap,
            estimateSettings,
          ),
        }),
      );
    }
  };

  const updateLaborTimeQuantity = (itemId: string, newQuantity: number) => {
    dispatch(
      updateLaborTime({
        itemId,
        newValue: String(newQuantity),
        field: 'QUANTITY',
      }),
    );
  };

  const isApplicabilityAvailable = laborTimes?.find((laborTime) => laborTime.applicability.length > 0) !== undefined;
  const columns = tableColumns(
    t,
    currentQuantityMap,
    addLaborTimeToEstimate,
    updateLaborTimeQuantity,
    isApplicabilityAvailable,
    currency,
    getData(estimateSettings),
    history,
  );

  const changeQuickFilterState = (filterLabel: string) => {
    const updatedFilters = activeQuickFilters.includes(filterLabel)
      ? activeQuickFilters.filter((filter) => filter !== filterLabel)
      : [...activeQuickFilters, filterLabel];
    setActiveQuickFilters(updatedFilters);
  };

  const filterDatasource = () => {
    const filteredByElements =
      filterState.filterElement === FilterDefault.filterElement
        ? laborTimes ?? []
        : laborTimes?.filter((laborTime) => laborTime.element === filterState.filterElement) ?? [];

    const filteredByDesignation =
      activeQuickFilters.length > 0
        ? filteredByElements.filter((laborTime) => {
            const designationPrefix = laborTime.designation.title?.split(':')[0].trim() ?? '';
            return activeQuickFilters.includes(designationPrefix);
          }) ?? []
        : filteredByElements;

    return filterState.filterOperations === FilterDefault.filterOperations
      ? filteredByDesignation
      : filteredByDesignation.filter((laborTime) => laborTime.operation === filterState.filterOperations);
  };

  const dataSource = filterDatasource();
  return (
    <DataContainer
      data={laborTimeSearchStatus}
      Loading={() => (
        <Box height={150}>
          <CenteredSpin />
        </Box>
      )}
      NotFound={() => (
        <Box height={40}>
          <ErrorAlert
            message={t(
              'catalog.parts.category.car_parts.labor_time.notification.noMatchFound',
              'Sorry, no match found for your research',
            )}
          />
        </Box>
      )}
    >
      <Text type={'section_bold'} dataCy={'labor-time'}>
        {t('catalog.parts.category.car_parts.labor_time', 'Labor time')}
        {` - `}
        <Trans count={dataSource.length} i18nKey={'catalog.parts.category.car_parts.labor_time.items_found'}>
          {'{{count}} items found'}
        </Trans>
      </Text>
      <MarginBox mt={15} />
      <Flex size={'auto'} justify={'flex-start'} direction={'row'} align={'center'}>
        <Box width={operationsWidth}>
          <Select
            options={operations}
            onChange={(value) => {
              setFilterState({ ...filterState, filterOperations: String(value) });
            }}
            bordered
            size={'middle'}
            value={filterState.filterOperations}
            initialValue={FilterDefault.filterOperations}
            disabled={!hasData(laborTimesWrapper)}
          />
        </Box>
        <MarginBox ml={30} />
        <Box width={elementsWidth}>
          <Select
            options={elements}
            onChange={(value) => {
              setFilterState({ ...filterState, filterElement: String(value) });
            }}
            bordered
            size={'middle'}
            initialValue={FilterDefault.filterElement}
            value={filterState.filterElement}
            disabled={!hasData(laborTimesWrapper)}
          />
        </Box>
        <MarginBox ml={30} />
        {quickFilters && quickFilters.length > 1 && (
          <Flex size={'auto'} justify={'flex-start'} direction={'row'} align={'baseline'}>
            <Box width={105}>
              <Text type={'highlight'}>
                {t('catalog.parts.category.car_parts.labor_time.quick_filters', 'Quick filters:')}
              </Text>
            </Box>
            {Array.isArray(quickFilters) &&
              quickFilters.map((filterLabel, index) => {
                return (
                  filterLabel && (
                    <LaborTimeQuickFilter
                      filterLabel={filterLabel}
                      activeFilters={activeQuickFilters}
                      changeQuickFilterState={changeQuickFilterState}
                      key={`filter-${index}`}
                    />
                  )
                );
              })}
          </Flex>
        )}
      </Flex>
      <MarginBox mt={30} />
      <STable<LaborTimeLocal>
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey={(row) => row.uuid}
        expandable={{
          expandIcon: ExpandIcon,
          childrenColumnName: 'subtable',
          indentSize: 0,
        }}
        size={'large'}
        bordered={true}
        data-cy={'table-labor-time'}
      />
    </DataContainer>
  );
}
