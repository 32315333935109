import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Reference } from '@1po/1po-bff-fe-spec/generated/basket/response/model/OtherSection';
import { useDebouncedCallback } from 'use-debounce';
import { RootState } from 'app/AppStore';
import { AngleDownIcon, AngleUpIcon, CheckCircleIcon } from 'assets/icons';
import {
  setReferencesIsSelected,
  updateAllUrgencyFlagsRequest,
  updateOrderMarkRequest,
  updateReferenceUrgencyFlag,
} from 'domains/basket/Basket.store';
import { OtherSectionLocal } from 'domains/basket/Basket.types';
import { collectAllReferenceNumbers, countIsSelectedReferences } from 'domains/order/Order.mapper';
import { getDiscounts, getLoadedPrices } from 'domains/references';
import { getUserCommercialLink } from 'domains/user';
import CartReferenceTable from 'pages/CartPage/CartStep/CartItems/CartReferenceTable';
import { calculateTotalPrice } from 'pages/CartPage/CartStep/CartStep';
import { theme } from 'styles';
import { Flex, Icon, MarginBox, Text } from 'UI';
import Checkbox from 'UI/CheckBox';
import { textFormatter } from 'utils/format';
import { CartItemWrapper, SInput, UrgentWrapper } from '../CartItems.styled';

interface CartItemsOthersProps {
  otherSection: OtherSectionLocal;
  currency?: string;
  addedOtherReference?: string;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  highlightedRef: string | undefined;
  setHighlightedRef: (referenceNumber: string | undefined) => void;
  markRemovedReferences: (numberOfReferences: number) => void;
  disabledRefs: string[];
  highlightedReference: string | undefined;
  setDisableRefs: (ref: string) => void;
}

const CartItemsOthers = ({
  otherSection,
  currency,
  addedOtherReference,
  expanded,
  setExpanded,
  highlightedRef,
  setHighlightedRef,
  markRemovedReferences,
  disabledRefs,
  highlightedReference,
  setDisableRefs,
}: CartItemsOthersProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);
  const [isSelectAllUrgentChecked, setAllUrgentChecked] = useState(false);

  const referenceMap = otherSection.references.reduce(
    (acc, next) => acc.set(next.referenceNumber, next),
    new Map<string, Reference>(),
  );
  const refNumbers = otherSection.references.map((r) => r.referenceNumber);
  const discounts = useSelector((state: RootState) => getDiscounts(state, refNumbers));
  const loadedPrices = useSelector((state: RootState) => getLoadedPrices(state, refNumbers));
  const lastAddedRef = addedOtherReference ? referenceMap.get(addedOtherReference) : undefined;
  const totalPrice = calculateTotalPrice(otherSection?.references, discounts, loadedPrices);
  const commercialLink = useSelector(getUserCommercialLink);
  const isAllOtherRefUrgent =
    otherSection.references.filter((ref) => ref.isUrgent).length === otherSection.references.length ?? false;

  useEffect(() => {
    if (!highlightedRef) return;

    const tableCols = document.getElementById('otherRefTable')?.querySelectorAll('tbody tr td');

    tableCols?.forEach((col) => {
      if (col.innerHTML.includes(highlightedRef)) {
        col.scrollIntoView({ behavior: 'smooth', block: 'center' });

        col.parentElement?.setAttribute('style', 'box-shadow: 5px 8px 20px rgb(0 0 0 / 30%)');

        const removeHighlight = setTimeout(() => {
          col.parentElement?.setAttribute('style', 'box-shadow: none;');
        }, 5000);

        document.addEventListener(
          'mousedown',
          () => {
            col.parentElement?.setAttribute('style', 'box-shadow: none;');
            clearTimeout(removeHighlight);
          },
          { once: true },
        );
      }
    });
    setHighlightedRef(undefined);
  }, [highlightedRef, lastAddedRef, setHighlightedRef]);

  function selectAllUrgent() {
    dispatch(
      updateAllUrgencyFlagsRequest({
        basketReferenceType: 'OTHER',
        vehicleKey: undefined,
        externalBasketId: undefined,
        isUrgent: !isSelectAllUrgentChecked,
      }),
    );
    dispatch(
      updateReferenceUrgencyFlag({
        vehicleKey: undefined,
        externalBasketId: undefined,
        referenceNumbers: otherSection.references.map((r) => r.referenceNumber),
        isUrgent: !isSelectAllUrgentChecked,
      }),
    );
    setAllUrgentChecked(!isSelectAllUrgentChecked);
  }

  useEffect(() => {
    setAllUrgentChecked(isAllOtherRefUrgent);
  }, [isAllOtherRefUrgent]);

  function doSelectAll(isSelected: boolean) {
    const otherRefs = collectAllReferenceNumbers(otherSection?.references, disabledRefs);
    dispatch(
      setReferencesIsSelected({
        isSelected,
        referenceIds: otherRefs,
        vehicleKey: undefined,
      }),
    );
    setIsSelectAllChecked(isSelected);
  }

  function selectAll() {
    doSelectAll(!isSelectAllChecked);
  }

  useEffect(() => {
    const count = countIsSelectedReferences(otherSection?.references);
    const numOfRef = otherSection?.references ? otherSection.references.length : 0;
    const disabled = otherSection.references.filter((ref) => disabledRefs.includes(ref.referenceNumber));

    setIsSelectAllChecked(count > 0 ? count === numOfRef - disabled.length : false);
  }, [otherSection, disabledRefs]);

  const setOrderMarkDebounce = useDebouncedCallback((value) => {
    dispatch(
      updateOrderMarkRequest({
        basketReferenceType: 'OTHER',
        vehicleKey: undefined,
        externalBasketId: undefined,
        orderMark: value,
      }),
    );
  }, 500);

  return (
    <>
      <CartItemWrapper expanded={expanded}>
        <Flex direction={'row'} minWidth={1000}>
          <MarginBox mt={27} ml={36}>
            <Checkbox value={isSelectAllChecked} onChange={selectAll} />
          </MarginBox>
          <MarginBox mt={27} ml={36}>
            <Flex direction={'row'}>
              <Text type={'light_14_black_85'}>{t('cart.other_articles', 'Other articles in your cart')}</Text>
            </Flex>
          </MarginBox>
          {commercialLink?.canPlaceUrgentOrders ? (
            <Flex justify={'flex-end'}>
              <MarginBox mr={67} mt={26}>
                <UrgentWrapper onClick={selectAllUrgent}>
                  <Flex>
                    <Checkbox value={isSelectAllUrgentChecked} />
                    <Icon
                      mr={5}
                      ml={5}
                      IconComponent={CheckCircleIcon}
                      size={12}
                      display={'inline'}
                      color={theme.color.brand}
                      background={theme.color.brand_black}
                      round
                    />
                    <Text type={'link'}>{t('cart.urgent_delivery', 'Urgent delivery')}</Text>
                  </Flex>
                </UrgentWrapper>
                <MarginBox mt={1} ml={20}>
                  <Text type={'light_dimmer'}>{t('cart.urgent_delivery_note', '(Select for faster delivery)')}</Text>
                </MarginBox>
              </MarginBox>
            </Flex>
          ) : (
            <Flex />
          )}
          <MarginBox mt={13} mr={36}>
            <Flex direction={'row'}>
              <Text type={'highlight'}>{t('common.price.total_VAT_excl', 'Total VAT. Excl')}</Text>
            </Flex>
            <Flex direction={'row'}>
              <Text type={'highlight'}>{textFormatter.formatCurrency(totalPrice, currency)}</Text>
            </Flex>
          </MarginBox>
          <MarginBox mt={27} mr={36}>
            <Icon IconComponent={expanded ? AngleUpIcon : AngleDownIcon} onClick={() => setExpanded(!expanded)} />
          </MarginBox>
        </Flex>
        {expanded && (
          <Flex direction={'row'}>
            <MarginBox ml={37} mt={17}>
              <SInput
                maxLength={20}
                onChange={(val) => {
                  setOrderMarkDebounce(val);
                }}
                bordered
                initialValue={otherSection.orderMark}
                placeholder={t('order.order_mark', 'Click here to add your order mark')}
              />
            </MarginBox>
          </Flex>
        )}
        {expanded && (
          <div id={'otherRefTable'}>
            <Flex direction={'row'}>
              <CartReferenceTable
                markRemovedReferences={markRemovedReferences}
                disabledRefs={disabledRefs}
                setDisableRefs={setDisableRefs}
                highlightedReference={highlightedReference}
              />
            </Flex>
          </div>
        )}
      </CartItemWrapper>
    </>
  );
};

export default CartItemsOthers;
