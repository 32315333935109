import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OrderDeliveryType,
  OrderMKTPDeliveryMode,
} from '@1po/1po-bff-fe-spec/generated/order/request/CheckoutOrderRequest';
import { Seller } from '@1po/1po-bff-fe-spec/generated/user/model/Seller';
import { Radio, Space } from 'antd';
import PrivateComponent from 'composers/PrivateComponent';
import { SText } from 'pages/CartPage/ValidationStep/DeliveryAddressSection.styled';
import { Box, Flex, Text } from 'UI';
import { Country } from 'utils/i18n/Country';

interface DeliveryAddressSectionProps {
  validationDeliveryAddress: OrderDeliveryType;
  updateDeliveryAddress: (value: OrderDeliveryType) => void;
  orderMKTPDeliveryMode: OrderMKTPDeliveryMode | undefined;
  basketHasMKTPReference: boolean;
  updateOrderMKTPDeliveryMode: (value: OrderMKTPDeliveryMode) => void;
  activeSeller: Seller | null;
}

const DeliveryAddressSection = ({
  validationDeliveryAddress,
  updateDeliveryAddress,
  orderMKTPDeliveryMode,
  basketHasMKTPReference,
  updateOrderMKTPDeliveryMode,
  activeSeller,
}: DeliveryAddressSectionProps) => {
  const { t } = useTranslation();

  return (
    <Flex direction={'column'} dataCy={'section-delivery-options'}>
      <Text type={'h1_banner_light'}>{`1. ${t('order.validation.delivery.options', 'Delivery options')}`}</Text>
      <Box height={20} />
      <Text type={'section'}>
        {t(
          'order.validation.address.description',
          'The selected delivery options will apply by default to your entire order.',
        )}
      </Text>
      <Box height={30} />
      <Text type={'h4_paragraph'} dataCy={'title-delivery-address'}>
        {t('order.validation.address', 'Delivery address')}
      </Text>
      <Box height={30} />
      <Radio.Group onChange={(event) => updateDeliveryAddress(event.target.value)} value={validationDeliveryAddress}>
        <Space direction={'vertical'}>
          <Radio value={'OWN_ADDRESS'}>
            <Text type={'light_14_black_65'} cursor={'pointer'}>
              {t('order.validation.address.options.my_address', 'Deliver to my address')}
            </Text>
          </Radio>
          <Box height={30} />
          <Radio value={'DEALER_PICKUP'}>
            <Text type={'light_14_black_65'} cursor={'pointer'}>
              {`${t('order.validation.address.options.dealer_address', 'Dealer pick-up at:')} ${activeSeller?.name}`}
            </Text>
          </Radio>
        </Space>
      </Radio.Group>
      {basketHasMKTPReference && (
        <PrivateComponent
          render={() => (
            <>
              <Box height={30} />
              <MKTPDelivery
                orderMKTPDeliveryMode={orderMKTPDeliveryMode}
                updateOrderMKTPDeliveryMode={updateOrderMKTPDeliveryMode}
              />
            </>
          )}
          requiredCountries={[Country.IT]}
        />
      )}
    </Flex>
  );
};

interface MKTPDeliveryProps {
  orderMKTPDeliveryMode: OrderMKTPDeliveryMode | undefined;
  updateOrderMKTPDeliveryMode: (value: OrderMKTPDeliveryMode) => void;
}

function MKTPDelivery(props: MKTPDeliveryProps) {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.orderMKTPDeliveryMode === undefined) {
      props.updateOrderMKTPDeliveryMode('STANDARD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Text type={'h4_paragraph'}>
        {t('order.validation.delivery.description', 'Shipping cost for other brand parts')}
      </Text>
      <Box height={30} />
      <Radio.Group
        onChange={(event) => props.updateOrderMKTPDeliveryMode(event.target.value)}
        value={props.orderMKTPDeliveryMode}
      >
        <Flex direction={'column'}>
          <Radio value={'STANDARD'}>
            <Text type={'h6'} cursor={'pointer'}>
              {t('order.validation.delivery.options.standard', 'Standard delivery - 10,00€')}
            </Text>
          </Radio>
          <SText type={'light_14_black_85'}>
            {t('order.validation.delivery.options.standard.description', 'Receive all your items at once')}
          </SText>
          <Box height={30} />
          <Radio value={'MULTIPLE'}>
            <Text type={'h6'} cursor={'pointer'}>
              {t('order.validation.delivery.options.multiple', 'Multiple deliveries - 10,00€ per delivery')}
            </Text>
          </Radio>
          <SText type={'light_14_black_85'}>
            {t(
              'order.validation.delivery.options.multiple.description',
              'Receive your items as soon they are available',
            )}
          </SText>
        </Flex>
      </Radio.Group>
    </>
  );
}

export default DeliveryAddressSection;
