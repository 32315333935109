import React, { useCallback, useState } from 'react';
import { Slider } from 'antd';

export interface InputSliderBaseProps {
  min?: number;
  max?: number;
  isRange?: boolean;
}

export interface InputSliderProps extends InputSliderBaseProps {
  value: number;
  onChange?: (x: number) => void;
}

export const InputSlider = ({ value, onChange, min, max }: InputSliderProps) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = useCallback(
    (val: number) => {
      setInputValue(val);
      if (onChange) {
        onChange(val);
      }
    },
    [setInputValue, onChange],
  );
  return <Slider value={inputValue} onChange={handleChange} min={min} max={max} />;
};

export interface InputRangeProps extends InputSliderBaseProps {
  value: [number, number];
  onChange?: (x: [number, number]) => void;
  tipFormatter: ((value?: number | undefined) => React.ReactNode) | null | undefined;
}

export const InputRange = ({ value, onChange, min, max, tipFormatter }: InputRangeProps) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = useCallback(
    (val: [number, number]) => {
      setInputValue(val);
      if (onChange) {
        onChange(val);
      }
    },
    [setInputValue, onChange],
  );

  return <Slider range value={inputValue} onChange={handleChange} min={min} max={max} tipFormatter={tipFormatter} />;
};
