import styled from 'styled-components';
import { CleverInput, Flex } from 'UI';

export const SInput = styled(CleverInput)`
  height: 40px;
  width: 750px;
`;

export const CartItemWrapper = styled.div<{ expanded: boolean }>`
  width: 100%;
  margin: 0 0 30px 0;
  border-bottom: solid 1px grey;
  background-color: ${({ expanded, theme }) => expanded && theme.color.grey95};
`;

export const UrgentWrapper = styled(Flex)`
  :hover {
    cursor: pointer;
  }
`;
