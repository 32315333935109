export class RequestResponseMappingManager {
  // Static map to hold the mappings
  private static requestResponseMapping: Map<string, string> = new Map();

  public static addMapping(url: string, saga: string): void {
    this.requestResponseMapping.set(url, saga);
  }

  public static isResponseMapped(saga: string): boolean {
    return Array.from(this.requestResponseMapping.values()).some((mappedSaga) => mappedSaga === saga);
  }

  public static getSagaByUrl(url: string): string | undefined {
    return this.requestResponseMapping.get(url);
  }
}
