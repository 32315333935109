const PREFIX = "/backoffice/";
export const ELECTRONIC_PARTS_REPAIR_UPLOAD_REFERENCES_FILE_URL = PREFIX + "electronic-parts-repair/upload/references";
export const ELECTRONIC_PARTS_REPAIR_REPLACE_REFERENCES_FILE_URL = PREFIX + "electronic-parts-repair/replace/references";
export const ELECTRONIC_PARTS_REPAIR_GET_FILE_URL = PREFIX + "electronic-parts-repair/get-file";
export const DELIVERY_LEAD_TIME_GET_URL = PREFIX + "delivery-lead-time/get";
export const DELIVERY_LEAD_TIME_MODIFY_URL = PREFIX + "delivery-lead-time/modify";
export const PROMOTION_SAVE_DISCOUNT_URL = PREFIX + "promotion/save-discount";
export const PROMOTION_SAVE_FLASH_TIME_LIMITED_URL = PREFIX + "promotion/save-flash-time-limited";
export const PROMOTION_SAVE_FLASH_QUANTITY_LIMITED_URL = PREFIX + "promotion/save-flash-quantity-limited";
export const PROMOTION_SAVE_BANNER_URL = PREFIX + "promotion/save-banner";
export const PROMOTION_ADJUST_BY_R1_URL = PREFIX + "promotion/adjust-promotion-by-r1";
export const PROMOTION_PUBLISH_URL = PREFIX + "promotion/publish";
export const PROMOTION_ARCHIVE_URL = PREFIX + "promotion/archive";
export const PROMOTION_DELETE_URL = PREFIX + "promotion/delete";
export const PROMOTION_GET_URL = PREFIX + "promotion/get";
export const PROMOTIONS_SUBSCRIBE_CURRENT_PROMOTIONS_URL = PREFIX + "promotion/subscribe-current-promotions";
export const PROMOTIONS_GET_CURRENT_URL = PREFIX + "promotion/get-current";
export const PROMOTIONS_GET_DASHBOARD_URL = PREFIX + "promotion/get-dashboard-all";
export const PROMOTIONS_R1_USER_GET_URL = PREFIX + "promotion/get-r1-user-promotions";
export const PROMOTIONS_COUNTRY_FOR_R1_GET_URL = PREFIX + "promotion/get-country-for-r1-promotions";
export const PROMOTIONS_COUNTRY_ADMIN_GET_URL = PREFIX + "promotion/get-country-admin-promotions";
export const PROMOTIONS_R1_FOR_COUNTRY_ADMIN_GET_URL = PREFIX + "promotion/get-r1-promotions-for-country-admin";
export const PROMOTION_GET_FULL_URL = PREFIX + "promotion/get-full";
export const PROMOTION_GET_BANNERS_URL = PREFIX + "promotion/get-banners";
export const PROMOTION_GET_REFERENCES_DISCOUNTS_URL = PREFIX + "promotion/get-references-discounts";
export const PROMOTION_UPLOAD_REFERENCES_FILE_URL = PREFIX + "promotion/upload-references-file";
export const PROMOTION_UPLOAD_TARGETED_AUDIENCE_FILE_URL = PREFIX + "promotion/upload-targeted-audience-file";
export const PROMOTION_VALIDATE_PRODUCTS_URL = PREFIX + "promotion/validate-products";
export const PROMOTION_GET_NUMBER_OF_NOT_YET_ADJUSTED_PROMOTIONS_URL = PREFIX + "promotion/get-number-of-not-yet-adjusted-promotions";
export const INFORMATION_SAVE_URL = PREFIX + "information/save";
export const INFORMATION_PUBLISH_URL = PREFIX + "information/publish";
export const INFORMATION_ARCHIVE_URL = PREFIX + "information/archive";
export const INFORMATION_GET_URL = PREFIX + "information/get";
export const INFORMATIONS_GET_URL = PREFIX + "information/get-all";
export const INFORMATION_GET_BANNERS_URL = PREFIX + "information/get-banners";
export const INFORMATION_GET_BANNERS_URL_REST = PREFIX + "information/banners";
export const INFORMATION_TEMPLATE_SAVE_URL = PREFIX + "information-template/save";
export const CROSS_SELLING_UPLOAD_FILE_URL = PREFIX + "cross-selling/upload";
export const CROSS_SELLING_REPLACE_FILE_URL = PREFIX + "cross-selling/replace";
export const CROSS_SELLING_DELETE_FILE_URL = PREFIX + "cross-selling/delete";
export const CROSS_SELLING_GET_FILE_URL = PREFIX + "cross-selling/get-file";
export const CROSS_SELLING_GET_REFERENCES_URL = PREFIX + "cross-selling/get-references";
