import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { useDisclosure } from 'components/Dialog';
import { getPicture } from 'domains/pictures/Pictures.store';
import { toCreatePromotionModel } from 'domains/promotion/Promotion.mapper';
import {
  adjustPromotionByR1RequestSaga,
  findStatusTypeByPromotionID,
  getFullPromotion,
} from 'domains/promotion/Promotion.store';
import { PromotionAdditionalDiscountPreviewParam } from 'domains/promotion/Promotion.types';
import { SCard } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/CreatePromotion.styled';
import { PROMOTION } from 'pages/BackOfficePage/BackOfficePage.type';
import { Banner, Box, CenteredSpin, Flex, MarginBox, Modal, Text, WhiteButton, YellowButton } from 'UI';
import Checkbox from 'UI/CheckBox';
import { getData } from 'utils';
import { getDiscountPromotionsData } from '../CreatePromotion/PublicationStep/PublicationStep.types';
import { TableDiscount } from '../CreatePromotion/PublicationStep/TableDiscount';
import { DialogHandle } from '../PromotionCard/PromotionDetailMenu';

interface AdjustPromotionProps {
  promotionId: string;
  isAdjusted: boolean | undefined;
}

const AdjustPromotionDialog = forwardRef<DialogHandle, AdjustPromotionProps>((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const disclosure = useDisclosure();
  const history = useHistory();
  const params = new URLSearchParams();
  const { isOpen, onClose, onOpen } = disclosure;
  const fullPromotionData = useSelector((state: RootState) => getFullPromotion(state, props.promotionId));

  const promotion = getData(fullPromotionData);
  const promotionStatus = useSelector((state: RootState) => findStatusTypeByPromotionID(state, props.promotionId));
  const [showApplyDiscountSection, setShowApplyDiscountSection] = useState(props.isAdjusted ?? false);

  useEffect(() => {
    setShowApplyDiscountSection(props.isAdjusted ?? false);
  }, [props.isAdjusted]);

  useImperativeHandle(ref, () => ({
    handleDialogOpen() {
      onOpen();
    },
  }));

  const promotionImage =
    showApplyDiscountSection && promotion?.additionalBanner?.key ? promotion?.additionalBanner : promotion?.banner;
  const bannerImage = useSelector((state: RootState) => getPicture(state, promotionImage?.key));
  const imageBase64 = bannerImage ?? `${promotionImage?.base64}`;

  const handleReject = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleAccept = useCallback(() => {
    if (promotion) {
      dispatch(
        adjustPromotionByR1RequestSaga({
          promotionId: promotion.promotionId,
          promotionType: promotion.promotionType,
          applyDiscount: showApplyDiscountSection,
        }),
      );
    }
    onClose();
  }, [onClose, dispatch, promotion, showApplyDiscountSection]);

  return (
    <Modal
      title={
        <MarginBox mt={20} mx={20}>
          <Text type={'h2'} disableGutter>
            {t('promotion.adjust_dialog.title', 'Edit Publication')}
          </Text>
        </MarginBox>
      }
      onCancel={handleReject}
      visible={isOpen}
      closable
      width={800}
      footer={
        <MarginBox mb={30} mx={30}>
          <Flex>
            <WhiteButton stretch onClick={handleReject}>
              {t('common.action.cancel', 'Cancel')}
            </WhiteButton>
            <MarginBox ml={30} />
            <YellowButton stretch onClick={handleAccept}>
              {t('common.action.save', 'Save')}
            </YellowButton>
          </Flex>
        </MarginBox>
      }
    >
      <DataContainer data={fullPromotionData}>
        <Flex direction={'column'}>
          {promotion && (
            <TableDiscount
              data={getDiscountPromotionsData(
                toCreatePromotionModel({
                  promotion: promotion,
                  isPublished: promotionStatus === 'PUBLISHED',
                }),
                showApplyDiscountSection,
              )}
            />
          )}
          <MarginBox mt={30} />
          <Flex direction={'column'}>
            <Text type={'h5_bold'}>
              {t('backoffice.promotion.additional_discount_rate', 'Additional discount rate')}
            </Text>
            <Text type={'text'}>
              {t(
                'backoffice.promotion.additional_discount_rate.description',
                'Add suggested discount rate for your network.',
              )}
            </Text>
            <MarginBox mt={5} />
            <Checkbox
              value={showApplyDiscountSection}
              onChange={(newValue) => setShowApplyDiscountSection(newValue)}
              label={t('backoffice.promotion.additional_discount_apply', 'Apply + {{ discount }} %', {
                discount: promotion?.additionalDiscount ?? 0,
              })}
            />
            <MarginBox mt={30} />
          </Flex>
          {bannerImage && (
            <Flex direction={'column'}>
              <SCard>
                <DataContainer
                  data={imageBase64}
                  Loading={() => (
                    <Box height={250}>
                      <CenteredSpin />
                    </Box>
                  )}
                  Error={() => (
                    <Box height={250}>
                      <ErrorWithLabel />
                    </Box>
                  )}
                >
                  <Banner image={imageBase64} base64 height={250} onlyImage />
                </DataContainer>
              </SCard>
              <MarginBox mt={30} />
            </Flex>
          )}
        </Flex>
        <Text
          type={'text'}
          displayStyle={'link'}
          hoverUnderLine
          cursor={'pointer'}
          onClick={() => {
            params.set(PromotionAdditionalDiscountPreviewParam, showApplyDiscountSection.toString());
            history.push(`/${PROMOTION}/${props.promotionId}?${params}`);
          }}
        >
          {t('backoffice.promotion.see_promotion_page', 'See promotion page')}
        </Text>
      </DataContainer>
    </Modal>
  );
});

AdjustPromotionDialog.displayName = 'AdjustPromotionDialog';
export default AdjustPromotionDialog;
