import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import { CATALOG, ROUTER_HOME } from 'app/AppRouter';
import FirstHelpShadow from 'components/Help/FirstHelpShadow';
import AdvertisementSpace from 'components/Page/Header/AdvertisementSpace/AdvertisementSpace';
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_COLLAPSED } from 'components/Page/PageSideBar/PageSideBar';
import { getShowFirstHelp, setShowFirstHelp } from 'domains/firstHelp/FirstHelp.store';
import { Container, emptyPadding, StickyBoxCustom } from 'UI';
import { useSmall } from 'utils';
import { OFFSET_HEADER } from 'utils/hooks/useOffsetTop';
import { Footer } from './Footer';
import SubHeader from './Header/SubHeader/SubHeader';
import TopHeader from './Header/TopHeader';
import { FirstHelpBlock, SContent } from './Page.styled';
import PageSideBar from './PageSideBar';

interface PageProps {
  children: React.ReactNode;
  collapsedSideBar?: boolean;
  openedSideBar?: boolean;
  displayBanner?: boolean;
}

export const Page = ({ children, openedSideBar = true, collapsedSideBar = true, displayBanner = true }: PageProps) => {
  const small = useSmall();
  const [sideBarOpened, setSideBarOpened] = useState(openedSideBar);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const { showStreamId } = useSelector(getShowFirstHelp);
  const history = useHistory();
  const [activeUrl, setActiveUrl] = useState<string>('');
  const dispatch = useDispatch();
  const isCatalog = history.location.pathname.startsWith(CATALOG);
  const isHomepage = history.location.pathname === ROUTER_HOME;

  const setNewWindowHeight = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    setActiveUrl(history.location.pathname);
    if (showStreamId) {
      dispatch(setShowFirstHelp(undefined));
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  useEffect(() => {
    setSideBarOpened(openedSideBar);
  }, [openedSideBar]);

  useEffect(() => {
    window.addEventListener('resize', setNewWindowHeight);
    return () => {
      window.removeEventListener('resize', setNewWindowHeight);
    };
  }, []);

  const switchMenu = () => setSideBarOpened(!sideBarOpened);
  const sidebarWidth = collapsedSideBar ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH;
  return (
    <>
      {showStreamId && showStreamId !== 'close' && <FirstHelpBlock />}
      <Container padding={emptyPadding}>
        <TopHeader switchMenu={switchMenu} sidebarWidth={sideBarOpened ? sidebarWidth : 0} />
        <Layout>
          {!small && <PageSideBar opened={sideBarOpened} collapsed={collapsedSideBar} activeUrl={activeUrl} />}
          <Layout>
            <SContent $windowHeight={windowHeight}>
              <FirstHelpShadow header={false} />
              {isCatalog ? (
                <StickyBoxCustom offsetTop={OFFSET_HEADER} zIndex={899}>
                  <SubHeader />
                </StickyBoxCustom>
              ) : isHomepage ? null : (
                <SubHeader />
              )}
              {displayBanner && <AdvertisementSpace />}
              {children}
            </SContent>
            <Footer />
          </Layout>
        </Layout>
      </Container>
    </>
  );
};
