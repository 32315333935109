import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { OrderReferenceItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { RootState } from 'app/AppStore';
import { AwardIcon, CircleXMark } from 'assets/icons';
import { MotrioWarranty } from 'components/MotrioWarranty/MotrioWarranty';
import { SCloseIcon, SFlex, SModal, SModalHeader } from 'components/MotrioWarranty/MotrioWarrantyButton.styled';
import {
  getEnableOrderMotrioWarrantyRequest,
  isAppModalOpen,
  setCloseAppModal,
  setOpenAppModal,
} from 'domains/appContext/AppContext.store';
import { theme } from 'styles';
import { Icon, MarginBox, Text } from 'UI';

interface MotrioWarrantyButtonProps {
  reference: OrderReferenceItem;
  vehicleDetail?: VehicleDetail;
  disabled?: boolean;
}

const motrioWarrantyModalIdStr = 'motrio_warranty_modal_';

export function MotrioWarrantyButton(props: MotrioWarrantyButtonProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modalId = motrioWarrantyModalIdStr + props.reference.referenceNumber;
  const isOpen = useSelector((state: RootState) => isAppModalOpen(state, modalId));
  const isEnableOrderMotrioWarrantyRequest = useSelector(getEnableOrderMotrioWarrantyRequest);

  if (!isEnableOrderMotrioWarrantyRequest) {
    return <></>;
  }

  function onCancel() {
    dispatch(setCloseAppModal(modalId));
  }

  return (
    <>
      <SFlex size={0} onClick={() => !props.disabled && dispatch(setOpenAppModal(modalId))} disabled={props.disabled}>
        <Icon IconComponent={AwardIcon} round size={12} color={theme.color.warning} disabled={props.disabled} />
        <MarginBox ml={5} />
        <Text type={'description'} displayStyle={props.disabled ? 'disabled' : undefined}>
          {t('motrio.warranty', 'Warranty')}
        </Text>
      </SFlex>
      <SModal zIndex={1000} width={'100%'} footer={''} visible={isOpen} onCancel={onCancel} closable={false}>
        <SModalHeader justify={'flex-end'} align={'center'}>
          <CloseIcon onClick={onCancel} />
        </SModalHeader>
        <MotrioWarranty reference={props.reference} vehicleDetail={props.vehicleDetail} />
      </SModal>
    </>
  );
}

export function CloseIcon({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <SCloseIcon onClick={onClick} align={'center'} size={0}>
      <Text type={'light_14_bold_black_85'}>{t('motrio.warranty.action.close', 'Close')}</Text>
      <MarginBox ml={5} />
      <Icon IconComponent={CircleXMark} size={20} color={theme.color.grey25} />
    </SCloseIcon>
  );
}
