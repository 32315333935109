import {
  GET_APPLICATION_CONFIG_RESPONSE,
  GET_BUILD_VERSIONS_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { ModifyMockConfigRequest } from '@1po/1po-bff-fe-spec/generated/config/request/ModifyMockConfigRequest';
import { ApplicationConfigResponse } from '@1po/1po-bff-fe-spec/generated/config/response/ApplicationConfigResponse';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { FOUND, hasData, NO_DATA } from 'utils';
import {
  APP_CONTEXT_NAMESPACE,
  AppContextState,
  BuildVersion,
  GET_APP_CONFIG,
  GET_BUILD_VERSIONS,
  MODIFY_MOCK_CONFIG,
} from './AppContext.types';

// Init state
const initialState: AppContextState = {
  buildVersions: [],
  appConfig: {
    searchStatus: undefined,
  },
  openedModals: new Set<string>(),
};

// Saga actions
export const fetchBuildVersions = createAction(GET_BUILD_VERSIONS);
export const fetchBuildVersionsResponse = createAction(GET_BUILD_VERSIONS_RESPONSE);
export const fetchAppConfig = createAction(GET_APP_CONFIG);
export const fetchAppConfigResponse = createAction(GET_APPLICATION_CONFIG_RESPONSE);
export const modifyMockConfig = createAction<ModifyMockConfigRequest>(MODIFY_MOCK_CONFIG);

// Slice
const slice = createSlice({
  name: APP_CONTEXT_NAMESPACE,
  initialState,
  reducers: {
    setBuildVersions: (state, action: PayloadAction<BuildVersion[] | NO_DATA>) => {
      state.buildVersions = hasData(action.payload)
        ? action.payload.sort((a, b) => (a.name > b.name ? 1 : -1))
        : action.payload;
    },
    setAppConfig: (state, action: PayloadAction<ApplicationConfigResponse>) => {
      state.appConfig = {
        searchStatus: FOUND,
        data: action.payload,
      };
    },
    setOpenAppModal: (state, { payload }: PayloadAction<string>) => {
      state.openedModals.add(payload);
    },
    setCloseAppModal: (state, { payload }: PayloadAction<string>) => {
      state.openedModals.delete(payload);
    },
  },
});

// Actions
export const { setBuildVersions, setAppConfig, setOpenAppModal, setCloseAppModal } = slice.actions;

// Getters/Selectors
export const getBuildVersions = createSelector(
  (state: RootState) => state.appContext.buildVersions,
  (buildVersions) => buildVersions,
);

export const getAppConfig = createSelector(
  (state: RootState) => state.appContext.appConfig,
  (appConfig) => appConfig,
);

export const isRemoteLoggingEnabled = createSelector(
  getAppConfig,
  (appConfig) => appConfig?.data?.clientConfig.remoteLoggingEnabled ?? false,
);

export const getMockConfig = createSelector(getAppConfig, (appConfig) => appConfig?.data?.mockConfig);

export const getUserDelegationEnabled = createSelector(
  getAppConfig,
  (appConfig) => appConfig?.data?.clientConfig.userDelegationEnabled,
);

export const getPromotionsForTodayEnabled = createSelector(
  getAppConfig,
  (appConfig) => appConfig?.data?.clientConfig?.promotionsCreationForTodayEnabled,
);

export const getFirstHelpEnabled = createSelector(
  getAppConfig,
  (appConfig) => appConfig?.data?.clientConfig?.firstHelpEnabled,
);

export const getMyStoreEnabled = createSelector(
  getAppConfig,
  (appConfig) => appConfig?.data?.clientConfig?.myStoreEnabled,
);

export const getMyStoreTiresEnabled = createSelector(
  getAppConfig,
  (appConfig) => appConfig?.data?.clientConfig?.myStoreTiresEnabled,
);

export const getDisplayTimeoutNotifications = createSelector(
  getAppConfig,
  (appConfig) => appConfig?.data?.clientConfig?.displayTimeoutNotifications,
);

export const getOrderReturnEnabled = createSelector(
  getAppConfig,
  (appConfig) => appConfig?.data?.clientConfig?.orderReturnEnabled,
);

export const getElectronicPartsRepairLink = createSelector(
  getAppConfig,
  (appConfig) => appConfig?.data?.clientConfig?.electronicPartsRepairLink,
);

export const getTechnicalDocumentationLink = createSelector(
  getAppConfig,
  (appConfig) => appConfig?.data?.clientConfig?.technicalDocumentationLink,
);

export const getReuseLink = createSelector(getAppConfig, (appConfig) => appConfig?.data?.clientConfig?.reuseLink);

export const getEnableOrderMotrioWarrantyRequest = createSelector(
  getAppConfig,
  (appConfig) => appConfig?.data?.clientConfig?.enableOrderMotrioWarrantyRequest,
);

export const isAppModalOpen = createSelector(
  (state: RootState) => state.appContext.openedModals,
  (state: RootState, id: string) => id,
  (openedModals, id) => openedModals.has(id),
);

// Export reducer
export default slice.reducer;
