import React from 'react';
import { Trans } from 'react-i18next';
import { DealerType, OrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { pdf } from '@react-pdf/renderer';
import { OrderPdfData } from 'pages/MyOrdersDetailPdf';
import { MyOrdersDetailPdfPage } from 'pages/MyOrdersDetailPdf/MyOrderDetailPdf';
import { OrdersListPdfData } from 'pages/MyOrdersListPdf';
import { MyOrdersListPdfPage } from 'pages/MyOrdersListPdf/MyOrdersListPdf';
import { textFormatter } from 'utils';

export const handlePrintList = async (orders: OrderItem[] | undefined, selectedOrders: string[]) => {
  const pdfData: OrdersListPdfData = {
    date: new Date(),
    text: {
      title: <Trans i18nKey="my_orders.pdf.list">My Orders</Trans>,
      delivery: <Trans i18nKey="my_orders.pdf.delivery">Delivery</Trans>,
      orderBy: <Trans i18nKey="my_orders.pdf.order_by">Order by</Trans>,
      orderDate: <Trans i18nKey="my_orders.pdf.order_date">Order Date</Trans>,
      orderLine: <Trans i18nKey="my_orders.pdf.order_mark">Order Mark</Trans>,
      orderNumber: <Trans i18nKey="my_orders.pdf.order_number">Order Number</Trans>,
      price: <Trans i18nKey="my_orders.pdf.price">Price</Trans>,
      status: <Trans i18nKey="my_orders.pdf.status">Status</Trans>,
      vehicle: <Trans i18nKey="my_orders.vehicle">Vehicle</Trans>,
    },
    selectedOrders: orders ? orders.filter((o) => selectedOrders.indexOf(o.internalOrderId) !== -1) : [],
  };
  const blob = await pdf(<MyOrdersListPdfPage data={pdfData} />).toBlob();
  const url = URL.createObjectURL(blob);
  if (url) {
    window.open(url);
  }
};

export const handlePrintDetail = async (
  orders: OrderItem[] | undefined,
  selectedOrders: string[],
  dealerType: DealerType | undefined,
  r1Country: string,
  orderReturnEnabled: boolean,
) => {
  if (selectedOrders.length !== 1 || !orders) {
    return;
  }

  const order: OrderItem | undefined = orders.find((o) => selectedOrders.indexOf(o.internalOrderId) !== -1);

  if (!order) {
    return;
  }

  const orderNumber = Number(order.externalOrderId ?? order.internalOrderId);
  const items = order.orderedReferences.length;
  const date = textFormatter.formatDate(new Date());

  const pdfData: OrderPdfData = {
    orderReturnEnabled,
    dealerType,
    r1Country,
    order,
    text: {
      title: <Trans i18nKey="my_orders.details">Order details</Trans>,
      orderNumber:
        order.orderStatus === 'MAYBE_PLACED' ? (
          <Trans i18nKey="my_orders.pdf.order_processing_after_timeout">Order n°: Processing</Trans>
        ) : (
          <Trans i18nKey="my_orders.pdf.order_nummber">Order n°: {{ orderNumber }}</Trans>
        ),
      date: <Trans i18nKey={'estimate.pdf.date'}>Date: {{ date }}</Trans>,
      clientInformation: <Trans i18nKey="my_orders.pdf.clientInformation">Client information</Trans>,
      informations: <Trans i18nKey="my_orders.pdf.informations">Informations</Trans>,
      contactSection: <Trans i18nKey="my_orders.pdf.contact">Contact</Trans>,
      billingAddressSection: <Trans i18nKey="my_orders.pdf.billing">Billing address</Trans>,
      instructions: <Trans i18nKey="my_orders.pdf.instructions">Instructions</Trans>,
      client: <Trans i18nKey="my_orders.pdf.client">Client</Trans>,
      orderDate: <Trans i18nKey="my_orders.pdf.order_date">Order Date</Trans>,
      orderLine: <Trans i18nKey="my_orders.pdf.order_mark">Order Mark</Trans>,
      status: <Trans i18nKey="my_orders.pdf.detail_status">Status</Trans>,
      deliveryMode: <Trans i18nKey="my_orders.pdf.detail_delivery_mode">Delivery mode</Trans>,
      total: (
        <Trans count={items} i18nKey="my_orders.details.total">
          Total: ({{ items }} item)
        </Trans>
      ),
      vatExcl: (vat_exclude_price) => (
        <Trans i18nKey="common.price.vat_exclude_price">{{ vat_exclude_price }} VAT. Excl</Trans>
      ),
      discount: (discount) => <Trans i18nKey="my_orders.pdf.price.discount">{{ discount }}% Off</Trans>,
      totalVatExcl: <Trans i18nKey="my_orders.pdf.detail_total_exclulded_vat">Total VAT excl.:</Trans>,
      totalVat: <Trans i18nKey="my_orders.details.vat">VAT:</Trans>,
      totalVatIncl: <Trans i18nKey="my_orders.pdf.detail_total_included_vat">Total VAT.incl.:</Trans>,
      references: <Trans i18nKey="my_orders.pdf.references">References</Trans>,
      orderBy: <Trans i18nKey="my_orders.pdf.order_by">Order by</Trans>,
      clientNumber: <Trans i18nKey="my_orders.pdf.client_number">Client number</Trans>,
      distributor: <Trans i18nKey="my_orders.pdf.distributor">Distributor</Trans>,
      billingAccount: <Trans i18nKey="my_orders.pdf.billing_account">Billing account</Trans>,
      contact: <Trans i18nKey="my_orders.pdf.contact">Contact</Trans>,
      phone: <Trans i18nKey="my_orders.pdf.phone">Phone</Trans>,
      email: <Trans i18nKey="my_orders.pdf.email">E-mail@</Trans>,
      billingAddress: <Trans i18nKey="my_orders.pdf.billing_address">Address</Trans>,
      areaCode: <Trans i18nKey="my_orders.pdf.area_code">Area code</Trans>,
      city: <Trans i18nKey="my_orders.pdf.city">City</Trans>,
      country: <Trans i18nKey="my_orders.pdf.country">Country</Trans>,
      vrn: <Trans i18nKey="my_orders.pdf.vrn">VRN: </Trans>,
      referenceList: <Trans i18nKey={'my_orders.pdf.reference_list'}>Reference list</Trans>,
      noVehicleContext: <Trans i18nKey={'my_orders.order_detail.no_vehicle_context'}>No vehicle context</Trans>,
      urgent: <Trans i18nKey={'order.urgent'}>Urgent</Trans>,
      referenceMark: <Trans i18nKey={'order.referenceMark'}>Reference mark: </Trans>,
      stocks: <Trans i18nKey={'common.stock'}>Stock: </Trans>,
      ref: <Trans i18nKey={'my_orders.pdf.reference_number'}>Ref: </Trans>,
      qty: <Trans i18nKey="common.quantity.short">Qty: </Trans>,
      shipped: <Trans i18nKey="my_orders.pdf.references_shipped">shipped</Trans>,
      returnedArticles: (returnCount) => (
        <Trans i18nKey="my_orders.pdf.return_status">Returned articles: {{ returnCount }}</Trans>
      ),
    },
  };

  const blob = await pdf(<MyOrdersDetailPdfPage data={pdfData} />).toBlob();
  const url = URL.createObjectURL(blob);
  if (url) {
    window.open(url);
  }
};
