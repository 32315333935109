/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  TimeType,
  Warehouse,
} from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/response/DeliveryLeadTimeResponse';
import { Radio, Space } from 'antd';
import { RootState } from 'app/AppStore';
import { getDeliveryLeadTime, updateDeliveryLeadTime } from 'domains/deliveryLeadTime/DeliveryLeadTime.store';
import { DELIVERY_LEAD_TIME_WAREHOUSE } from 'domains/deliveryLeadTime/DeliveryLeadTime.types';
import { BlackButton, Box, Flex, InputNumber, MarginBox, Text } from 'UI';
import { Switch } from 'UI/Switch';

interface WarehouseDeliveryProps {
  setOptionsSaved: (state: boolean) => void;
  setOptionInvalid: (state: boolean) => void;
  warehouse: DELIVERY_LEAD_TIME_WAREHOUSE;
  title: string;
  allowDisableSwitch?: boolean;
  isDisabledByDefault?: boolean;
}

export interface CustomDeliveryLeadTime {
  warehouse: Warehouse;
  isDisabled: boolean;
  time: TimeType;
  from?: number;
  to?: number;
}

const WarehouseDelivery = ({
  setOptionsSaved,
  setOptionInvalid,
  warehouse,
  allowDisableSwitch,
  title,
  isDisabledByDefault,
}: WarehouseDeliveryProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const warehouseDelivery = useSelector((state: RootState) => getDeliveryLeadTime(state, warehouse));
  const [warehouseConfiguration, setWarehouseConfiguration] = useState<CustomDeliveryLeadTime>(
    warehouseDelivery ?? {
      warehouse,
      isDisabled: isDisabledByDefault ?? false,
      time: 'HOURS',
      from: undefined,
      to: undefined,
    },
  );

  useEffect(() => {
    if (warehouseDelivery) {
      setWarehouseConfiguration(warehouseDelivery);
    }
  }, [warehouseDelivery]);

  const saveOption = () => {
    if (!warehouseConfiguration.from || !warehouseConfiguration.to) {
      setOptionInvalid(true);
      setOptionsSaved(false);
      return;
    }
    dispatch(
      updateDeliveryLeadTime({
        ...warehouseConfiguration,
        from: warehouseConfiguration.from,
        to: warehouseConfiguration.to,
      }),
    );
    setOptionInvalid(false);
    setOptionsSaved(true);
  };

  return (
    <Flex direction={'column'}>
      <Box height={40} />
      <Flex direction={'row'}>
        <Text type={'h2'}>{title}</Text>
        {allowDisableSwitch && (
          <>
            <Flex />
            {warehouseConfiguration.isDisabled ? (
              <Text type={'section'}>{t('backoffice.delivery_lead_time.action.enableOption', 'Enable option')}</Text>
            ) : (
              <Text type={'section'}>{t('backoffice.delivery_lead_time.action.disableOption', 'Disable option')}</Text>
            )}
            <Box width={7} />
            <Switch
              checked={!warehouseConfiguration.isDisabled}
              onChange={() =>
                setWarehouseConfiguration({
                  ...warehouseConfiguration,
                  isDisabled: !warehouseConfiguration?.isDisabled,
                })
              }
            />
          </>
        )}
      </Flex>

      <Text type={'section'}>
        {t(
          'backoffice.delivery_lead_time.description',
          'Configure delivery times to display when there is no stock at R1s warehouse.',
        )}
      </Text>
      <Box height={30} />
      <Space direction={'vertical'} size={15}>
        <Text type={'h5_bold'}>{t('backoffice.delivery_lead_time.delay', 'Delay')}</Text>
        <Text type={'section'}>
          {t(
            'backoffice.delivery_lead_time.delay.description',
            'Would you like to configure your delivery options, per hour or per days?',
          )}
        </Text>
        <Radio.Group value={warehouseConfiguration.time}>
          <Radio
            value={'HOURS'}
            onClick={() =>
              setWarehouseConfiguration({
                ...warehouseConfiguration,
                time: 'HOURS',
              })
            }
          >
            {t('backoffice.delivery_lead_time.delay.perHour', 'Per hour')}
          </Radio>
          <Radio
            value={'DAYS'}
            onClick={() =>
              setWarehouseConfiguration({
                ...warehouseConfiguration,
                time: 'DAYS',
              })
            }
          >
            {t('backoffice.delivery_lead_time.delay.perDay', 'Per day')}
          </Radio>
        </Radio.Group>
      </Space>
      <Box height={30} />
      <Space direction={'vertical'} size={15}>
        <Text type={'h5_bold'}>
          {t('backoffice.delivery_lead_time.delivery_slot.title', 'Indicate desired delivery slot')}
        </Text>
        <Text type={'section'}>
          {t('backoffice.delivery_lead_time.delivery_slot.description', 'Usually delivered within')}
        </Text>
        <Space size={15}>
          <InputNumber
            bordered
            value={warehouseConfiguration.from}
            max={9999}
            onBlur={(val) => {
              setWarehouseConfiguration({
                ...warehouseConfiguration,
                from: val,
                to: warehouseConfiguration.to
                  ? Number(val) > warehouseConfiguration.to
                    ? val
                    : warehouseConfiguration.to
                  : undefined,
              });
            }}
          />
          <Text type={'section'}>{t('common.to', 'to')}</Text>
          <InputNumber
            bordered
            max={9999}
            value={warehouseConfiguration.to}
            onBlur={(val) => {
              setWarehouseConfiguration({
                ...warehouseConfiguration,
                to: val,
                from: warehouseConfiguration.from
                  ? Number(val) < warehouseConfiguration.from
                    ? val
                    : warehouseConfiguration.from
                  : undefined,
              });
            }}
          />
          {warehouseConfiguration.time === 'HOURS' ? (
            <Text type={'section'}>{t('common.hours', 'Hours')}</Text>
          ) : (
            <Text type={'section'}>{t('common.days', 'Days')}</Text>
          )}
        </Space>
      </Space>
      <MarginBox mt={45}>
        <BlackButton stretch={false} onClick={saveOption}>
          {t('common.action.save', 'Save')}
        </BlackButton>
      </MarginBox>
    </Flex>
  );
};

export default WarehouseDelivery;
