/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import { ROUTER_CATALOG_DH_L3 } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import { useDisclosure } from 'components/Dialog';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { InlineNotification } from 'components/Notification/InlineNotification';
import { getDetailOfLevel3, getPlateFullImageKey } from 'domains/catalog/Catalog.store';
import { PlateReferencesDetail } from 'domains/catalog/Catalog.types';
import { ListTitle, PartsListCard } from 'pages/CatalogPage/DH/CategoriesMenu/CategoriesMenu.styled';
import PlateDetailCard from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateDetailCard';
import PlateIndexItem from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateDetailSection/PlateIndexItem';
import PlateThumbnailSection from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateThumbnailSection';
import PrintPlateReferencesDialog from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PrintPlate';
import { Box, Flex, MarginBox, Modal } from 'UI';
import { getData, hasData, NO_DATA } from 'utils';
import { IndexType, svgElementPlateId, svgElementPlateModalId } from 'utils/svg/common';
import { SelectedIndexType } from '../SparePartsSection';

const mod = (n: number, m: number) => ((n % m) + m) % m;

export interface PlateDetailSectionProps {
  plateId: string;
  vehicleKey: string | undefined;
  handleSelectMultiplate: (plateIds: string[]) => void;
  subcategory: VehiclePartCategoryTreeItem | undefined;
  plateReferences: PlateReferencesDetail[] | NO_DATA;
  setSelectedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void;
  selectedIndex: SelectedIndexType;
  highlightedIndex: IndexType;
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void;
}

const PlateDetailSection = ({
  plateId,
  handleSelectMultiplate,
  subcategory,
  vehicleKey,
  plateReferences,
  setSelectedIndex,
  selectedIndex,
  highlightedIndex,
  setHighlightedIndex,
}: PlateDetailSectionProps) => {
  const { t } = useTranslation();
  const [isPlateModalOpen, setIsPlateModalOpen] = useState(false);
  const multiplates = useSelector((state: RootState) =>
    getDetailOfLevel3(state, { nodeId: subcategory?.nodeId, vehicleKey }),
  );
  const disclosurePrint = useDisclosure();
  const { onOpen: onPrintOpen } = disclosurePrint;
  const modalWidth = window.innerHeight / window.innerWidth > 0.9 ? '90vh' : '100vh';

  const obj = useMemo(() => {
    return { plateId, vehicleKey };
  }, [plateId, vehicleKey]);
  const plateImage = useSelector((state: RootState) => getPlateFullImageKey(state, obj));

  // can we compute detailIndex more easily?
  const detailIndex: number = Array.isArray(multiplates)
    ? multiplates.reduce((acc: number, next, index) => (next.plateIds.includes(plateId) ? index : acc), 0)
    : 0;

  const browse = (offset: number) => {
    if (Array.isArray(multiplates)) {
      const selectedPlateIds = multiplates[mod(detailIndex + offset, multiplates.length)]?.plateIds;
      if (selectedPlateIds) {
        handleSelectMultiplate(selectedPlateIds);
      }
    }
  };

  useEffect(() => {
    setIsPlateModalOpen(false);
  }, [selectedIndex]);

  return (
    <Flex direction={'row'}>
      <PlateThumbnailSection
        multiplates={multiplates}
        handleSelectMultiplate={handleSelectMultiplate}
        plateImage={plateImage}
        selectedPlateId={plateId}
      />
      <Box width={15} />
      <Flex direction={'row'} justify={'center'}>
        {plateImage && (
          <PlateDetailCard
            plate={plateImage}
            platesCount={getData(multiplates)?.length}
            browseLeft={() => browse(-1)}
            browseRight={() => browse(1)}
            plateReferences={plateReferences}
            setSelectedIndex={setSelectedIndex}
            highlightedIndex={highlightedIndex}
            setHighlightedIndex={setHighlightedIndex}
            indexesLength={plateReferences?.length}
            setIsPlateModalOpen={setIsPlateModalOpen}
            onPrintPlate={onPrintOpen}
            svgElementPlateId={svgElementPlateId}
          />
        )}
        {plateImage && (
          <>
            <div id={'plate_popin_modal'}>
              <Modal
                visible={isPlateModalOpen}
                footer={''}
                closable
                onCancel={() => setIsPlateModalOpen(false)}
                width={'auto'}
                getContainer={document.getElementById('plate_popin_modal') ?? document.body}
              >
                <MarginBox mt={20} />
                <Flex minWidth={modalWidth} maxWidth={modalWidth}>
                  <PlateDetailCard
                    plate={plateImage}
                    platesCount={getData(multiplates)?.length}
                    browseLeft={() => browse(-1)}
                    browseRight={() => browse(1)}
                    plateReferences={plateReferences}
                    setSelectedIndex={setSelectedIndex}
                    highlightedIndex={highlightedIndex}
                    setHighlightedIndex={setHighlightedIndex}
                    indexesLength={plateReferences?.length}
                    svgElementPlateId={svgElementPlateModalId}
                    isModal
                  />
                </Flex>
              </Modal>
            </div>
            <div id={'plate_print_popin_modal'}>
              <PrintPlateReferencesDialog
                disclosure={disclosurePrint}
                multiplates={multiplates}
                vehicleKey={vehicleKey}
                currentPlate={plateId}
              />
            </div>
          </>
        )}
      </Flex>
      <Box width={30} />
      <FirstHelpPopin
        streamId={ROUTER_CATALOG_DH_L3}
        popinId={`${ROUTER_CATALOG_DH_L3}_quick_search`}
        placement={'top'}
      >
        <PartsListCard
          title={<ListTitle>{t('catalog.parts.category.car_parts.available_pieces', 'Available pieces')}</ListTitle>}
        >
          <DataContainer
            data={plateReferences}
            noDataMarginY={20}
            NotFound={() => (
              <InlineNotification
                displayStyle={'error'}
                message={t('catalog.parts.category.car_parts.results_not_found', 'No results found')}
                description={t(
                  'catalog.parts.category.car_parts.results_not_found.detail',
                  'Sorry, no parts were found for this vehicle.',
                )}
              />
            )}
          >
            {hasData(plateReferences) &&
              plateReferences.map((detail) => (
                <PlateIndexItem
                  key={`part-list-reference-${detail.index}`}
                  detail={detail}
                  vehicleKey={vehicleKey}
                  setSelectedIndex={setSelectedIndex}
                  highlightedIndex={highlightedIndex}
                  setHighlightedIndex={setHighlightedIndex}
                />
              ))}
          </DataContainer>
        </PartsListCard>
      </FirstHelpPopin>
    </Flex>
  );
};

export default PlateDetailSection;
