import React, { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { REFERENCE_QUANTITY_LIMIT } from 'domains/basket/Basket.types';
import { DeleteAltButton, Flex, MarginBox, MinusButton, PlusButton } from 'UI';
import { ONCHANGE_DEBOUNCE_INTERVAL } from 'utils/hooks/useInput';
import { QtyButton, QtyInput } from './QuantityModule.styled';

export type QuantityModuleUpdateComponentType = 'TRASH' | 'BUTTON' | 'INPUT';

interface QuantityModuleProps {
  value: number;
  onChange: (val: number, type?: QuantityModuleUpdateComponentType) => void;
  showDelete?: boolean;
  precisionMin?: number;
  precisionMax?: number;
  noDebounce?: boolean;
  disabled?: boolean;
  maxValue?: number;
  onMaxValueOverflow?: () => void;
}

function QuantityModuleLocal({
  value,
  onChange,
  showDelete,
  precisionMin,
  precisionMax,
  noDebounce = false,
  disabled = false,
  maxValue,
  onMaxValueOverflow,
}: QuantityModuleProps) {
  const [inputValue, setInputValue] = useState(value);
  const debouncedOnChange = useDebouncedCallback(onChange, ONCHANGE_DEBOUNCE_INTERVAL);
  const maxValueLocal = maxValue ?? REFERENCE_QUANTITY_LIMIT;

  useEffect(() => {
    const isMaxValueOverflow = value > maxValueLocal;
    setInputValue(isMaxValueOverflow ? maxValueLocal : value);
    if (isMaxValueOverflow && onMaxValueOverflow) {
      onMaxValueOverflow();
    }
  }, [value, maxValueLocal, onMaxValueOverflow]);

  const handleChange = useCallback(
    (val: number, type?: QuantityModuleUpdateComponentType) => {
      setInputValue(val);
      noDebounce ? onChange(val, type) : debouncedOnChange(val, type);
    },
    [setInputValue, debouncedOnChange, noDebounce, onChange],
  );

  const handleClickDeleteButton = () => {
    const call = showDelete ? inputValue > 0 : inputValue > 1;
    call && handleChange(inputValue - 1, 'TRASH');
  };

  const handleClickMinusButton = () => {
    const call = showDelete ? inputValue > 0 : inputValue > 1;
    call && handleChange(inputValue - 1, 'BUTTON');
  };

  const handleClickPlusButton = () => {
    inputValue < maxValueLocal && handleChange(inputValue + 1, 'BUTTON');
  };

  const handleOnBlur = (val: number | undefined) => {
    if (val === undefined || val === inputValue) return;
    handleChange(val, 'INPUT');
  };

  return (
    <Flex size={0}>
      <QtyButton>
        {showDelete && inputValue <= 1 ? (
          <DeleteAltButton
            onClick={handleClickDeleteButton}
            disabled={disabled || showDelete ? false : inputValue === 1}
          />
        ) : (
          <MinusButton onClick={handleClickMinusButton} disabled={disabled || showDelete ? false : inputValue === 1} />
        )}
      </QtyButton>
      <QtyInput
        value={inputValue}
        bordered
        precisionMin={precisionMin}
        precisionMax={precisionMax}
        min={0}
        max={maxValueLocal}
        onBlur={handleOnBlur}
        readOnly={disabled}
      />
      <MarginBox ml={10}>
        <QtyButton>
          <PlusButton
            color={'brand_black'}
            onClick={handleClickPlusButton}
            disabled={disabled || inputValue >= maxValueLocal}
            dataCy={'increase-quantity-button'}
          />
        </QtyButton>
      </MarginBox>
    </Flex>
  );
}

export const QuantityModule = React.memo(QuantityModuleLocal);
