import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Space } from 'antd';
import { ROUTER_HOME } from 'app/AppRouter';
import { deleteFileCrossSellingRequestSaga } from 'domains/crossSelling/CrossSelling.store';
import { BlackButton, MarginBox, SuccessResult, WhiteButton } from 'UI';

const PublishResult = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCancel = () => dispatch(deleteFileCrossSellingRequestSaga());
  const handleHome = () => {
    history.push(ROUTER_HOME);
  };

  const Actions = () => {
    return (
      <Space size={30}>
        <WhiteButton onClick={handleCancel}>{t('common.action.cancel', 'Cancel')}</WhiteButton>
        <BlackButton onClick={handleHome}>{t('common.home', 'Home')}</BlackButton>
      </Space>
    );
  };

  return (
    <MarginBox ml={50} mt={50}>
      <SuccessResult
        title={t('cross_selling.upload.result.success.title', 'Your Cross seilling options are now published.')}
        subTitle={t(
          'cross_selling.upload.result.success.description',
          'Your preferences should now appear on the website.',
        )}
        extra={<Actions />}
      />
    </MarginBox>
  );
};

export default PublishResult;
