import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { AngleDownIcon, AngleUpIcon } from 'assets/icons';
import { REFERENCE_QUANTITY_LIMIT } from 'domains/basket/Basket.types';
import { Icon } from 'UI';
import { ONCHANGE_DEBOUNCE_INTERVAL } from 'utils/hooks/useInput';
import { InputArrowsWrapper, InputWrapper, SInputNumber } from './QuantityBox.styled';

interface QuantityBoxProps {
  value: number;
  onChange: (quantity: number | string) => void;
  mini?: boolean;
  medium?: boolean;
  maxValue?: number;
  minValue?: number;
  onMaxValueOverflow?: () => void;
}

const QuantityBoxLocal = ({
  value,
  onChange,
  mini = false,
  medium = false,
  maxValue,
  minValue = 0,
  onMaxValueOverflow,
}: QuantityBoxProps) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState(value);
  const debouncedOnChange = useDebouncedCallback(onChange, ONCHANGE_DEBOUNCE_INTERVAL);
  const maxValueLocal = maxValue ?? REFERENCE_QUANTITY_LIMIT;

  useEffect(() => {
    const isMaxValueOverflow = value > maxValueLocal;
    setInputValue(isMaxValueOverflow ? maxValueLocal : value);
    if (isMaxValueOverflow && onMaxValueOverflow) {
      onMaxValueOverflow();
    }
  }, [value, maxValueLocal, onMaxValueOverflow]);

  const handleChange = useCallback(
    (val: number) => {
      if (val < minValue) return;
      setInputValue(val);
      debouncedOnChange(val);
    },
    [setInputValue, debouncedOnChange, minValue],
  );

  return (
    <InputWrapper>
      <SInputNumber
        mini={mini}
        medium={medium}
        bordered
        value={inputValue}
        onBlur={(val) => {
          if (val === undefined || val === inputValue) return;
          handleChange(val);
        }}
        min={minValue}
        max={maxValueLocal}
      />
      <InputArrowsWrapper>
        <Icon
          IconComponent={AngleUpIcon}
          size={15}
          color={theme.color.grey55}
          hoverFill={theme.color.brand_black}
          display={'block'}
          onClick={() => {
            inputValue < maxValueLocal && handleChange(inputValue + 1);
          }}
          disabled={inputValue === REFERENCE_QUANTITY_LIMIT}
        />
        <Icon
          IconComponent={AngleDownIcon}
          size={15}
          color={theme.color.grey55}
          hoverFill={theme.color.brand_black}
          display={'block'}
          onClick={() => {
            handleChange(inputValue - 1);
          }}
          disabled={inputValue <= minValue}
        />
      </InputArrowsWrapper>
    </InputWrapper>
  );
};

export const QuantityBox = React.memo(QuantityBoxLocal);
