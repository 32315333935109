import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { getCurrentEstimateId, getSelectedTab, setCurrentEstimateId } from 'domains/estimate/Estimate.store';
import { ESTIMATE_TAB, EstimateIdParam, EstimateTabParam } from 'domains/estimate/Estimate.types';
import EstimatePage from 'pages/EstimatePage/EstimatePage';

export const EstimateBase = () => {
  const selectedTab = useSelector(getSelectedTab);
  const currentEstimateId = useSelector(getCurrentEstimateId);
  const params = useMemo(
    () => new URLSearchParams(location.search),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentEstimateId],
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabKey, setTabKey] = useState<ESTIMATE_TAB>((params.get(EstimateTabParam) as ESTIMATE_TAB) || selectedTab);

  const estimateIdParam = params.get(EstimateIdParam);

  // set estimateId parameter properly in the URL and in store - do not set it in history and settings
  useEffect(() => {
    if (tabKey !== 'estimate') {
      return;
    }
    if (estimateIdParam && estimateIdParam !== currentEstimateId) {
      dispatch(setCurrentEstimateId(estimateIdParam));
      return;
    }
    if (!estimateIdParam && currentEstimateId) {
      history.push(`${ROUTER_ESTIMATE}?${params}&${EstimateIdParam}=${currentEstimateId}`);
    }
  }, [estimateIdParam, currentEstimateId, params, dispatch, history, tabKey]);

  return <EstimatePage estimateId={estimateIdParam ?? currentEstimateId} tabKey={tabKey} setTabKey={setTabKey} />;
};
