import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InfoCircleIcon } from 'assets/icons';
import Popover from 'components/Popover';
import PrivateComponent from 'composers/PrivateComponent';
import { CreatePromotionStepProps, PromotionTypeLocal } from 'domains/promotion/Promotion.types';
import { UserRole } from 'domains/user';
import { Flex, Icon, InputNumber, MarginBox, Radio, StepButtons, Text } from 'UI';
import { CommercialFamilyDiscountRow } from './CommercialFamilyDiscountRow';
import { FlashPromotionDiscount } from './FlashPromotionDiscount';
import { ReferencesDiscountRow } from './ReferencesDiscountRow';

function getPriceOptionContent(type: PromotionTypeLocal) {
  switch (type) {
    case 'PROMOTION_FLASH_QUANTITY_LIMITED':
      return {
        header: <Trans i18nKey={'backoffice.promotion.remote_product_selection'}>Select your promoted product</Trans>,
        description: null,
        maxFileCount: 0,
        maxCommercialFamiliesGroups: 0,
      };
    case 'BANNER':
    case 'PROMOTION_FLASH_TIME_LIMITED':
      return {
        header: <Trans i18nKey={'backoffice.promotion.remote_products_selection'}>Select your promoted products</Trans>,
        description: (
          <Trans i18nKey={'backoffice.promotion.remote_products_selection.description'}>
            Directly enter one or several commercial family code or/and upload a Csv file to select the promoted
            references
          </Trans>
        ),
        maxFileCount: 1,
        maxCommercialFamiliesGroups: 1,
      };
    default:
      return {
        header: <Trans i18nKey={'backoffice.promotion.remote_products_selection'}>Select your promoted products</Trans>,
        description: (
          <Trans i18nKey={'backoffice.promotion.remote_products_selection.description'}>
            Directly enter one or several commercial family code or/and upload a Csv file to select the promoted
            references
          </Trans>
        ),
        maxFileCount: 5,
        maxCommercialFamiliesGroups: 20,
      };
  }
}

export const PriceOptionsStep = ({
  promotion,
  setPromotion,
  currentStep,
  setCurrentStep,
  totalSteps,
  validateStep,
}: CreatePromotionStepProps) => {
  const { t } = useTranslation();
  const content = getPriceOptionContent(promotion.promotionType);

  const renderCalculationInfo = (method: 'PCL' | 'PNMRA') => {
    const infoData = {
      PCL: ['PCL-(DG%+MCX%)=PROMO PRICE', 'e.g. 300-(40%+10%)=150'],
      PNMRA: ['PCL-DG%=PNMRA', 'e.g. 300-40%=180', 'PNMRA-MCX%=PROMO PRICE', 'e.g. 180-10%=162'],
    };

    return (
      <Popover
        padding={10}
        content={
          <Flex direction={'column'}>
            <Text type={'text_dim_bold'}>
              {t('backoffice.promotion.calculation_method', 'Example of calculation method')}
            </Text>
            {infoData[method].map((i) => (
              <MarginBox mt={10} key={i}>
                <Text type={'text'}>{i}</Text>
              </MarginBox>
            ))}
          </Flex>
        }
        placement={'right'}
      >
        <MarginBox ml={3} mt={3}>
          <Icon IconComponent={InfoCircleIcon} size={16} />
        </MarginBox>
      </Popover>
    );
  };

  const renderPriceOption = () => {
    return (
      <>
        <MarginBox mt={45} />
        <Text type={'h2'}>{t('backoffice.promotion.price_option', 'Price option')}</Text>
        <Text type={'text'}>
          {t(
            'backoffice.promotion.select_calculation_option',
            'Please select desired calculation option of the promotion price.',
          )}
        </Text>
        <MarginBox mt={15} />
        <Flex direction={'row'}>
          <Radio
            disable={promotion.editing}
            label={t('backoffice.promotion.price_pcl', 'Based on PCL (Detail net price)')}
            value={promotion.discountType === 'PCL'}
            onChange={() => setPromotion({ ...promotion, discountType: 'PCL' })}
          />
          {renderCalculationInfo('PCL')}
        </Flex>
        <MarginBox mt={15} />
        <Flex direction={'row'}>
          <Radio
            disable={promotion.editing}
            label={t('backoffice.promotion.pnmra', 'Based on PNMRA (Standard price for service)')}
            value={promotion.discountType === 'PNMRA'}
            onChange={() => setPromotion({ ...promotion, discountType: 'PNMRA' })}
          />
          {renderCalculationInfo('PNMRA')}
        </Flex>
      </>
    );
  };

  const renderPromotedProducts = () => {
    return (
      <Flex direction={'column'}>
        <MarginBox mt={45} />
        <Text type={'h2'}>{content.header}</Text>
        <Text type={'text'}>{content.description}</Text>
        <MarginBox mt={30} />
        {content.maxCommercialFamiliesGroups > 0 && (
          <Flex direction={'column'}>
            <CommercialFamilyDiscountRow
              promotion={promotion}
              setPromotion={setPromotion}
              setCurrentStep={setCurrentStep}
              maxCommercialFamiliesGroups={content.maxCommercialFamiliesGroups}
            />
            <MarginBox mt={30} />
          </Flex>
        )}
        <Flex direction={'column'}>
          <ReferencesDiscountRow
            promotion={promotion}
            setPromotion={setPromotion}
            setCurrentStep={setCurrentStep}
            maxFileCount={content.maxFileCount}
          />
          <MarginBox mt={15} />
        </Flex>
      </Flex>
    );
  };

  const renderAdditionalDiscount = () => {
    return (
      <PrivateComponent
        render={() => (
          <>
            {promotion.promotionType === 'DISCOUNT' && (
              <Flex direction={'column'} minWidth={200} maxWidth={200}>
                <Text type={'h5_bold'}>
                  {t('backoffice.promotion.add_additional_discount', 'Add additional discount')}
                </Text>
                <MarginBox mt={20} />
                <Flex minWidth={115} maxWidth={115}>
                  <InputNumber
                    disabled={promotion.editing}
                    placeholder={'-'}
                    addonAfter={'%'}
                    min={0}
                    max={100}
                    value={promotion.additionalInformation?.discount}
                    onChangeDebounced={(discount) => {
                      setPromotion({
                        ...promotion,
                        additionalInformation: {
                          ...promotion.additionalInformation,
                          discount: Number(discount),
                        },
                      });
                    }}
                  />
                </Flex>
                <MarginBox mt={30} />
              </Flex>
            )}
          </>
        )}
        requiredRights={[UserRole.COUNTRY_ADMIN]}
      />
    );
  };

  const getCurrentItem = () => {
    switch (promotion.promotionType) {
      case 'BANNER':
        return <>{renderPromotedProducts()}</>;
      case 'PROMOTION_FLASH_QUANTITY_LIMITED':
      case 'PROMOTION_FLASH_TIME_LIMITED':
        return (
          <>
            {renderPriceOption()}
            {renderPromotedProducts()}
            <FlashPromotionDiscount promotion={promotion} setPromotion={setPromotion} />
          </>
        );
      default:
        return (
          <>
            {renderPriceOption()}
            {renderPromotedProducts()}
            {renderAdditionalDiscount()}
          </>
        );
    }
  };

  return (
    <Flex direction={'column'}>
      {getCurrentItem()}
      <Flex>
        <StepButtons
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalItems={totalSteps}
          nextEnabled={validateStep()}
        />
      </Flex>
    </Flex>
  );
};
