import { ApplicationConfigResponse } from '@1po/1po-bff-fe-spec/generated/config/response/ApplicationConfigResponse';
import { APP_NAMESPACE } from 'app/App.constants';
import { NO_DATA, SearchData } from 'utils';

export const APP_CONTEXT_NAMESPACE = `${APP_NAMESPACE}/APP_CONTEXT`;

export const GET_BUILD_VERSIONS = `${APP_CONTEXT_NAMESPACE}/GET_BUILD_VERSIONS`;
export const GET_APP_CONFIG = `${APP_CONTEXT_NAMESPACE}/GET_APP_CONFIG`;
export const MODIFY_MOCK_CONFIG = `${APP_CONTEXT_NAMESPACE}/MODIFY_MOCK_CONFIG`;

export interface AppContextState {
  buildVersions: BuildVersion[] | NO_DATA;
  appConfig: SearchData<ApplicationConfigResponse>;
  openedModals: Set<string>;
}

export interface BuildVersion {
  name: string;
  time: Date;
  buildVersion: string;
}
