import { Modal } from 'antd';
import styled from 'styled-components';
import { Flex } from 'UI';

export const SList = styled.div`
  max-height: 400px;
  overflow-y: overlay;
  :nth-child(even) {
    background-color: #1c1c1c;
  }
`;

export const SVehicleItem = styled(Flex)`
  padding: 15px 30px;
  :nth-child(even) {
    background-color: ${({ theme }) => theme.color.grey_very_light_2};
  }
`;

export const SModal = styled(Modal)`
  .ant-modal-body {
    padding: 15px 0;
  }
`;
