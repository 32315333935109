import { Modal } from 'antd';
import styled from 'styled-components';
import { Flex } from 'UI';

export const SFlex = styled(Flex)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const SModal = styled(Modal)`
  .ant-modal-body {
    background: #f3f3f3;
    padding: 0;
  }

  border-radius: 15px;
  overflow: hidden;
`;

export const SModalHeader = styled(Flex)`
  background: white;
  height: 52px;
  width: 100%;
`;

export const SCloseIcon = styled(Flex)`
  margin-right: 50px;
  cursor: pointer;
`;
