import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SRectangle } from 'app/App.styled';
import { Page } from 'components/Page';
import SellerSettings from 'components/SellerSettings';
import {
  getShowSellerPopup,
  getUserCommercialLink,
  getUserRights,
  getUserSellersToChoose,
  getUserSellerWarning,
  UserRole,
} from 'domains/user';
import { Modal, Text } from 'UI';
import { getData, hasData } from 'utils';
import { hasUserAnyRight } from 'utils/rights';

const NoSeller = () => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(true);
  return (
    <Modal width={585} footer={''} visible={modalVisible} onCancel={() => setModalVisible(false)}>
      <SRectangle>
        <Text type={'h1'}>
          <strong>{t('profile.seller_selection_no_seller_title', 'No seller available')}</strong>
        </Text>
        <Text type={'h2'}>
          <strong>{t('profile.seller_selection_no_seller_description', 'Contact your R1 or your support.')}</strong>
        </Text>
      </SRectangle>
    </Modal>
  );
};

export const WithPopup = ({ children, requiredRights }: { children: JSX.Element; requiredRights: UserRole[] }) => {
  const userRights = useSelector(getUserRights);
  const seller = useSelector(getUserCommercialLink)?.seller;
  const sellersToChoose = useSelector(getUserSellersToChoose);
  const sellerWarning = useSelector(getUserSellerWarning);
  const displaySellerWarning = hasData(sellerWarning) && sellerWarning !== 'NO_CHANGE';
  const showPopup = useSelector(getShowSellerPopup);
  const isAuthorized = hasUserAnyRight(requiredRights, getData(userRights) ?? []);
  const isFilledSeller = !seller && hasData(sellersToChoose) && sellersToChoose.length > 0;
  const showSellerSettings = showPopup && isAuthorized && (isFilledSeller || displaySellerWarning);

  if (hasData(sellerWarning) && sellerWarning === 'NO_SELLER_AVAILABLE') {
    return (
      <>
        <NoSeller />
        {children}
      </>
    );
  }

  return showSellerSettings ? (
    <Page openedSideBar={false} collapsedSideBar={false}>
      <SellerSettings />
    </Page>
  ) : (
    <>{children}</>
  );
};
